import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { localizer, NotFound } from "di-common";
import { Button, Spin } from "antd";

type LocalizedStaticProps = {
  folderName: string;
  pageName: string;
  showLogo?: boolean;
};
/**
 * This component reads a localized file from the public folder and displays it on screen.
 * Currently, this only works properly, when this component is the top-level component on
 * the page. This fullfills current requirements, but limits it's re-use.
 */
function LocalizedStatic({ folderName, pageName, showLogo = true }: LocalizedStaticProps) {
  const [isLoading, setLoading] = useState(true);
  const [html, setHtml] = useState(null);
  const navigate = useNavigate();
  const language = localizer.getCurrentLocale();
  useEffect(() => {
    const contentFile = `/${folderName}/${pageName}.${language}.html`;
    Axios.get(contentFile).then(
      response => {
        setLoading(false);
        setHtml(response.data);
      },
      error => {
        setLoading(false);
        console.error("Could not load the localized static contents: " + contentFile);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (html != null) {
    const isNewTab = navigate.length === 1;
    return (
      <div className="content-box">
        {showLogo && (
          <header className="header">
            <div className="branding">
              <img className="branding__name" src="logo-transparant.png" alt="digital-ink logo" />
            </div>
          </header>
        )}
        <div className="content-box__content">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className="content-box__buttonbar">
            <Button
              type="link"
              onClick={() => {
                isNewTab ? window.close() : navigate(-1);
              }}
            >
              {localizer.resolve("Global.buttonCaption.back")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return <NotFound />;
}
export default LocalizedStatic;
