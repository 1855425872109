import React, { useContext, useState } from "react";
import { Modal, Form, Input, Typography, message } from "antd";
import { copyObject } from "../../utils/MiscellaneousUtil";
import DispatchContext, { getDispatchContext } from "../../DispatchContext";
import { localizer, calculatePasswordHash } from "di-common";
import userDao from "../../dao/UserDao";
import { User } from "../../StateContext";

type ChangePasswordFormProps = {
  setModalComponent: React.Dispatch<
    React.SetStateAction<React.ReactElement<any, any> | null>
  >;
  currentUser: User;
};

function ChangePasswordForm({
  setModalComponent,
  currentUser
}: ChangePasswordFormProps) {
  const [form] = Form.useForm();
  const [isProcessing, setProcessing] = useState(false);
  const { dispatch } = getDispatchContext(useContext(DispatchContext));

  function sendPasswordChangeRequest(values: any) {
    setProcessing(true);
    const passwordChangeRequest = {
      newPasswordHash: calculatePasswordHash(
        currentUser.username,
        values["newPassword"]
      ),
      oldPasswordChangeCode: calculatePasswordHash(
        currentUser.id,
        values["oldPassword"]
      ),
      newPasswordChangeCode: calculatePasswordHash(
        currentUser.id,
        values["newPassword"]
      )
    };
    //send axios request and on success, dispatch password change to inform application
    userDao.changePassword(
      currentUser,
      passwordChangeRequest,
      handlePasswordChangeResponse,
      (error: any) => {
        console.dir(error);
        setProcessing(false);
      }
    );
  }

  function handlePasswordChangeResponse(response: any) {
    setProcessing(false);
    if (Boolean(response.data) === true) {
      message.success(localizer.resolve("ChangePassword.success"));
      const request = JSON.parse(response.config.data);
      const updatedUser = copyObject(currentUser);
      if (updatedUser === null) {
        throw new Error("User can not be null");
      }
      updatedUser.passwordHash = request.newPasswordHash;
      dispatch({
        type: "changeUserInfo",
        data: updatedUser
      });
    } else {
      message.error(localizer.resolve("ChangePassword.fail"));
    }
    setModalComponent(null); //to close this modal
  }

  const { Paragraph } = Typography;
  return (
    <Modal
      title={localizer.resolve("ChangePassword.title")}
      open={true}
      okText={localizer.resolve("Global.buttonCaption.save")}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={form.submit}
      okButtonProps={{ loading: isProcessing }}
      onCancel={() => {
        form.resetFields();
        setModalComponent(null);
      }}
      destroyOnClose={true}
    >
      <Form
        layout="horizontal"
        form={form}
        initialValues={{ newPassword: null, retypedPassword: null }}
        onFinish={sendPasswordChangeRequest}
      >
        <Form.Item
          label={localizer.resolve("ChangePassword.oldPassword")}
          name="oldPassword"
          rules={[
            {
              required: true,
              message: localizer.resolve("ChangePassword.oldRequiredMessage")
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  !value ||
                  currentUser.passwordHash ===
                    calculatePasswordHash(currentUser.username, value)
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  localizer.resolve("ChangePassword.wrongPasswordMessage")
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={localizer.resolve("ChangePassword.newPassword")}
          name="newPassword"
          dependencies={["oldPassword"]}
          rules={[
            {
              required: true,
              message: localizer.resolve("ChangePassword.newRequiredMessage")
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const oldValue = getFieldValue("oldPassword");
                if (!value || !oldValue || value !== oldValue) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  localizer.resolve("ChangePassword.samePasswordMessage")
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={localizer.resolve("ChangePassword.retypedPassword")}
          name="retypedPassword"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: localizer.resolve("ChangePassword.newRequiredMessage")
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  localizer.resolve("ChangePassword.noMatchMessage")
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Paragraph type="secondary">
          {localizer.resolve("ChangePassword.tip")}
        </Paragraph>
      </Form>
    </Modal>
  );
}

export default ChangePasswordForm;
