import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "antd";
import { localizer } from "di-common";
import DeleteResourceAction from "../general/DeleteResourceAction";
import ProtectedImage from "../general/ProtectedImage";
import { ProjectMeta } from "../../dao/ProjectDao";
import { GlobalAction } from "../..";

type ProjectCardState = {
  project: ProjectMeta;
  dispatcher: React.Dispatch<GlobalAction>;
};

function ProjectCard({ project, dispatcher }: ProjectCardState) {
  const navigate = useNavigate();
  const { Text } = Typography;

  return (
    <Card
      className="projectcard"
      onClick={() => navigate(`/projects/${project.id}`)}
      hoverable
      cover={<ProtectedImage alt="" src={project.coverUrl ? project.coverUrl : "images/add-image.svg"} />}
      actions={[
        <DeleteResourceAction
          title={localizer.resolve("ProjectMeta.deleteConfirmation")}
          urlResourceName="projects"
          resourceId={project.id}
          dispatcher={dispatcher}
          actionType="removeProjectSuccess"
        />
      ]}
    >
      <Card.Meta
        description={localizer.resolve("ProjectType." + project.projectType)}
        title={
          <Text
            style={{ width: "100%" }}
            ellipsis={{
              tooltip: project.workTitle
            }}
          >
            {project.workTitle}
          </Text>
        }
      />
    </Card>
  );
}

export default ProjectCard;
