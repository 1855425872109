import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { localizer } from "di-common";
import RelationList from "./RelationList";
import RelationDetails from "./RelationDetails";
import { LIST_VIEW, NEW_VIEW, SINGLE_VIEW } from "../entity/EntityConstants";

type RelationPaneProps = {
  isDisabled?: boolean;
  onClose: React.MouseEventHandler<HTMLElement>;
  isVisible: boolean;
};

/**
 * Maintain the information for all the relatioons in the story
 */
function RelationPane({
  isDisabled = false,
  onClose,
  isVisible
}: RelationPaneProps) {
  //This state is pulled up, because it is shared between child components
  const [relationId, setRelationId] = useState<string>();
  const [mode, setMode] = useState(LIST_VIEW);

  function addRelation() {
    setRelationId(undefined);
    setMode(NEW_VIEW);
  }

  function onSelectRelation(relationId: string) {
    setRelationId(relationId);
    setMode(SINGLE_VIEW);
  }

  return (
    <aside
      className={[
        "aside__area",
        isVisible ? "aside__area--active" : "aside__area--inactive"
      ].join(" ")}
    >
      <div className="aside__title">
        <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
          <Button
            type="text"
            icon={<CloseOutlined />}
            size="small"
            onClick={onClose}
          />
        </Tooltip>
        {mode === LIST_VIEW ? (
          <>
            <span>{localizer.resolve("RelationPane.title.list")} </span>
            <Tooltip
              title={localizer.resolve("RelationPane.tooltip.addRelation")}
            >
              <Button
                disabled={isDisabled}
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
                onClick={addRelation}
              />
            </Tooltip>
          </>
        ) : (
          <>
            {/* Do not show back-to-list button when we are editing, because we don't use autosave and we want the user
              to explicitely use save/cancel buttons -- this design choice may change in the future
            */}
            {mode === SINGLE_VIEW && (
              <Tooltip
                title={localizer.resolve("RelationPane.tooltip.backToList")}
              >
                <Button
                  shape="circle"
                  icon={<ArrowLeftOutlined />}
                  size="small"
                  onClick={() => setMode(LIST_VIEW)}
                />
              </Tooltip>
            )}
            <span>{localizer.resolve("RelationPane.title.detail")}</span>
          </>
        )}
      </div>
      {mode === LIST_VIEW ? (
        <RelationList
          isDisabled={isDisabled}
          onSelectRelation={onSelectRelation}
        />
      ) : (
        <RelationDetails
          isDisabled={isDisabled}
          relationId={relationId}
          mode={mode}
          setMode={setMode}
        />
      )}
    </aside>
  );
}

export default RelationPane;
