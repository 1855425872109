import React, { useContext } from "react";
import { Form, Input, Modal, Typography } from "antd";
import ImageUpload from "../general/ImageUpload";
import { getBrowserLocale, localizer } from "di-common";
import ProjectDispatchContext, {
  getProjectViewDispatchContext
} from "./ProjectDispatchContext";
import { ProjectMeta } from "../../dao/ProjectDao";

function ProjectMetaForm({ projectMeta }: { projectMeta: ProjectMeta }) {
  const [form] = Form.useForm();
  const { setModalComponent, projectMetaDispatch } =
    getProjectViewDispatchContext(useContext(ProjectDispatchContext));
  const { Text } = Typography;
  return (
    <Modal
      title={localizer.resolve("ProjectMeta.modalTitle")}
      open={true}
      okText={localizer.resolve("Global.buttonCaption.save")}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={form.submit}
      onCancel={() => {
        form.resetFields();
        setModalComponent(null);
      }}
      destroyOnClose={true}
    >
      <Form
        initialValues={projectMeta}
        onFinish={values => {
          projectMetaDispatch({
            type: "updateMeta",
            data: { ...projectMeta, ...values }
          });
          setModalComponent(null);
        }}
        preserve
        form={form}
        size="large"
      >
        <Form.Item
          label={localizer.resolve("ProjectMeta.workTitle.label")}
          name="workTitle"
          rules={[
            {
              max: 254,
              message: localizer.resolve(
                "ProjectMeta.workTitle.message.maxLength"
              )
            },
            {
              required: true,
              message: localizer.resolve(
                "ProjectMeta.workTitle.message.mandatory"
              )
            }
          ]}
        >
          <Input maxLength={255} />
        </Form.Item>
        <ImageUpload
          name="coverUrl"
          label={localizer.resolve("ProjectMeta.coverUrl.label")}
          extra={localizer.resolve("ProjectMeta.coverUrl.extra")}
          initialUrl={projectMeta.coverUrl}
        />
        <Form.Item label={localizer.resolve("ProjectMeta.language.label")}>
          <Text>
            {localizer.getLanguageDescription(
              projectMeta.language || getBrowserLocale()
            )}
          </Text>
        </Form.Item>
        <Form.Item label={localizer.resolve("ProjectMeta.projectType.label")}>
          <Text>
            {localizer.resolve(`ProjectType.${projectMeta.projectType}`)}
          </Text>
        </Form.Item>
        <Form.Item
          label={localizer.resolve("ProjectMeta.genre.label")}
          name="genre"
          rules={[
            {
              max: 254,
              message: localizer.resolve("ProjectMeta.genre.message.maxLength")
            }
          ]}
        >
          <Input maxLength={255} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ProjectMetaForm;
