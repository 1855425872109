import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import Feedback from "../general/Feedback";
import subscriptionDao from "../../dao/SubscriptionDao";
import { DateTime } from "luxon";
import { localizer } from "di-common";
import { User } from "../../StateContext";

type PaymentResultPanelProps = {
  currentUser: User;
  dispatch: any;
};

type PaymentInfo = {
  userInfo: User;
  status: string;
  subscriptionType: string;
};

/**
 * Give feedback on result payment process, update frozen status, by setting the
 * new value of globalstate currentUser and redirect to dashboard? -- or previous page
 * from where payment was initiated?
 */
function PaymentResultPanel({
  currentUser,
  dispatch
}: PaymentResultPanelProps) {
  const { paymentId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    //load payment and user info from server
    if (paymentId) {
      subscriptionDao.getPaymentInfo(
        currentUser,
        paymentId,
        (response: any) => {
          setLoading(false);
          processPaymentInfo(response.data);
        },
        console.dir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  function processPaymentInfo({
    userInfo,
    status,
    subscriptionType
  }: PaymentInfo) {
    let message = "";
    if (status === "PAID") {
      const endDate = DateTime.fromISO(userInfo.validTill)
        .setLocale(localizer.getCurrentLocale())
        .toLocaleString(DateTime.DATE_FULL);
      message += localizer.resolve("Payment.success");
      if (subscriptionType === "PREPAID_FLEX") {
        message += localizer.resolve("Payment.flexPeriodEnd", {
          flexEnd: endDate
        });
      } else {
        message += localizer.resolve("Payment.nextInstallment", {
          endDate
        });
      }
      message += localizer.resolve("Payment.haveFun");
    } else if (status !== "OPEN") {
      message += localizer.resolve("Payment.unpaid");
    } else {
      message += localizer.resolve("Payment.error");
    }
    dispatch({ type: "changeUserInfo", data: userInfo });
    setFeedback(message);
  }

  if (isLoading) {
    return <Spin size="large" />;
  }

  return <Feedback feedback={feedback} />;
}

export default PaymentResultPanel;
