import Axios from "axios";
import { User, UserPreferences } from "../StateContext";
import { getBrowserLocale } from "di-common";

/**
 * Describes a single userpreference (or setting) of a collection of userpreferences that are stored server side.
 */
export type ServerStoredPreference = {
  name: string;
  type: string;
  value: string;
};

class UserDao {
  loadSettings(currentUser: User, responseCallback: any, errorCallback: any) {
    Axios.get(`/api/private/user/settings?userId=${currentUser.id}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(response => {
      response.data = {
        ...this.getDefaultSettings(),
        ...transformFromDatabase(response.data)
      };
      return responseCallback(response);
    }, errorCallback);
  }

  saveSettings(currentUser: User, settings: UserPreferences, responseCallback: any, errorCallback: any) {
    Axios.post(`/api/private/user/settings?userId=${currentUser.id}`, transformToDatabase(settings), {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  changePassword(currentUser: User, passwordChangeRequest: any, responseCallback: any, errorCallback: any) {
    Axios.put(`/api/private/user/password/${currentUser.id}`, passwordChangeRequest, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  createAccount(createAccountRequest: any, responseCallback: any, errorCallback: any) {
    Axios.post("/api/public/user", createAccountRequest).then(responseCallback, errorCallback);
  }

  activateUser(userId: string, activationCode: string, responseCallback: any, errorCallback: any) {
    Axios.put(`/api/public/user/activate/${userId}/${activationCode}`).then(responseCallback, errorCallback);
  }

  getDefaultSettings(): UserPreferences {
    return {
      isAutoSaveOn: true,
      autoSaveInterval: 30,
      uiLocale: getBrowserLocale()
    };
  }
}

function transformToDatabase(appSettings: UserPreferences): ServerStoredPreference[] {
  const persistablePreferences: ServerStoredPreference[] = [];
  for (const entry in appSettings) {
    persistablePreferences.push({
      name: entry,
      type: typeof appSettings[entry],
      value: String(appSettings[entry])
    });
  }
  return persistablePreferences;
}

/**
 * Transform an array with setting objects (name, type, value) into an object
 * where each setting is transformed into a typed property.
 */
function transformFromDatabase(dbSettings: ServerStoredPreference[]): UserPreferences {
  const appSettings: UserPreferences = {};
  if (dbSettings) {
    if (Array.isArray(dbSettings)) {
      for (const setting of dbSettings) {
        let value: string | number | boolean | null = null;
        switch (setting.type) {
          case "string":
            value = setting.value;
            break;
          case "number":
            value = Number(setting.value);
            break;
          case "boolean":
            value = setting.value === "true" ? true : false;
            break;
          default:
            console.error(`[UserDao.transformFromDatabase] Unsupported setting type: ${setting.type}; falling back to string`);
            value = setting.value;
        }
        appSettings[setting.name] = value;
      }
    }
  }
  return appSettings;
}

const userDao = new UserDao();
export default userDao;
