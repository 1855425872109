import React, { createContext } from "react";
import { GlobalAction } from ".";
import { Updater } from "use-immer";
import { UserPreferences } from "./StateContext";

export type DispatchContextType = {
  dispatch: React.Dispatch<GlobalAction>;
  updateUserPreferences: Updater<UserPreferences>;
};

const DispatchContext = createContext<DispatchContextType | null>(null);

export function getDispatchContext(
  dispatchContext: DispatchContextType | null
): DispatchContextType {
  if (!dispatchContext) {
    throw new Error("Dispatch context not defined");
  }
  return dispatchContext;
}

export default DispatchContext;
