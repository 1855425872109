import React, { useContext, useState } from "react";
import { Form, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import projectDao, { ProjectMeta } from "../../dao/ProjectDao";
import ProjectTypeFormItem from "../projects/ProjectTypeFormItem";
import ProjectLanguageFormItem from "../projects/ProjectLanguageFormItem";
import { Idea } from "./IdeaDetails";
import { v4 as uuidv4 } from "uuid";
import { ProjectTypeName } from "../../utils/OutlineManager";

type IdeaToProjectFormProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  idea: Idea;
};

function IdeaToProjectForm({ showModal, setShowModal, idea }: IdeaToProjectFormProps) {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isPromoting, setPromoting] = useState(false);
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  //send data to server
  function sendRequest(values: any) {
    if (projectId && currentUser) {
      setPromoting(true);
      projectDao.promoteIdea(
        createProjectMeta(idea, values.language, values.projectType),
        currentUser,
        (response: any) => {
          setPromoting(false);
          if (response.data === true) {
            navigate(`/projects/${projectId}`);
          }
        },
        (error: any) => {
          setPromoting(false);
          console.error(error);
        }
      );
    }
  }

  function createProjectMeta(idea: Idea, language: string, projectType: ProjectTypeName): ProjectMeta {
    const meta = {
      id: projectId || uuidv4(),
      workTitle: idea.workTitle || "",
      coverUrl: idea.coverUrl,
      projectType,
      language,
      genre: idea.genre
    };
    return meta;
  }

  return (
    <Modal
      title={localizer.resolve("IdeaDetails.modalTitle")}
      open={showModal}
      okText={localizer.resolve("Global.buttonCaption.save")}
      okButtonProps={{ loading: isPromoting }}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={form.submit}
      onCancel={() => {
        form.resetFields();
        setShowModal(false);
      }}
      destroyOnClose={true}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={sendRequest}
        initialValues={{
          language: localizer.getCurrentLocale()
        }}
      >
        <ProjectLanguageFormItem />
        <ProjectTypeFormItem />
      </Form>
    </Modal>
  );
}

export default IdeaToProjectForm;
