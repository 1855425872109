import React from "react";
import { ControlOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { localizer } from "di-common";
import { useLocation, useNavigate } from "react-router-dom";

type PlotDesignerButtonProps = {
  projectId: string;
};

/**
 * This button is aware of the current state of the Plot Designer (open or closed),
 * based on the URL. It will display a different Icon to go to the next state (open
 * or close). This component is an alternatice for the Switch-component, because I
 * think it looks out of place in the button bar.
 *
 * @param param0 T
 * @returns
 */
export default function PlotDesignerButton({ projectId }: PlotDesignerButtonProps) {
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;
  const isPlotDesignerOpened: boolean = currentPath.startsWith("/plot");

  return isPlotDesignerOpened ? null : (
    <Tooltip title={localizer.resolve("PlotDesigner.buttonCaption.openPlottingTool")}>
      <Button
        type="default"
        size="large"
        shape="circle"
        icon={<ControlOutlined />}
        htmlType="button"
        // should we save when autosave is on?
        onClick={() => navigate(`/plot/${projectId}`)}
      />
    </Tooltip>
  );
}
