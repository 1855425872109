import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, Link, useBlocker, useNavigate } from "react-router-dom";
import { localizer, NotFound } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import Page from "../general/Page";
import { fragmentMainCols, fragmentTailLayout, fragmentFormLayout } from "../LayoutConstants";
import { Form, Spin, Input, Row, Col, message, Space } from "antd";
import fragmentDao, { FragmentItem } from "../../dao/FragmentDao";
import { useImmerReducer } from "use-immer";
import { wordCount } from "../../utils/wordCount";
import { isNotEqual } from "../../utils/MiscellaneousUtil";
import { CloseButton, ExportButton, SaveButton } from "../general/CommonButtons";
import SlateEditor from "../general/SlateEditor";
import { GlobalAction } from "../..";
import { ConsoleLogger } from "di-common";
import { Content } from "../../dao/ProjectDao";
import docxExportService from "../../services/DocXExportService";
import UnsavedChangesModal from "../general/UnsavedChangesModal";
import { useBeforeunload } from "react-beforeunload";

const logger = new ConsoleLogger("Fragment");

type FragmentState = {
  isLoading: boolean;
  wordCount: number;
  notFound: boolean;
};

function createEmptyFragment(fragmentId?: string): FragmentItem {
  return {
    id: fragmentId,
    title: "",
    fragment: "",
    wordCount: 0
  };
}

function Fragment({ isDisabled = false }) {
  const syncedFragmentRef = useRef(createEmptyFragment());
  const copyToSaveRef = useRef(createEmptyFragment());

  const [isDirty, setDirty] = useState(false);

  //Indicate if the current save operation is triggered by the user or by the autosave mechanism
  const [saveSource, setSaveSource] = useState<"none" | "user" | "autosave">("none");

  /* Can have two types of values: "saving" means that a save operation is running and we are waiting for server response,
   * "success" means that the previous request was succesfully finished, otherwise it has the value of the error received from
   * the server
   */
  const [saveState, setSaveState] = useState<"saving" | "success" | string | null>(null);

  //Use saveCount to trigger save operation with useEffect
  const [saveCount, setSaveCount] = useState(0);

  const {
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));
  const { fragmentId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const initialState: FragmentState = {
    /* Indicates weather a load operation is active (waiting for server response). A load operation normally
       is only triggered when the page is first displayed
     */
    isLoading: true,

    wordCount: 0,

    notFound: false
  };

  function localReducer(draft: FragmentState, action: GlobalAction): void {
    logger.debug("ContentReducer: processing action :", action.type);
    switch (action.type) {
      case "saveSuccess":
        //This piece of code is called twice when the fragment is saved (!!) - I don't understand it
        logger.info(localizer.resolve("Global.feedback.saveSuccess"));
        setSaveState("success");
        syncedFragmentRef.current = { ...copyToSaveRef.current };
        setDirty(isDirtyCheck());
        break;
      case "saveFailed":
        logger.info(`There was a problem saving the fragment: ${action.data}`);
        setSaveState(null);
        break;
      case "fragmentReceived":
        draft.isLoading = false;
        draft.wordCount = wordCount(action.data.fragment);
        syncedFragmentRef.current = { ...action.data, wordCount: draft.wordCount };
        copyToSaveRef.current = { ...action.data, wordCount: draft.wordCount };
        break;
      case "loadFailed":
        draft.isLoading = false;
        logger.info(`There was a problem loading a fragment: ${action.data.message}`);
        if (action.data.response.status === 404) {
          draft.notFound = true;
          syncedFragmentRef.current = createEmptyFragment(fragmentId);
          copyToSaveRef.current = createEmptyFragment();
        }
        break;
      case "exportFragment":
        let content: Content[] = [
          {
            content: copyToSaveRef.current.fragment,
            contentMeta: {
              key: "1",
              pathDescriptor: "0001",
              title: copyToSaveRef.current.title,
              type: "SCENE",
              wordCount: draft.wordCount
            }
          }
        ];
        docxExportService.processProject("SHORT_STORY", copyToSaveRef.current.title, content);
        break;
      case "updateWordCount":
        draft.wordCount = action.data;
        break;
      default:
        logger.info(`Command not defined: ${action.type}`);
    }
  }

  const [state, dispatch] = useImmerReducer(localReducer, initialState);

  function isDirtyCheck() {
    return (
      isNotEqual(syncedFragmentRef.current.title, form.getFieldValue("title")) ||
      isNotEqual(syncedFragmentRef.current.fragment, form.getFieldValue("fragment"))
    );
  }

  function autosave() {
    if (isDirtyCheck()) {
      setSaveSource("autosave");
      form.submit();
    }
  }

  useEffect(() => {
    //load initial fragment data when page is first loaded
    if (fragmentId && currentUser) {
      fragmentDao.loadFragment(
        fragmentId,
        currentUser,
        (response: any) => dispatch({ type: "fragmentReceived", data: response.data }),
        (error: any) => dispatch({ type: "loadFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //give user feedback about result of manual save operation
    if (saveCount > 0 && saveState !== "saving" && saveSource === "user") {
      setSaveSource("none");
      if (saveState === "success") {
        message.success(localizer.resolve("Global.feedback.saveSuccess"));
      } else {
        message.error(localizer.resolve("Global.feedback.saveError"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount, saveState, saveSource]);

  useEffect(() => {
    if (isDisabled === true) {
      message.info(localizer.resolve(`Global.message.${currentUser?.reasonBecomingInvalid}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        autosave();
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      console.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      console.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  function handleClose() {
    if (isDirtyCheck()) {
      if (userPreferences.isAutoSaveOn === true) {
        autosave();
      } else {
        return "Unsaved changes, do not close!";
      }
    }
    return null;
  }

  useBeforeunload(() => (isDirty ? "Unsaved changes, do not close!" : null));
  const blocker = useBlocker(() => handleClose() != null);

  function handleChangedFields(changedFields: any) {
    setDirty(isDirtyCheck());
    if (changedFields.length > 0) {
      if (changedFields[0].name[0] === "fragment") {
        copyToSaveRef.current.fragment = changedFields[0].value;
        dispatch({
          type: "updateWordCount",
          data: wordCount(changedFields[0].value)
        });
      } else if (changedFields[0].name[0] === "title") {
        copyToSaveRef.current.title = changedFields[0].value;
      }
    }
  }

  function sendForm(values: any): void {
    copyToSaveRef.current = {
      ...values,
      id: fragmentId,
      wordCount: state.wordCount
    };

    if (currentUser) {
      setSaveState("saving");
      setSaveCount(saveCount + 1);
      console.dir("Saving", copyToSaveRef.current);
      fragmentDao.updateFragment(
        copyToSaveRef.current,
        currentUser,
        (response: any) => dispatch({ type: "saveSuccess" }),
        (error: any) => dispatch({ type: "saveFailed", data: error })
      );
    }
  }

  if (state.isLoading) {
    return <Spin size="large" />;
  }

  if (state.notFound) {
    return <NotFound />;
  }

  return (
    <Page>
      <div>
        <Row>
          <Col {...fragmentMainCols}>
            <Link to="/fragments">&laquo; {localizer.resolve("Fragment.backToFragments")}</Link>
          </Col>
        </Row>
        <Form.Item {...fragmentTailLayout}>
          <Space size="small">
            <SaveButton
              isDisabled={isDisabled}
              isDirty={isDirty}
              isSaving={saveState === "saving"}
              save={() => {
                setSaveSource("user");
                form.submit();
              }}
            />
            <CloseButton
              isDirty={isDirty}
              close={() => {
                // this makes sure that isDirtyCheck will result to false for the useBlocker-guard
                form.setFieldsValue(syncedFragmentRef.current);
                navigate("/fragments");
              }}
            />
            <ExportButton
              name={copyToSaveRef.current.title}
              exportFunction={() =>
                dispatch({
                  type: "exportFragment"
                })
              }
            />
            <span className="fragment__wordcount">
              {localizer.resolve("Fragment.wordCount.label")}: <span className="ant-typography">{state.wordCount}</span>
            </span>
          </Space>
        </Form.Item>
      </div>
      <main>
        <Form
          {...fragmentFormLayout}
          initialValues={syncedFragmentRef.current}
          onFieldsChange={handleChangedFields}
          onFinish={values => sendForm(values)}
          preserve
          form={form}
          size="large"
        >
          <div className="fragment__content">
            <Form.Item
              label={localizer.resolve("Fragment.title.label")}
              name="title"
              rules={[
                {
                  max: 254,
                  message: localizer.resolve("Fragment.title.message.maxLength")
                },
                {
                  required: true,
                  message: localizer.resolve("Fragment.title.message.mandatory")
                }
              ]}
            >
              <Input maxLength={255} disabled={isDisabled} />
            </Form.Item>
            <Form.Item
              label={localizer.resolve("Fragment.fragment.label")}
              name="fragment"
              rules={[
                {
                  /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
                  max: 65000,
                  message: localizer.resolve("Fragment.fragment.message.maxLength")
                }
              ]}
            >
              <SlateEditor disabled={isDisabled} />
              {/* <Input.TextArea rows={25} maxLength={65001} disabled={isDisabled} /> */}
            </Form.Item>
          </div>
        </Form>
      </main>
      {blocker.state === "blocked" && (
        <UnsavedChangesModal
          onOk={() => {
            blocker.proceed();
          }}
          onCancel={() => {
            // setDirty(false);
            blocker.reset();
          }}
          registryEntries={[
            {
              id: "fragment",
              name: localizer.resolve("UnsavedChangesModal.entityEditor", {
                entityName: localizer.resolve("Fragment.fragment.label")
              }),
              isDirty: () => isDirty,
              autosave
            }
          ]}
        />
      )}
    </Page>
  );
}

export default Fragment;
