import Axios from "axios";
import { User } from "../StateContext";

export type FragmentItem = {
  id?: string;
  title: string;
  fragment: string;
  wordCount: number;
};

export type FragmentExcerpt = {
  readonly id: string;
  readonly title: string;
  readonly fragmentExcerpt: string;
};

class FragmentDao {
  loadFragmentList(
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get<FragmentExcerpt[]>("/api/private/fragments/" + currentUser.id, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  insertFragment(
    fragment: FragmentItem,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.post("/api/private/fragments/" + currentUser.id, fragment, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  loadFragment(
    fragmentId: string,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get("/api/private/fragments/" + currentUser.id + "/" + fragmentId, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  updateFragment(
    fragment: FragmentItem,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.put(
      "/api/private/fragments/" + currentUser.id + "/" + fragment.id,
      fragment,
      {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }
    ).then(responseCallback, errorCallback);
  }

  deleteFragment(
    fragmentId: string,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.delete(
      "/api/private/fragments/" + currentUser.id + "/" + fragmentId,
      {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }
    ).then(responseCallback, errorCallback);
  }
}

const fragmentDao = new FragmentDao();
export default fragmentDao;
