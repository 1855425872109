import React from "react";
import { localizer } from "di-common";
import IconFont from "./IconFont";
import { Typography } from "antd";
import { IdentityProps } from "../../dao/EntityDao";
import moment from "moment";
import { DateTime } from "luxon";

const { Paragraph, Text } = Typography;

/**
 * Check the type of a fieldValue by looking at the name of the constructor function. However,
 * when the JavaScript code is obfuscated, we need to change our referencepoint. We do this by
 * determining the actual constructor name at runtime.
 */
export const MOMENT_TYPENAME = moment().constructor.name;

export function concatIdentity({ age, name, gender }: IdentityProps): React.JSX.Element {
  let genderSymbol = null;
  switch (gender) {
    case "MALE":
      genderSymbol = <IconFont type="icon-gendermale" />;
      break;
    case "FEMALE":
      genderSymbol = <IconFont type="icon-genderfemale" />;
      break;
    case "TRANS":
      genderSymbol = <IconFont type="icon-gendermalefemale" />;
      break;
    default:
      genderSymbol = "";
  }

  let ageDescription = null;
  if (age) {
    ageDescription = `(${age}${localizer.resolve("Global.abbreviation.year")})`;
  }

  return (
    <span>
      {name} {genderSymbol} {ageDescription}
    </span>
  );
}

export function selectDescription({ roleInStory }: { roleInStory?: string }): string {
  return roleInStory || "";
}

export function displayField(entityName: string, fieldName: string, entity: any) {
  if (entity && entity[fieldName]) {
    let fieldValue = entity[fieldName];
    if (fieldValue.constructor.name === MOMENT_TYPENAME) {
      fieldValue = DateTime.fromISO(fieldValue.format("YYYY-MM-DD"))
        .setLocale(localizer.getCurrentLocale())
        .toLocaleString(DateTime.DATE_FULL);
    }
    return (
      <Paragraph key={fieldName}>
        <Text strong>{localizer.resolve(`${entityName}.${fieldName}.label`)}: </Text>
        {fieldValue}
      </Paragraph>
    );
  }
  return "";
}

export function capitalizeFirstCharacter(value: string | null | undefined) {
  if (value && typeof value === "string") {
    return value[0].toUpperCase() + value.substring(1);
  }
  return value;
}

export function formatNickNamesForDisplay({ nickNames }: { nickNames?: string[] }): string[] {
  if (nickNames) {
    let conjunction = localizer.resolve("EntityPane.knownAs") + ": ";
    return nickNames.map(nickName => {
      const result = `${conjunction} '${nickName}'`;
      conjunction = " " + localizer.resolve("Global.conjunction.or") + " ";
      return result;
    });
  }
  return [];
}
