import { GlobalAction } from "../..";
import { ProjectMetaState } from "./ProjectView";

/**
 * The code in this module manages the state related to the projectMeta data.
 */
export default function projectMetaReducer(draft: ProjectMetaState, action: GlobalAction) {
  switch (action.type) {
    case "initialize":
      draft.projectMeta = action.data;
      break;
    case "updateMeta":
      draft.projectMeta = action.data;
      draft.updateCount++;
      break;
    default:
      console.error(`ProjectView: Unexpected action type: ${action.type}`);
  }
}
