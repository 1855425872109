import rfdc from "rfdc";
import { v4 as uuidv4 } from "uuid";
import { ContentMeta, StructuralTypeName } from "../dao/ProjectDao";
import { LocalizationService } from "di-common";
const deepClone = rfdc();

const PATH_SEPARATOR = "_";

export type ProjectTypeName = "NOVEL" | "SHORT_STORY" | "TEXTBOOK" | "POETRY_BUNDLE" | "SCRIPT";

type ProjectType = {
  id: ProjectTypeName;
  supportedTypes: StructuralTypeName[];
  containableTypes: StructuralTypeName[];
  isStory: boolean;
};

type NodeType = {
  id: string;
  isContainer: boolean;
  containableTypes?: StructuralTypeName[];
};

export type OutlineNode = ContentMeta & {
  path: number[];
  children?: OutlineNode[];
};

export type OutlineRootNode = Omit<OutlineNode, "type" | "title" | "wordCount"> & {
  type: ProjectTypeName;
};

const PROJECT_TYPES = new Map<ProjectTypeName, ProjectType>();
PROJECT_TYPES.set(
  "NOVEL",
  Object.freeze<ProjectType>({
    id: "NOVEL",
    supportedTypes: ["CHAPTER", "SCENE"],
    containableTypes: ["CHAPTER"],
    isStory: true
  })
);
PROJECT_TYPES.set(
  "SHORT_STORY",
  Object.freeze<ProjectType>({
    id: "SHORT_STORY",
    supportedTypes: ["SCENE"],
    containableTypes: ["SCENE"],
    isStory: true
  })
);
PROJECT_TYPES.set(
  "TEXTBOOK",
  Object.freeze<ProjectType>({
    id: "TEXTBOOK",
    supportedTypes: ["CHAPTER", "SECTION", "TEXT"],
    containableTypes: ["CHAPTER"],
    isStory: false
  })
);
PROJECT_TYPES.set(
  "POETRY_BUNDLE",
  Object.freeze<ProjectType>({
    id: "POETRY_BUNDLE",
    supportedTypes: ["POEM"],
    containableTypes: ["POEM"],
    isStory: false
  })
);
PROJECT_TYPES.set(
  "SCRIPT",
  Object.freeze<ProjectType>({
    id: "SCRIPT",
    supportedTypes: ["ACT", "SCENE"],
    containableTypes: ["ACT"],
    isStory: true
  })
);

const NODE_TYPES = new Map<StructuralTypeName, NodeType>([
  [
    "CHAPTER",
    {
      id: "CHAPTER",
      isContainer: true,
      containableTypes: ["SECTION", "TEXT", "SCENE"]
    }
  ],
  ["SECTION", { id: "SECTION", isContainer: true, containableTypes: ["SECTION", "TEXT"] }],
  ["SCENE", { id: "SCENE", isContainer: false }],
  ["TEXT", { id: "SCENE", isContainer: false }],
  ["POEM", { id: "POEM", isContainer: false }],
  ["ACT", { id: "ACT", isContainer: true, containableTypes: ["SCENE"] }]
]);

export function isStory(projectType: ProjectTypeName): boolean {
  let isStoryProject = true; //the default for unknown types
  if (PROJECT_TYPES.has(projectType)) {
    isStoryProject = PROJECT_TYPES.get(projectType)?.isStory === true || false;
  }
  return isStoryProject;
}

export function isContentType(typeName?: StructuralTypeName) {
  if (typeName == null) {
    return false;
  }
  if (!NODE_TYPES.has(typeName)) {
    return false;
  }
  return !NODE_TYPES.get(typeName)?.isContainer;
}

export function isContainerType(typeName?: StructuralTypeName) {
  if (typeName == null) {
    return false;
  }
  if (!NODE_TYPES.has(typeName)) {
    return false;
  }
  return NODE_TYPES.get(typeName)?.isContainer;
}

export function isValidDropTarget(
  projectType: ProjectTypeName,
  draggedNode: OutlineNode,
  dropNode: OutlineNode,
  dropIndex: number
): boolean {
  if (draggedNode.key === dropNode.key) {
    return false; // Not allowed to drop onto dragged (cannot drop onto yourself)
  }

  const draggedNodeType: StructuralTypeName = draggedNode.type;
  const dropNodeType: StructuralTypeName = dropNode.type;

  //sanity check
  if (
    !PROJECT_TYPES.has(projectType) ||
    !PROJECT_TYPES.get(projectType)?.supportedTypes.includes(dropNodeType) ||
    !PROJECT_TYPES.get(projectType)?.supportedTypes.includes(draggedNodeType)
  ) {
    return false; //programming error?
  }

  if (dropIndex === -1) {
    //special case
    return PROJECT_TYPES.get(projectType)?.containableTypes.includes(draggedNodeType) || false;
  }

  //valid, if same type (at least siblings, maybe child (eg. with regards to SECTION))
  if (draggedNodeType === dropNodeType) {
    return true;
  }

  //valid, if dropType can contain draggedType (child)
  return (
    (NODE_TYPES.has(dropNodeType) &&
      NODE_TYPES.get(dropNodeType)?.isContainer &&
      NODE_TYPES.get(dropNodeType)?.containableTypes?.includes(draggedNodeType)) ||
    false
  );
}

export default class OutlineManager {
  private projectType: ProjectTypeName;
  private treeData: OutlineNode[] = [];
  private backup: OutlineNode[] | null = null;

  private constructor(projectType: ProjectTypeName) {
    if (!PROJECT_TYPES.get(projectType)) {
      throw new Error("Project type not supported: " + projectType);
    }
    this.projectType = projectType;
  }

  static createFromFlatData(projectType: ProjectTypeName, flatData: ContentMeta[]) {
    const outlineManager = new OutlineManager(projectType);
    outlineManager.treeData = outlineManager.makeHierarchical(flatData);
    return outlineManager;
  }

  static createFromTreeData(projectType: ProjectTypeName, treeData: OutlineNode[]) {
    const outlineManager = new OutlineManager(projectType);
    outlineManager.treeData = treeData;
    return outlineManager;
  }

  /**
   * Allow usage of different set of tree data, e.g. a draft instance provided by the immer package
   * @param {*} treeDataDraft
   */
  withDraft(treeDataDraft: OutlineNode[]) {
    this.backup = this.treeData;
    this.treeData = treeDataDraft;
    // return this;
  }

  decoupleDraft() {
    if (this.backup) {
      this.treeData = this.backup;
      this.backup = null;
    }
  }

  /* From the container that is the parent of the current content item, request for the types
   * it can contain. Users can insert these items before or after the current item.
   * If the current item is a container, ask the container what items it can contain. These can be
   * appended as a child to the current container.
   */
  getAllowedSiblingTypes(pathDescriptor: string): StructuralTypeName[] {
    if (pathDescriptor) {
      const parentNode = findParentNode(this.treeData, toPathElements(pathDescriptor));
      return this.getAllowedChildTypes(parentNode?.type || this.projectType);
    } else {
      return PROJECT_TYPES.get(this.projectType)?.containableTypes || [];
    }
  }

  getAllowedChildTypes(currentNodeType: StructuralTypeName | ProjectTypeName): StructuralTypeName[] {
    const containableTypes =
      NODE_TYPES.get(currentNodeType as StructuralTypeName)?.containableTypes ||
      PROJECT_TYPES.get(currentNodeType as ProjectTypeName)?.containableTypes ||
      [];
    const supportedTypes = PROJECT_TYPES.get(this.projectType)?.supportedTypes || [];
    return supportedTypes.filter(element => containableTypes.includes(element));
  }

  public getTreeData() {
    return this.treeData;
  }

  public getProjectType(): string {
    return this.projectType;
  }

  /**
   * Copy the flat contentMeta array and modify the copy to reflect a hierarchical structure using the pathDescriptor information.
   * Each node that is a parent, will get a new property named 'children', containing it's child elements.
   *
   * @param {Array} contentMetas
   */
  makeHierarchical(contentMetas: ContentMeta[] = []) {
    const copy: OutlineNode[] = copyAndEnrichContentMeta(contentMetas);
    sortByPathLength(copy);

    const rootNode = this.getRootNode();
    let i = 0;
    while (i < copy.length) {
      const path = copy[i].path;
      if (path.length > 1) {
        // process the child nodes
        const relocateNode = copy.splice(i, 1)[0]; //take out the current node from copy, otherwise it could think it was it's own parent
        const parentNode = findParentNode(copy, path);
        if (!parentNode) {
          throw new Error(`No ContentMeta found with path=${path} to take on parent role`);
        }

        checkContainability(parentNode, relocateNode.type);
        parentNode.children?.push(relocateNode); //we should not add as last item, but find correct index, based on path value
      } else {
        checkContainability(rootNode, copy[i].type);
        i++;
      }
    }
    return copy;
  }

  updateTitle(targetPathDescriptor: string, title: string) {
    const node = this.getNode(targetPathDescriptor);
    if (isOutlineNode(node)) {
      node.title = title;
    }
  }

  /**
   * Visit each node in the treeModel and update it's word count. There is no word count of actual content,
   * only recalculation of word count based on child element word count.
   */
  updateWordCount(targetPathDescriptor?: string, newWordCount?: number) {
    if (targetPathDescriptor && typeof newWordCount === "number") {
      const node = this.getNode(targetPathDescriptor);
      if (isOutlineNode(node)) {
        node.wordCount = newWordCount;
      }
    }
    return updateWordCount(this.treeData);
  }

  isContentNode(nodeType: StructuralTypeName) {
    return isContentType(nodeType);
  }

  getNextContentNode(targetPathDescriptor: string): OutlineNode | null {
    const nodeArray: OutlineNode[] = [];
    collectNodes(this.getRootNode(), nodeArray);
    for (let i = 0; i < nodeArray.length; i++) {
      if (nodeArray[i].pathDescriptor === targetPathDescriptor) {
        let nextIndex = i + 1;
        while (nextIndex < nodeArray.length) {
          if (this.isContentNode(nodeArray[nextIndex].type)) {
            return nodeArray[nextIndex];
          }
          nextIndex++;
        }
      }
    }
    return null;
  }

  getPreviousContentNode(targetPathDescriptor: string): OutlineNode | null {
    const nodeArray: OutlineNode[] = [];
    collectNodes(this.getRootNode(), nodeArray);
    for (let i = nodeArray.length - 1; i >= 0; i--) {
      if (nodeArray[i].pathDescriptor === targetPathDescriptor) {
        let previousIndex = i - 1;
        while (previousIndex >= 0) {
          if (this.isContentNode(nodeArray[previousIndex].type)) {
            return nodeArray[previousIndex];
          }
          previousIndex--;
        }
      }
    }
    return null;
  }

  getNearestNode(targetPathDescriptor: string) {
    const path = toPathElements(targetPathDescriptor);
    return getNearestNode(this.treeData, path, 0);
  }

  getNode(targetPathDescriptor: string) {
    const node = this.findNode(targetPathDescriptor);
    if (node === null) {
      throw new Error("No such TreeNode with pathDescriptor: " + targetPathDescriptor);
    }
    return node;
  }

  findNode(targetPathDescriptor: string) {
    let targetNode = this.findContainerNode(targetPathDescriptor);
    if (targetNode && targetNode.pathDescriptor !== targetPathDescriptor) {
      for (const child of targetNode.children || []) {
        if (child.pathDescriptor === targetPathDescriptor) {
          targetNode = child;
          break;
        }
      }
    }

    if (targetNode && targetNode.pathDescriptor === targetPathDescriptor) {
      return targetNode;
    }
    return null;
  }

  /**
   * Find the node identified by the targetPathDescriptor. If it is a container, return it, otherwise,
   * return it's parent node.
   *
   * @param {String} targetPathDescriptor the pathDescriptor
   * @returns the container node closest to or refering to the targetPathDescriptor
   */
  findContainerNode(targetPathDescriptor: string): OutlineNode | OutlineRootNode | null {
    if (targetPathDescriptor) {
      const path = toPathElements(targetPathDescriptor);
      if (path.length > 1) {
        return findParentNode(this.treeData, path);
      } else {
        for (const item of this.treeData) {
          if (item.pathDescriptor === targetPathDescriptor && NODE_TYPES.get(item.type)?.isContainer) {
            return item;
          }
        }
      }
    }
    return this.getRootNode();
  }

  getRootNode(): OutlineRootNode {
    return {
      path: [],
      pathDescriptor: "",
      key: "root",
      children: this.treeData,
      type: this.projectType
    };
  }

  getPathDescriptor(nodeKey: string) {
    return getPathDescriptor(this.treeData, nodeKey);
  }

  appendChild(newNodeType: StructuralTypeName, containerPathDescriptor: string, localizer: LocalizationService): OutlineNode {
    checkSupportability(this.projectType, newNodeType);
    const containerNode = this.findContainerNode(containerPathDescriptor);
    if (containerNode === null) {
      throw new Error(`Cannot append ${newNodeType}, because ${containerPathDescriptor} does not resolve to a container node`);
    }
    checkContainability(containerNode, newNodeType);

    if (containerNode.children) {
      const newChildNode = createStructuralNode(
        newNodeType,
        containerNode.children?.length + 1 || 1,
        containerNode.pathDescriptor,
        localizer
      );
      containerNode.children.push(newChildNode);
      return newChildNode;
    }

    throw new Error("");
  }

  insertSibling(newNodeType: StructuralTypeName, siblingPathDescriptor: string, isAfter: boolean, localizer: LocalizationService) {
    checkSupportability(this.projectType, newNodeType);
    const parentNode = findParentNode(this.treeData, toPathElements(siblingPathDescriptor)) || this.getRootNode();

    if (!parentNode.children) {
      throw new Error(
        `Parent node of type ${parentNode.type} with pathDescriptor ${parentNode.pathDescriptor} has no children property to insert sibling`
      );
    }
    let insertIndex = indexOf(parentNode, siblingPathDescriptor);
    if (insertIndex > -1 && isAfter === true) {
      insertIndex++;
    }

    checkContainability(parentNode, newNodeType);
    const newSiblingNode = createStructuralNode(newNodeType, insertIndex + 1, parentNode.pathDescriptor, localizer);
    parentNode.children.splice(insertIndex, 0, newSiblingNode);

    //renumber remaining siblings and their ancestors
    let renumberedNodes: Map<string, string> | null = null;
    const mustRenumber = parentNode.children.length > 0 && insertIndex < parentNode.children.length;
    if (mustRenumber) {
      renumberedNodes = new Map();
      renumberNodes(parentNode, insertIndex + 1, renumberedNodes);
    }
    return { newSiblingNode, renumberedNodes };
  }

  /**
   * Check if the OutlineNode with the given pathDescriptor can be removed from the tree, while still having one or more nodes left
   * @param pathDescriptor
   * @returns
   */
  isLastRootNode(pathDescriptor: string) {
    //this implementation might be overcomplicated. A simpler one would be:
    const parentNode = findParentNode(this.treeData, toPathElements(pathDescriptor)) || this.getRootNode();
    return parentNode.path.length === 0 && parentNode.children?.length === 1;
  }

  moveNode(sourcePathDesciptor: string, targetPathDescriptor: string, dropPosition: number) {
    //move = delete + insert
    let insertChildIndex = -1;
    let targetContainer: OutlineNode | OutlineRootNode | null = null;
    const dropNode = this.getNode(targetPathDescriptor);
    const nodeToMove = this.getNode(sourcePathDesciptor) as OutlineNode;
    if (dropPosition === -1) {
      insertChildIndex = 0;
      targetContainer = this.getRootNode();
    } else if (
      NODE_TYPES.get(dropNode.type as StructuralTypeName)?.isContainer &&
      NODE_TYPES.get(dropNode.type as StructuralTypeName)?.containableTypes?.includes(nodeToMove.type)
    ) {
      //insert as first child in dropNode container
      targetContainer = dropNode;
      insertChildIndex = 0;
    } else if (dropNode.type === nodeToMove.type) {
      //move as siblings (or as child, as could be the case for SECTION) -- use the parentContainer of dropNode
      targetContainer = findParentNode(this.treeData, toPathElements(targetPathDescriptor)) || this.getRootNode();

      if (targetContainer.children) {
        for (let i = 0; i < targetContainer.children.length; i++) {
          if (targetContainer.children[i].key === dropNode.key) {
            insertChildIndex = i + 1;
            break;
          }
        }
      }
    }

    if (!targetContainer || !targetContainer.children) {
      throw new Error("Could not move node to new location");
    }

    checkContainability(targetContainer, nodeToMove.type);

    //delete, but do not renumber
    const sourceContainer = findParentNode(this.treeData, toPathElements(sourcePathDesciptor)) || this.getRootNode();

    if (sourceContainer.children) {
      for (let i = 0; i < sourceContainer.children.length; i++) {
        const child = sourceContainer.children[i];
        if (child.pathDescriptor === sourcePathDesciptor) {
          sourceContainer.children.splice(i, 1);
          break;
        }
      }
    }

    //insert at the right index of
    targetContainer.children.splice(insertChildIndex, 0, nodeToMove);

    //renumber all nodes that have been relocated in the tree -- simplistic approach
    const renumberedNodes = new Map<string, string>();
    renumberNodes(this.getRootNode(), 0, renumberedNodes);
    return renumberedNodes;
  }

  deleteNode(targetPathDescriptor: string): {
    nodesToDelete: string[];
    renumberedAterDeletion: Map<string, string> | null;
  } {
    const nodesToDelete: string[] = [];
    let renumberedAterDeletion = null;

    const parentNode = findParentNode(this.treeData, toPathElements(targetPathDescriptor)) || this.getRootNode();

    if (!parentNode.children) {
      throw new Error("Abort: parent node has no children");
    }

    if (parentNode.path.length === 0 && parentNode.children.length === 1) {
      //Special case: there must always be at least one node remaining, so we must empty the last child of the root node and not delete it
      //That also means: doing a delete request and a save request (atomically)
      const nodeToEmpty = parentNode.children[0];
      if (nodeToEmpty.children) {
        for (const child of nodeToEmpty.children) {
          collectNodeIds(child, nodesToDelete);
        }
        nodeToEmpty.children.length = 0; //delete all items in this array
      }
    } else {
      for (let i = 0; i < parentNode.children.length; i++) {
        const child = parentNode.children[i];
        if (child.pathDescriptor === targetPathDescriptor) {
          collectNodeIds(child, nodesToDelete);
          parentNode.children.splice(i, 1);
          if (i < parentNode.children.length) {
            renumberedAterDeletion = new Map<string, string>();
            renumberNodes(parentNode, i, renumberedAterDeletion);
          }
          break;
        }
      }
    }
    return { nodesToDelete, renumberedAterDeletion };
  }

  initialize(localizer: LocalizationService): OutlineNode {
    if (this.treeData.length === 0) {
      const nodeType = PROJECT_TYPES.get(this.projectType)?.containableTypes[0]; //just pick the first one
      if (nodeType) {
        const contentMeta: OutlineNode = createStructuralNode(nodeType, 1, undefined, localizer);
        this.treeData.push(contentMeta);
        return contentMeta;
      }
    }
    throw new Error("Error: project is already initialized");
  }
}

/* The functions below are outside the class definition and therefore not visible for an OutlineManager instance (unless exported (duh)).
 * Some of these functions are exported, so they can be used, but this is only done to make them testable as a unit.
 */

function getPathDescriptor(children: OutlineNode[], targetKey: string): string | null {
  for (const child of children) {
    if (child.key === targetKey) {
      return child.pathDescriptor;
    }
    if (child.children && child.children.length > 0) {
      const result = getPathDescriptor(child.children, targetKey);
      if (result) {
        return result;
      }
    }
  }
  return null;
}

/**
 * Check if the containerNode can be the parent for elements of the given child type. This check helps guard the consistency of the treeData
 * @param {*} containerNode
 * @param {String} childType
 */
export function checkContainability(containerNode: OutlineNode | OutlineRootNode, childType: StructuralTypeName) {
  let canContain: boolean;
  if (isOutlineNode(containerNode)) {
    canContain = NODE_TYPES.get(containerNode.type)?.containableTypes?.includes(childType) || false;
  } else {
    canContain = PROJECT_TYPES.get(containerNode.type)?.containableTypes?.includes(childType) || false;
  }
  if (!canContain) {
    throw new Error("Unsupported node type " + childType + " for container type " + containerNode.type);
  }
}

function updateWordCount(childNodes: OutlineNode[]) {
  // first count the leaf-nodes on a branch
  let count = 0;
  if (childNodes) {
    for (const child of childNodes) {
      if (child.children) {
        let childWordCount = updateWordCount(child.children);
        if (childWordCount !== child.wordCount) {
          child.wordCount = childWordCount;
        }
        count += childWordCount;
      } else {
        count += child.wordCount;
      }
    }
  }
  return count;
}

/**
 * Check if the nodeType is supported by the given projectType. If this is not the case, an error is thrown.
 */
function checkSupportability(projectType: ProjectTypeName, nodeType: StructuralTypeName) {
  if (!PROJECT_TYPES.get(projectType)?.supportedTypes.includes(nodeType)) {
    throw new Error("Unsupported node type " + nodeType + " for project type " + projectType);
  }
}

function collectNodeIds(node: OutlineNode, nodeIds: string[]) {
  nodeIds.push(node.key);
  if (node.children) {
    for (const child of node.children) {
      collectNodeIds(child, nodeIds);
    }
  }
}

export function isOutlineNode(node: OutlineNode | OutlineRootNode | null): node is OutlineNode {
  return (node as OutlineNode).key !== "root";
}

/** Flatten the tree structure into an Array, excluding any root node */
function collectNodes(currentNode: OutlineNode | OutlineRootNode, collectedNodes: OutlineNode[]) {
  if (isOutlineNode(currentNode)) {
    collectedNodes.push(currentNode as OutlineNode);
  }
  if (currentNode.children) {
    for (const child of currentNode.children) {
      collectNodes(child, collectedNodes);
    }
  }
}

function getNearestNode(children: OutlineNode[], path: number[], depth: number): OutlineNode | undefined {
  if (path.length === depth + 1) {
    //we must find result on this depth-level
    if (path[depth] <= children.length) {
      return children[path[depth] - 1];
    } else {
      return children[children.length - 1];
    }
  } else {
    //we might need to go deeper
    if (path[depth] <= children.length) {
      if (children[path[depth] - 1].children && children[path[depth] - 1].children!.length > 0) {
        //child branch we are looking for exists
        return getNearestNode(children[path[depth] - 1].children!, path, depth + 1);
      } else {
        //child branch we are looking for does NOT exist; it might have been recently removed...
        return children[children.length - 1];
      }
    }
  }
}

function indexOf(containerNode: OutlineNode | OutlineRootNode, pathDescriptor: string) {
  let index = 0;
  for (const child of containerNode.children || []) {
    if (child.pathDescriptor === pathDescriptor) {
      return index;
    }
    index++;
  }
  return -1;
}

function createStructuralNode(
  newNodeType: StructuralTypeName,
  indexBase1: number,
  parentPathDescriptor?: string,
  localizer?: LocalizationService
) {
  const newNode: OutlineNode = {
    key: uuidv4(),
    type: newNodeType,
    title: localizer?.resolve("Content.title.for" + newNodeType) || newNodeType,
    wordCount: 0,
    pathDescriptor: "",
    path: []
  };
  updatePathDescriptor(newNode, indexBase1, parentPathDescriptor);

  if (NODE_TYPES.get(newNodeType)?.isContainer) {
    newNode.children = [];
  }
  return newNode;
}

function updatePathDescriptor(node: OutlineNode, indexBase1: number, parentPathDescriptor?: string) {
  node.pathDescriptor = (parentPathDescriptor ? parentPathDescriptor + PATH_SEPARATOR : "") + String(indexBase1).padStart(4, "0");
  node.path = toPathElements(node.pathDescriptor);
}

/**
 * Assign new pathDescriptor and path values to each of the nodes in this container, starting at the given index, untill the end
 * @param {*} containerNode
 * @param {*} index
 * @param {*} mustIncrement flag that indicates if the
 */
function renumberNodes(containerNode: OutlineNode | OutlineRootNode, index: number, renumberedNodes: Map<string, string>) {
  if (!containerNode.children) {
    throw new Error("Abort: cannot renumber container nodes, because children property is missing");
  }
  for (let i = index; i < containerNode.children.length; i++) {
    const child = containerNode.children[i];
    const oldPathDescriptor = child.pathDescriptor;
    updatePathDescriptor(child, i + 1, containerNode.pathDescriptor);
    if (oldPathDescriptor !== child.pathDescriptor) {
      renumberedNodes.set(child.key, child.pathDescriptor);
    }
    if (child.children) {
      renumberNodes(child, 0, renumberedNodes); //call recursively on descendent container
    }
  }
}

/**
 * Add to every ContentMeta item in the array two additional properties: a path-property and an empty children array.
 * Also give it a key (uuid) if it not already has one.
 *
 * @param contentMetas
 */
function copyAndEnrichContentMeta(contentMetas: ContentMeta[]): OutlineNode[] {
  return deepClone(contentMetas).map(contentMeta => {
    const outlineNode = contentMeta as OutlineNode;
    outlineNode["path"] = toPathElements(outlineNode.pathDescriptor);
    if (!outlineNode.key) {
      outlineNode.key = uuidv4();
    }
    if (NODE_TYPES.get(outlineNode.type)?.isContainer) {
      outlineNode["children"] = [];
    }
    return outlineNode;
  });
}

export function sortByPathLength(outlineNodes: OutlineNode[]): void {
  // Array must be sorted ascending by the number of path segments, otherwise this algorithm won't work.
  // This basically means: ancesters first, descendents last,
  outlineNodes.sort((a, b) => {
    if (a.path.length > b.path.length) {
      return 1;
    } else if (a.path.length < b.path.length) {
      return -1;
    }

    // within sibblings, order by value of their path segment, so that content is in right order and the ordering of scenes and chapters are not mixed up
    const aOrder = a.path[a.path.length - 1];
    const bOrder = b.path[b.path.length - 1];
    if (aOrder > bOrder) {
      return 1;
    } else if (aOrder < bOrder) {
      return -1;
    }
    return 0; //this should not happen, but it is not this sorter's task to report it
  });
}

/**
 * From the collection of ContentMeta elements, find the parent node that contains the element with the given child path.
 *
 * @param {*} elements the array of elements to search through, it could be a collection of children.
 * @param {*} childPath The relative path into the collection of elements for which we are still looking for a match
 * @param {*} ancestorPathLevel the ancestorPathLevel of ancestery that we are looking for.
 */
function findParentNode(elements: OutlineNode[], childPath: number[], ancestorPathLevel = 0): OutlineNode | null {
  if (childPath.length === 1 && ancestorPathLevel === 0) {
    return null; //unfortunately, we cannot return the root node; return null instead
  }

  let ancestorNode: OutlineNode | null = null;
  for (let element of elements) {
    if (element.path[ancestorPathLevel] === childPath[0]) {
      ancestorNode = element;
      break;
    }
  }

  if (ancestorNode) {
    if (!ancestorNode.children) {
      throw new Error("Abort: cannot find parent node because ancestor has no children");
    }
    // Recursively call this method on the children of the parent
    if (childPath.length > 2) {
      //we need to go a level deeper and search the children of the encountered ancestorNode
      ancestorNode = findParentNode(ancestorNode.children, childPath.slice(1), ++ancestorPathLevel);
    }
  }
  return ancestorNode;
}

/**
 * Convert a string with numbers separated by underscores into an array where each number from the string is a number in the array (same order as in the string)
 * @param pathDescriptor a flattened description of the path from the root down, where each number is the index of a child branch to follow
 * @returns an array of numbers, representing the same information as the string, but in a different format
 */
export function toPathElements(pathDescriptor: string): number[] {
  if (!pathDescriptor || typeof pathDescriptor !== "string") {
    throw new Error("A string value was expected: " + pathDescriptor);
  }

  return pathDescriptor.split(PATH_SEPARATOR).map(segment => {
    if (!segment) {
      throw new Error("Path segment can not be empty");
    }
    if (!/^\d+$/.test(segment)) {
      throw new Error("Path segment must be a 'whole number': " + segment + " in " + pathDescriptor);
    }
    return Number(segment);
  });
}
