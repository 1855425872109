import React, { useContext, useEffect, useState } from "react";
import Page from "../general/Page";
import ActionBar from "../general/ActionBar";
import StateContext, { getStateContext } from "../../StateContext";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import outlineDao, { Coordinates, PlotBoard, PlotItem, PlotLine, SavePlotboardRequest } from "../../dao/OutlineDao";
import { Button, Space, Spin, Tooltip, message } from "antd";
import { useImmerReducer } from "use-immer";
import plotDesignerReducer from "./PlotDesignerReducer";
import { PlotCard } from "./PlotCard";
import { calculateGridCell, calculateGridHeight, calculateGridWidth, calculateYAxisPosition } from "./GridHelper";
import { PlotItemDetails } from "./PlotItemDetails";
import { ConsoleLogger, localizer } from "di-common";
import { SaveButton } from "../general/CommonButtons";
import { PlotLineHeading } from "./PlotLineHeading";
import { PlotLineDetails } from "./PlotLineDetails";
import AutosaveRegistry, { autosaveRegistryDispatcher, RegistryEntry } from "../../utils/AutosaveRegistry";
import { GlobalAction } from "../..";
import { useBeforeunload } from "react-beforeunload";
import AutosaveContext from "../../utils/AutosaveContext";
import UnsavedChangesModal from "../general/UnsavedChangesModal";
import { RollbackOutlined } from "@ant-design/icons";

const logger = new ConsoleLogger("PlotDesigner");

type PlotDesignerProps = {
  isDisabled?: boolean;
};

export type PlotDesignerState = {
  plotBoard: PlotBoard;
  plotLines: (PlotLine | null)[];
  plotItems: PlotItem[];
  selectedPlotItemId?: string;
  selectedPlotLineId?: string;
  isLeftAsideOpen: boolean;
  isDirty: boolean;
  pendingSaveRequest?: SavePlotboardRequest;
  saveCount: number;
  saveSource: "user" | "autosave" | "none";
};

const initialPlotState: PlotDesignerState = {
  plotBoard: {
    columnCount: 5,
    rowCount: 10
  },
  plotLines: [],
  plotItems: [],
  selectedPlotItemId: undefined,
  selectedPlotLineId: undefined,
  isLeftAsideOpen: false,
  isDirty: false,
  saveCount: 0,
  saveSource: "none"
};

export default function PlotDesigner({ isDisabled = false }: PlotDesignerProps) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  /* Control the open/closed state of the ActionBar */
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const {
    isBelowBreakpointSmallest,
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  const [registryEntries, registryEntriesDispatcher] = useImmerReducer<RegistryEntry[], GlobalAction>(
    autosaveRegistryDispatcher,
    []
  );

  function handleClose() {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    logger.info(`${dirtyEntries.length} entries are dirty`);
    if (dirtyEntries.length > 0) {
      if (userPreferences.isAutoSaveOn === true) {
        dirtyEntries.forEach(entry => {
          logger.info(`Saving changes in ${entry.name}`);
          entry.autosave();
        });
      } else {
        return dirtyEntries.map(entry => entry.name);
      }
    }
    return [];
  }

  useBeforeunload(() => {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    if (dirtyEntries.length > 0) {
      return "Do not close!";
    }
    return null;
  });
  const blocker = useBlocker(() => handleClose().length > 0);

  /**
   * Hold plot designer data as state
   */
  const [plotDesignerState, plotDesignerDispatch] = useImmerReducer(plotDesignerReducer, initialPlotState);

  useEffect(() => {
    const autosaveRegistry = new AutosaveRegistry(registryEntries, registryEntriesDispatcher);
    autosaveRegistry.addEntry({
      id: "PlotDesigner",
      name: localizer.resolve("PlotDesigner.name"),
      isDirty: () => plotDesignerState.isDirty,
      autosave: () => plotDesignerDispatch({ type: "autosave" })
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotDesignerState.isDirty]);

  // load the project's plot design from the database upon mounting of this panel
  useEffect(() => {
    if (projectId && currentUser) {
      setLoading(true);
      outlineDao.loadPlotboard(projectId, currentUser).then(
        (response: any) => {
          setLoading(false);
          plotDesignerDispatch({
            type: "initialize",
            data: response.data
          });
        },
        (error: any) => {
          setLoading(false);
          console.dir(error);
          if (error.response && error.response.status === 404) {
            plotDesignerDispatch({
              type: "newPlotBoard"
            });
          } else {
            message.error(localizer.resolve("Global.message.loadError"));
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (plotDesignerState.saveCount > 0 && plotDesignerState.pendingSaveRequest && currentUser && projectId) {
      outlineDao.savePlotboard(
        projectId,
        currentUser,
        plotDesignerState.pendingSaveRequest,
        (response: any) => {
          plotDesignerDispatch({ type: "saveSuccesfull", data: response.data });
        },
        (error: any) => {
          plotDesignerDispatch({ type: "saveFailed", data: error });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotDesignerState.saveCount, plotDesignerState.pendingSaveRequest]);

  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        plotDesignerDispatch({ type: "autosave" });
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      console.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      console.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  if (isLoading) {
    return <Spin />;
  }

  function isPlotItem(transferData: string): boolean {
    if (transferData) {
      for (const plotItem of plotDesignerState.plotItems) {
        if (plotItem.id === transferData) {
          return true;
        }
      }
    }
    return false;
  }

  function isPlotLine(transferData: string): boolean {
    if (transferData) {
      for (const plotLine of plotDesignerState.plotLines) {
        if (plotLine && plotLine.id === transferData) {
          return true;
        }
      }
    }
    return false;
  }

  function getSelectedPlotItem(selectedItemId?: string): PlotItem | undefined {
    if (!selectedItemId) {
      return undefined;
    }
    for (const plotItem of plotDesignerState.plotItems) {
      if (plotItem.id === selectedItemId) {
        return plotItem;
      }
    }
    throw new Error(`PlotItem with id ${selectedItemId} not found`);
  }

  function getSelectedPlotLine(selectedItemId?: string): PlotLine | undefined {
    if (!selectedItemId) {
      return undefined;
    }
    for (const plotLine of plotDesignerState.plotLines) {
      if (plotLine && plotLine.id === selectedItemId) {
        return plotLine;
      }
    }
    throw new Error(`PlotLine with id ${selectedItemId} not found`);
  }

  return (
    <AutosaveContext.Provider value={{ registryEntries, registryEntriesDispatcher }}>
      <Page>
        <div />
        <main className={["writertools", isDrawerOpen ? "writertools--drawer-open" : "writertools--drawer-closed"].join(" ")}>
          <div className="left-aside" style={{ position: "relative" }}>
            <PlotItemDetails
              plotItem={getSelectedPlotItem(plotDesignerState.selectedPlotItemId)}
              isDisabled={isDisabled}
              dispatcher={plotDesignerDispatch}
              isVisible={plotDesignerState.isLeftAsideOpen && plotDesignerState.selectedPlotItemId !== undefined}
              onClose={() => plotDesignerDispatch({ type: "closePlotDesignerDetails" })}
            />
            <PlotLineDetails
              plotLine={getSelectedPlotLine(plotDesignerState.selectedPlotLineId)}
              isDisabled={isDisabled}
              dispatcher={plotDesignerDispatch}
              isVisible={plotDesignerState.isLeftAsideOpen && plotDesignerState.selectedPlotLineId !== undefined}
              onClose={() => plotDesignerDispatch({ type: "closePlotDesignerDetails" })}
            />
          </div>
          <div className="content-area">
            <Space size="small" style={{ marginBottom: "0.5rem" }}>
              <SaveButton
                isDisabled={isDisabled}
                isDirty={plotDesignerState.isDirty}
                isSaving={plotDesignerState.saveSource !== "none"}
                save={() => {
                  plotDesignerDispatch({ type: "save" });
                }}
              />
              <Tooltip title={localizer.resolve("PlotDesigner.buttonCaption.closePlottingTool")}>
                <Button
                  type="default"
                  size="large"
                  shape="circle"
                  icon={<RollbackOutlined />}
                  htmlType="button"
                  // should we save when autosave is on?
                  onClick={() => {
                    if (handleClose().length > 0) {
                      logger.info("There are unsaved changes in the Story Elements");
                    }
                    navigate(-1);
                  }}
                />
              </Tooltip>
            </Space>
            <div className="plotboard">
              <div
                className="plotboard__axisTitles"
                onDrop={event => {
                  const plotLineId = event.dataTransfer.getData("application/uuid-plotline");
                  if (isPlotLine(plotLineId)) {
                    const insertLocation: Coordinates = calculateGridCell(event);
                    plotDesignerDispatch({
                      type: "movePlotLine",
                      data: { plotLineId, insertColumn: insertLocation.x, moveExistingRight: event.getModifierState("Control") }
                    });
                  }
                }}
                onDragOver={event => {
                  event.preventDefault();
                }}
                onDoubleClick={event => {
                  const insertLocation: Coordinates = calculateGridCell(event);
                  plotDesignerDispatch({
                    type: "addPlotLine",
                    data: { columnIndex: insertLocation.x, moveExistingRight: event.getModifierState("Control") }
                  });
                }}
                title={localizer.resolve("PlotDesigner.mouseover.plotlines")}
              >
                {
                  /* draw the vertical story axis, but not every column contains plot cards; empty columns have no axis drawn */
                  plotDesignerState.plotLines
                    .map((plotLine, index) => (plotLine === null || plotLine === undefined ? -1 : index))
                    .filter(index => index >= 0)
                    .map(index => {
                      return (
                        <PlotLineHeading
                          key={plotDesignerState.plotLines[index]!.id}
                          plotLine={plotDesignerState.plotLines[index]!}
                          dispatcher={plotDesignerDispatch}
                          isDisabled={isDisabled}
                        />
                      );
                    })
                }
              </div>
              <div
                className="plotboard__canvas"
                style={{
                  width: calculateGridWidth(plotDesignerState.plotBoard.columnCount),
                  height: calculateGridHeight(plotDesignerState.plotBoard.rowCount)
                }}
                onDrop={event => {
                  const itemId = event.dataTransfer.getData("application/uuid-plotcard");
                  if (isPlotItem(itemId)) {
                    const insertLocation: Coordinates = calculateGridCell(event);
                    plotDesignerDispatch({
                      type: "movePlotItem",
                      data: { itemId, insertLocation, moveExistingDown: event.getModifierState("Control") }
                    });
                  }
                }}
                onDragOver={event => {
                  event.preventDefault();
                }}
                onDoubleClick={event => {
                  const insertLocation: Coordinates = calculateGridCell(event);
                  plotDesignerDispatch({
                    type: "addPlotItem",
                    data: { insertLocation, moveExistingDown: event.getModifierState("Control") }
                  });
                }}
                title={localizer.resolve("PlotDesigner.mouseover.plotcards")}
              >
                {plotDesignerState.plotItems.map((item: PlotItem) => {
                  return <PlotCard key={item.id} plotItem={item} dispatcher={plotDesignerDispatch} isDisabled={isDisabled} />;
                })}
                {
                  /* draw the vertical story axis, but not every column contains plot cards; empty columns have no axis drawn */
                  plotDesignerState.plotLines
                    .map((plotLine, index) => (plotLine === null || plotLine === undefined ? -1 : index))
                    .filter(index => index >= 0)
                    .map(index => {
                      const leftOffset = calculateYAxisPosition(index);
                      return (
                        <div
                          key={index}
                          className="plotboard__yAxis"
                          style={{ left: leftOffset + "px", height: calculateGridHeight(plotDesignerState.plotBoard.rowCount) }}
                          title={plotDesignerState.plotLines[index]!.title}
                          onDoubleClick={event => {
                            event.stopPropagation();
                            return false;
                          }}
                        />
                      );
                    })
                }
              </div>
            </div>
          </div>
          <ActionBar
            storylineOn={true}
            isDisabled={isDisabled}
            setDrawerOpenCallback={setDrawerOpen}
            initialyOpenedSection={isBelowBreakpointSmallest === true ? null : "Storyline"}
          />
        </main>
        {blocker.state === "blocked" && (
          <UnsavedChangesModal
            onOk={() => {
              blocker.proceed();
            }}
            onCancel={() => {
              registryEntriesDispatcher({ type: "clear" });
              blocker.reset();
            }}
            registryEntries={registryEntries}
          />
        )}
      </Page>
    </AutosaveContext.Provider>
  );
}
