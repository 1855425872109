import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext, { getStateContext } from "../../StateContext";
import NewProjectCard from "./NewProjectCard";
import { Card, Spin } from "antd";
import { localizer } from "di-common";
import { useImmerReducer } from "use-immer";
import projectDao, { ProjectMeta } from "../../dao/ProjectDao";
import ProjectCard from "./ProjectCard";
import { GlobalAction } from "../..";
import { ConsoleLogger } from "di-common";

type DashboardViewState = {
  isLoading: boolean;
  projects: ProjectMeta[];
};

function initialState(): DashboardViewState {
  return {
    isLoading: true,
    projects: []
  };
}
function DashboardView({ isDisabled = false }) {
  const logger = new ConsoleLogger("DashboardView");
  const navigate = useNavigate();
  const { Meta } = Card;

  function localReducer(draft: DashboardViewState, action: GlobalAction): void {
    logger.debug("localReducer: processing action :", action.type);
    switch (action.type) {
      case "projectsLoaded":
        draft.projects = action.data;
        draft.isLoading = false;
        break;
      case "loadingFailed":
        logger.info("There was a problem loading the projects: " + action.data);
        draft.isLoading = false;
        break;
      case "removeProjectSuccess":
        draft.projects = action.data; //we receive back current list of projects
        break;
      default:
        logger.info(`DashboardView: Unexpected action type: ${action.type}`);
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(localReducer, initialState());

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  useEffect(() => {
    //Load the list of current projects for display
    if (currentUser) {
      projectDao.loadProjects(
        currentUser,
        (response: any) => dispatch({ type: "projectsLoaded", data: response.data }),
        (error: any) => dispatch({ type: "loadingFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <div className="actions" />
      <main className="dashboardview">
        {/* A card to open the Ideabook */}
        <Card className="projectcard" onClick={() => navigate("/ideas")} hoverable cover={<img alt="" src="images/idea.svg" />}>
          <Meta
            title={localizer.resolve("Dashboard.ideabookTitle")}
            description={localizer.resolve("Dashboard.ideabookLongDescription")}
          />
        </Card>
        {/* A card to go to fragments */}
        <Card
          className="projectcard"
          onClick={() => navigate("/fragments")}
          hoverable
          cover={<img alt="" src="images/keyboard.svg" />}
        >
          <Meta
            title={localizer.resolve("Dashboard.fragmentsTitle")}
            description={localizer.resolve("Dashboard.fragmentsLongDescription")}
          />
        </Card>
        {/* A card to create a new Project */}
        <NewProjectCard isDisabled={isDisabled} />

        {state.projects.map((project: ProjectMeta) => {
          // A card for each project
          return <ProjectCard key={project.id} project={project} dispatcher={dispatch} />;
        })}
      </main>
    </>
  );
}

export default DashboardView;
