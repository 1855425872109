import React from "react";
import { Select, Form } from "antd";
import { localizer } from "di-common";

function ProjectLanguageFormItem() {
  const { Option } = Select;
  const languageDescriptions = localizer.getLanguageDescriptions();
  return (
    <Form.Item
      label={localizer.resolve("ProjectMeta.language.label")}
      name="language"
      rules={[
        {
          required: true,
          message: localizer.resolve("ProjectMeta.language.message.mandatory")
        }
      ]}
    >
      <Select
        showSearch
        optionFilterProp="label"
        // defaultValue={localizer.getCurrentLocale()}
      >
        {[...languageDescriptions].map(([code, description]) => {
          return (
            <Option key={code} value={code} label={description}>
              {description}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default ProjectLanguageFormItem;
