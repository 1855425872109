import React, { useState, useEffect, useContext } from "react";
import { FormInstance, Spin, Typography } from "antd";
import ProtectedImage from "../general/ProtectedImage";
import { concatIdentity, formatNickNamesForDisplay, displayField } from "../general/FieldFormatterUtil";
import StateContext, { getStateContext } from "../../StateContext";
import EntityStateContext, { getEntityStateContext } from "./EntityStateContext";
import { Character, Entity, getDao } from "../../dao/EntityDao";
import EntityForm from "./EntityForm";
import { CHARACTER, SINGLE_VIEW, ViewTypes, displayFieldsByEntityType } from "./EntityConstants";
import Paragraph from "antd/lib/typography/Paragraph";

const { Title } = Typography;

type EntityDetailsProps = {
  isDisabled?: boolean;
  entityId?: string;
  form: FormInstance;
  mode: ViewTypes;
  setMode: React.Dispatch<React.SetStateAction<ViewTypes>>;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  entityRef: React.MutableRefObject<Entity | undefined>;
};

function EntityDetails({ isDisabled = false, form, entityId, mode, setMode, setSaving, setDirty, entityRef }: EntityDetailsProps) {
  const sharedEntityState = getEntityStateContext(useContext(EntityStateContext));
  const { entityType } = sharedEntityState;
  const entityDao = getDao(entityType);
  const [isLoading, setLoading] = useState(false);
  const [entity, setEntity] = useState<Entity | undefined>();
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  useEffect(() => {
    if (entityId && currentUser) {
      setLoading(true);
      entityDao.loadEntity(
        entityId,
        currentUser,
        (response: any) => {
          setLoading(false);
          setEntity(response.data);
          entityRef.current = response.data;
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  if (isLoading) {
    return <Spin />;
  }

  return mode === SINGLE_VIEW ? (
    <Typography>
      {entity?.avatarUrl && <ProtectedImage className="entity__image" src={entity.avatarUrl} />}
      {entityType === CHARACTER ? (
        <Title level={4}>{entity && concatIdentity(entity)}</Title>
      ) : (
        <Title level={4}>{entity?.name}</Title>
      )}
      {entityType === CHARACTER && entity && (entity as Character).nickNames && (
        <Paragraph>{formatNickNamesForDisplay(entity as Character)}</Paragraph>
      )}
      {displayFieldsByEntityType.get(entityType)?.map(fieldName => displayField(entityType, fieldName, entity))}
    </Typography>
  ) : (
    <EntityForm form={form} entity={entity} setEntity={setEntity} setMode={setMode} setSaving={setSaving} setDirty={setDirty} />
  );
}

export default EntityDetails;
