import rfdc from "rfdc";

const deepClone = rfdc();

export function isNotEqual(value1: unknown, value2: unknown): boolean {
  if (typeof value1 === "undefined") {
    value1 = null;
  }
  if (typeof value2 === "undefined") {
    value2 = null;
  }
  return value1 !== value2;
}

/**
 * Return the input value when it is not null or undefined
 *
 * @param value
 * @returns The input value which is certain to be not null and not undefined
 * @throws An Error when the value is null or undefined
 */
export function getOrFail<T>(value?: T): T {
  if (value === null || value === undefined) {
    throw new Error("GetOrFail: value is null or undefined");
  }
  return value;
}

/* check if there is any (ant design) form field that has a validation error */
export function hasFormErrors(changedFields: any[], allFields: any[]) {
  for (let field of allFields) {
    if (field.errors && field.errors.length > 0) {
      return true;
    }
  }
  return false;
}

export function copyObject<T>(objectToCopy: T): T {
  //This wrapper function is here, inorder to make it easier when we want to change it's implementation in the future
  //(And maybe it is more memory performant, because the deepClone instance is created only once)
  if (!objectToCopy) {
    throw new Error("Object to copy can not be null");
  }
  return deepClone(objectToCopy);
}
