import React from "react";
import { Form, Input } from "antd";
import { localizer } from "di-common";

function NewPasswordInputs() {
  return (
    <>
      <Form.Item
        label={localizer.resolve("Password.chosenPassword")}
        name="chosenPassword"
        rules={[
          {
            required: true,
            message: localizer.resolve("Password.chosenRequiredMessage")
          }
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label={localizer.resolve("Password.retypedPassword")}
        name="retypedPassword"
        dependencies={["chosenPassword"]}
        rules={[
          {
            required: true,
            message: localizer.resolve("Password.chosenRequiredMessage")
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("chosenPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                localizer.resolve("Password.noMatchMessage")
              );
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
}

export default NewPasswordInputs;
