/**
 * This component represents an image whose source url is protected by the server with Basic Authentication. The contents will be retrieved
 * dynamicly using Basic Authentication, displaying status icons (loading, failed, unauthorized etc.)
 */
import React, { useContext, useEffect, useState } from "react";
import { Spin, Avatar, AvatarProps } from "antd";
import StateContext, { getStateContext } from "../../StateContext";
import resourceDao from "../../dao/ResourceDao";

function ProtectedAvatar(
  props: AvatarProps & React.RefAttributes<HTMLSpanElement>
) {
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  const [source, setSource] = useState<React.ReactNode | undefined>(undefined);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (
      props.src &&
      typeof props.src === "string" &&
      props.src.startsWith("/api/private/") &&
      currentUser
    ) {
      setLoading(true);
      resourceDao.getResource(
        currentUser,
        props.src,
        (response: any) => {
          setLoading(false);
          const binary = new Blob([response.data]);
          const url = URL.createObjectURL(binary);
          setSource(url);
        },
        console.dir
      );
    } else {
      setSource(props.src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spin size="small" />;
  }

  return <Avatar {...props} src={source} />;
}

export default ProtectedAvatar;
