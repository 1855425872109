import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Typography, Checkbox, Button, Row, message } from "antd";
import Feedback from "../general/Feedback";
import { ConsoleLogger, localizer } from "di-common";
import NewPasswordInputs from "./NewPasswordInputs";
import VatRegimeSelector from "./VatRegimeSelector";
import { contentboxFormLayout, contentboxTailLayout } from "../LayoutConstants";
import { v4 as uuidv4 } from "uuid";
import { calculatePasswordHash } from "di-common";
import userDao from "../../dao/UserDao";
import { DefaultOptionType } from "antd/lib/cascader";

const logger = new ConsoleLogger("CreateAccountForm");

function CreateAccountForm() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isProcessing, setProcessing] = useState(false);

  /**Feedback is the response from the server. They (can) contain further instructions */
  const [feedback, setFeedback] = useState<string | undefined>();

  function submitForm(values: any) {
    setProcessing(true);
    const userId = uuidv4();
    const email = values["email"].toLowerCase();
    const passwordHash = calculatePasswordHash(email, values["chosenPassword"]);
    const passwordChangeCode = calculatePasswordHash(userId, values["chosenPassword"]);

    const createAccountRequest = {
      userId,
      email,
      displayName: values["displayName"],
      iso3166_1_countryCode: values["vatRegime"][0],
      stateName: values["vatRegime"][1] || null,
      passwordHash,
      passwordChangeCode,
      language: localizer.getCurrentLocale()
    };
    logger.info(createAccountRequest);
    userDao.createAccount(
      createAccountRequest,
      (response: any) => handleResponse(response),
      (error: any) => handleError(error)
    );
  }

  function handleResponse(response: any): void {
    setProcessing(false);
    switch (response.data) {
      case "OK":
        setFeedback(localizer.resolve("Register.feedback.registered"));
        break;
      case "WAITS_ACTIVATION":
        setFeedback(localizer.resolve("Register.feedback.accountExistsAndWaitsActivation"));
        break;
      case "ACTIVE":
        setFeedback(localizer.resolve("Register.feedback.accountAlreadyExists"));
        break;
      default:
        setFeedback(localizer.resolve("Register.feedback.unknownProblem"));
        break;
    }
  }

  function handleError(error: any): void {
    setProcessing(false);
    if (error.response.status === 400) {
      console.error(localizer.resolve(`${error.response.data.message}`));
    }
    message.error(localizer.resolve("Register.feedback.unknownProblem"));
  }

  if (feedback) {
    return <Feedback feedback={feedback} allowQuestions={false} />;
  }

  const { Paragraph, Title } = Typography;
  return (
    <div className="content-box">
      <header className="header">
        <div className="branding">
          <img className="branding__name" src="logo-transparant.png" alt="digital-ink logo" />
        </div>
      </header>
      <div className="content-box__content">
        <Form
          {...contentboxFormLayout}
          layout="horizontal"
          form={form}
          initialValues={{ chosenpassword: null, retypedPassword: null }}
          onFinish={submitForm}
        >
          <Form.Item {...contentboxTailLayout}>
            <Row align="middle">
              <Title style={{ margin: "auto" }}>{localizer.resolve("Register.title")}</Title>
              <Paragraph>
                <p>{localizer.resolve("Register.intro")}</p>
              </Paragraph>
            </Row>
          </Form.Item>
          <Form.Item
            label={localizer.resolve("Register.email.label")}
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!"
              },
              {
                required: true,
                message: localizer.resolve("Register.email.requiredMessage")
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={localizer.resolve("Register.displayName.label")}
            name="displayName"
            extra={localizer.resolve("Register.displayName.help")}
            rules={[
              {
                max: 16,
                message: localizer.resolve("Register.displayName.maxLengthMessage")
              },
              {
                required: true,
                message: localizer.resolve("Register.displayName.requiredMessage")
              }
            ]}
          >
            <Input maxLength={17} />
          </Form.Item>
          <Form.Item
            label={localizer.resolve("Register.vatRegime.label")}
            name="vatRegime"
            extra={localizer.resolve("Register.vatRegime.help")}
            rules={[
              {
                required: true,
                message: localizer.resolve("Register.vatRegime.requiredMessage")
              }
            ]}
          >
            <VatRegimeSelector
              onChange={(newVatRegime: (string | number)[], selectOptions: DefaultOptionType[]) =>
                form.setFieldsValue({ vatRegime: newVatRegime })
              }
            />
          </Form.Item>
          <NewPasswordInputs />
          <Form.Item {...contentboxTailLayout}>
            <Paragraph type="secondary">{localizer.resolve("ChangePassword.tip")}</Paragraph>
          </Form.Item>
          <Form.Item
            {...contentboxTailLayout}
            name="agreeServices"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(localizer.resolve("Register.agreeServices.requiredMessage"))
              }
            ]}
          >
            <Checkbox>
              <span
                dangerouslySetInnerHTML={{
                  __html: localizer.resolve("Register.agreeServices.label")
                }}
              />
            </Checkbox>
          </Form.Item>
          <Form.Item {...contentboxTailLayout}>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              {localizer.resolve("Register.buttonCaption")}
            </Button>{" "}
            <Button onClick={() => navigate("/")}>{localizer.resolve("Global.buttonCaption.cancel")}</Button>{" "}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default CreateAccountForm;
