import React, { useEffect, useState, useContext } from "react";
import { localizer } from "di-common";
import { Popconfirm, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import StateContext, { getStateContext } from "../../StateContext";
import Axios from "axios";

type DeleteResourceActionProps = {
  /** the identifier of the resource to delete */
  resourceId: string;
  /** the title of the confirmation dialog */
  title: string;
  /** the name of the resource to use in the url to the server */
  urlResourceName: string;
  /** dispatch function to use as callback when the deletion succeeded */
  dispatcher: any;
  /** the name of the action to execute by the dispatcher (when the deletion succeeded) */
  actionType: string;
};

/* I don't like the large number of parameters I have to pass on via props
 * to make it work
 */
function DeleteResourceAction(props: DeleteResourceActionProps) {
  //Passed on via props:
  // 1. resourceId = e.g. project.id
  // 2. title confirmation dialog = e.g. localizer.resolve("Idea.deleteConfirmation")
  // 3. urlResourceName = projects
  // 4. callbackFunction, e.g. dispatch({ type: "removeIdeaSuccess", data: response.data })
  // 5. actionType = typename of dispatch action to execute with the server response.
  // All 5 props must be supplied

  const [resourceToRemove, setResourceToRemove] = useState<string>();

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  useEffect(() => {
    //Remove the resource and display the server response: an updated list of ideas of the current user
    if (resourceToRemove && currentUser) {
      Axios.delete(
        `/api/private/${props.urlResourceName}/${currentUser.id}/${resourceToRemove}`,
        {
          auth: {
            username: currentUser.username,
            password: currentUser.passwordHash
          }
        }
      ).then(
        response =>
          props.dispatcher({ type: props.actionType, data: response.data }),
        error => console.error(error)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceToRemove]);

  return (
    <Popconfirm
      title={props.title}
      onConfirm={e => {
        e?.stopPropagation();
        setResourceToRemove(props.resourceId);
      }}
      onCancel={e => e?.stopPropagation()}
      okText={localizer.resolve("Global.buttonCaption.confirmDeletion")}
      cancelText={localizer.resolve("Global.buttonCaption.no")}
    >
      <Tooltip title={localizer.resolve("Global.tooltip.delete")}>
        <DeleteOutlined
          onClick={e => {
            e.stopPropagation();
          }}
          key="delete"
        />
      </Tooltip>
    </Popconfirm>
  );
}

export default DeleteResourceAction;
