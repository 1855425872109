import { ConsoleLogger } from "di-common";
import { GlobalAction } from "..";

const logger = new ConsoleLogger("DocXExportService");

/**
 * Function to intercept responses from Axios
 */
function errorInterceptor(globalDispatch: React.Dispatch<GlobalAction>, error: any) {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        //401: Unauthorized - do a logout if logged in
        globalDispatch({
          type: "serverLogout",
          messageKey: error.response.data.message
        });
        break;
      case 402:
        //402: payment required -- Display localized server message key + stop prolongating changes (refresh page?)
        globalDispatch({
          type: "feedback",
          messageKey: error.response.data.message
        });
        break;
      case 403:
        //403: forbidden: user is not granted access to the requeste resource by it's owner
        globalDispatch({
          type: "feedback",
          messageKey: error.response.data.message
        });
        break;
      default:
        logger.info("No specific handling implemented for http status " + error.response.status);
        return Promise.reject(error);
    }
  } else if (error.request) {
    //no response from server
    //host unreachable: set offline
  } else {
    //the request could not be produced
  }

  return error;
}

export default errorInterceptor;
