import React, { useState, useContext, useEffect } from "react";
import { Drawer, Form, Input, Space, Spin, Tooltip, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import rfdc from "rfdc";
import outlineDao, { Outline } from "../../dao/OutlineDao";
import { ConsoleLogger, localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import { CloseButton, SaveButton } from "../general/CommonButtons";
import PlotDesignerButton from "./PlotDesignerButton";
import { isNotEqual } from "../../utils/MiscellaneousUtil";
import AutosaveContext, { getAutosaveContext } from "../../utils/AutosaveContext";
import { AxiosResponse } from "axios";

const deepClone = rfdc();

const logger = new ConsoleLogger("StorylinePane");

type StorylinePaneProps = {
  isDisabled?: boolean;
  onClose: () => void;
  isVisible: boolean;
};

/** The StorylinePane only has edit mode, so we only show form */
function StorylinePane({ isDisabled = false, onClose, isVisible }: StorylinePaneProps) {
  const { projectId } = useParams();
  const [form] = Form.useForm();

  const [outline, setOutline] = useState<Outline | Partial<Outline>>({});
  const [isLoading, setLoading] = useState(true);

  //Indicate if the current save operation is triggered by the user or by the autosave mechanism
  const [isDirty, setDirty] = useState(false);
  const [saveSource, setSaveSource] = useState<"none" | "user" | "autosave">("none");
  const [saveState, setSaveState] = useState<"saving" | "success" | null>(null);
  const [saveCount, setSaveCount] = useState(0);

  const {
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  function isDirtyCheck() {
    return (
      isNotEqual(outline?.ideaSummary, form.getFieldValue("ideaSummary")) ||
      isNotEqual(outline?.plot, form.getFieldValue("plot")) ||
      isNotEqual(outline?.synopsis, form.getFieldValue("synopsis")) ||
      isNotEqual(outline?.notes, form.getFieldValue("notes"))
    );
  }

  function autosave() {
    if (isDirtyCheck()) {
      setSaveSource("autosave");
      logger.info("Autosave: submit form");
      form.submit();
    } else {
      logger.info("Autosave: no unsaved outline changed");
    }
  }

  const autosaveRegistry = getAutosaveContext(useContext(AutosaveContext));
  useEffect(() => {
    const entry = {
      id: "PlotPane",
      name: localizer.resolve("UnsavedChangesModal.plotEditor"),
      isDirty: () => isDirty,
      autosave
    };
    autosaveRegistry.addEntry(entry);
    return () => {
      autosaveRegistry.removeEntry(entry);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        autosave();
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      console.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      console.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  useEffect(() => {
    //give user feedback about result of manual save operation
    if (saveState !== "saving" && saveSource === "user") {
      if (saveState === "success") {
        message.success(localizer.resolve("Global.feedback.saveSuccess"));
      } else {
        message.error(localizer.resolve("Global.feedback.saveError"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCount, saveState]);

  //load outline -- create an empty object if one does not exist yet
  useEffect(() => {
    if (projectId && currentUser) {
      setLoading(true);
      outlineDao.loadOutline(projectId, currentUser).then(
        (response: AxiosResponse<Outline, any>) => {
          setLoading(false);
          if (typeof response.data === "object") {
            setOutline(response.data);
          } else {
            setOutline({});
          }
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  function sendForm(values: any): void {
    if (projectId && currentUser) {
      setSaveCount(saveCount + 1);
      setSaveState("saving");
      const outlineToSave = { ...deepClone(outline), ...values };
      logger.info("Sending outline to server");
      outlineDao.insertOrUpdateOutline(
        projectId,
        outlineToSave,
        currentUser,
        (response: any) => {
          setSaveState("success");
          setOutline(outlineToSave);
          setDirty(false);
        },
        (error: any) => {
          setSaveState(null);
          console.error(error);
        }
      );
    }
  }

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Drawer
      style={{ position: "absolute" }}
      title={localizer.resolve("Outline.title")}
      extra={
        <Space size="small">
          <SaveButton
            isDisabled={isDisabled}
            isDirty={isDirty}
            isSaving={saveState === "saving"}
            save={() => {
              setSaveSource("user");
              form.submit();
            }}
          />
          <CloseButton
            isDirty={isDirty}
            close={() => {
              form.resetFields();
              onClose();
            }}
          />
          {projectId && <PlotDesignerButton projectId={projectId} />}
        </Space>
      }
      placement="right"
      getContainer={() => document.getElementById("drawerContainer") as HTMLElement}
      closable={true}
      onClose={onClose}
      open={isVisible}
      mask={false}
      width="100%"
      closeIcon={
        <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
          <CloseOutlined />
        </Tooltip>
      }
    >
      <Form
        className="aside__scrollable"
        form={form}
        initialValues={outline}
        layout="vertical"
        onFinish={values => sendForm(values)}
        onFieldsChange={() => setDirty(isDirtyCheck())}
        preserve
        size="small"
      >
        <Form.Item
          name="ideaSummary"
          label={localizer.resolve("Outline.ideaSummary.label")}
          extra={localizer.resolve("Outline.ideaSummary.help")}
          rules={[
            {
              // The database can handle 65,535 chars, but we limit this to an easy pronouncable number
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={12} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label={localizer.resolve("Outline.plot.label")}
          name="plot"
          extra={localizer.resolve("Outline.plot.help")}
          rules={[
            {
              /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
              max: 65000,
              message: localizer.resolve("Outline.plot.message.maxLength")
            }
          ]}
        >
          <Input.TextArea rows={10} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label={localizer.resolve("Outline.synopsis.label")}
          name="synopsis"
          extra={localizer.resolve("Outline.synopsis.help")}
          rules={[
            {
              /* The database can handle 16,777,215 chars, but we limit this to an easy pronouncable number */
              max: 10000000,
              message: localizer.resolve("Outline.synopsis.message.maxLength")
            }
          ]}
        >
          <Input.TextArea rows={10} maxLength={10000001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label={localizer.resolve("Outline.notes.label")}
          name="notes"
          rules={[
            {
              /* The database can handle 16,777,215 chars, but we limit this to an easy pronouncable number */
              max: 10000000,
              message: localizer.resolve("Outline.notes.message.maxLength")
            }
          ]}
        >
          <Input.TextArea rows={10} maxLength={10000001} disabled={isDisabled} />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default StorylinePane;
