import Axios, { AxiosResponse } from "axios";
import { User } from "../StateContext";

export type Outline = {
  projectId: string | undefined;
  ideaSummary: string;
  plot: string;
  synopsis: string;
  notes: string;
};

/**
 * Coordinates into a two-dimensional grid
 */
export type Coordinates = {
  x: number;
  y: number;
};

export type SavePlotboardRequest = {
  plotBoard: PlotBoard;
  deletePlotlines: string[];
  insertPlotlines: PlotLine[];
  updatePlotlines: PlotLine[];
  deletePlotcards: string[];
  insertPlotcards: PlotItem[];
  updatePlotcards: PlotItem[];
};

type PlotboardResponse = {
  plotBoard: PlotBoard;
  plotLines: PlotLine[];
  plotCards: PlotItem[];
};

/**
 * The datatype for a single plot item (a.k.a. PlotCard). Each plot item has database identity and can be saved individually.
 * There shall be a mechanism to detect if it contains changes compared to the persisted version.
 */
export type PlotItem = {
  id: string;
  recap: string;
  //location is where the plot card is positioned on a grid
  location: Coordinates;
  action?: string;
  motivation?: string;
  notes?: string;
};

/**
 * The datatype for a single plot line. Each plotline has database identity and can be saved individually.
 * There shall be a mechanism to detect if it contains changes compared to the persisted version.
 */
export type PlotLine = {
  id: string;
  columnIndex: number;
  title: string;
  background?: string;
  summary?: string;
  notes?: string;
};

export type PlotBoard = {
  id?: string;
  columnCount: number;
  rowCount: number;
};

type ResponseCallback<T> = (response: AxiosResponse<T>) => void;

//Outline is a Storyline
class OutlineDao {
  loadOutline(projectId: string, currentUser: User) {
    return Axios.get<Outline>(`/api/private/outline/${currentUser.id}/${projectId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    });
  }

  insertOrUpdateOutline(
    projectId: string,
    outline: Outline,
    currentUser: User,
    responseCallback: ResponseCallback<Outline>,
    errorCallback: any
  ) {
    let operationType: string;
    if (typeof outline.projectId === "string") {
      operationType = "put"; //do an update
    } else {
      operationType = "post"; //do an insert
      outline.projectId = projectId;
    }
    Axios<Outline>({
      method: operationType,
      url: `/api/private/outline/${currentUser.id}`,
      data: outline,
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  loadPlotboard(projectId: string, currentUser: User) {
    return Axios.get<PlotboardResponse>(`/api/private/plotboard/${currentUser.id}/${projectId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    });
  }

  savePlotboard(
    projectId: string,
    currentUser: User,
    plotboard: SavePlotboardRequest,
    responseCallback: ResponseCallback<PlotboardResponse>,
    errorCallback: any
  ) {
    Axios.put<PlotboardResponse>(`/api/private/plotboard/${currentUser.id}/${projectId}`, plotboard, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }
}

const outlineDao = new OutlineDao();
export default outlineDao;
