import React from "react";
import { PlotLine } from "../../dao/OutlineDao";
import { calculateTitlePosition } from "./GridHelper";
import { GlobalAction } from "../..";
import { Popconfirm, Space, Tooltip } from "antd";
import { localizer } from "di-common";
import { DeleteOutlined } from "@ant-design/icons";

type PlotLineHeadingProps = {
  isDisabled?: boolean;
  plotLine: PlotLine;
  dispatcher: React.Dispatch<GlobalAction>;
};

export function PlotLineHeading({ isDisabled = false, plotLine, dispatcher }: PlotLineHeadingProps) {
  const xPosition = calculateTitlePosition(plotLine.columnIndex);
  const positionStyle = {
    left: xPosition + "px"
  };

  return (
    <div
      className="plotline-heading"
      style={positionStyle}
      draggable="true"
      onDragStart={event => {
        event.dataTransfer.setData("application/uuid-plotline", plotLine.id);
      }}
      onClick={event => {
        dispatcher({ type: "selectPlotLine", data: plotLine.id });
      }}
      onDoubleClick={event => {
        event.stopPropagation();
        return false;
      }}
      title=""
    >
      <div className="plotline-heading__draghandle">{localizer.resolve("PlotLine.caption")}</div>
      <div className="plotline-heading__title">{plotLine.title}</div>
      <Space className="plotline-heading__actions" direction="horizontal" align="end">
        <Popconfirm
          disabled={isDisabled}
          title={localizer.resolve("PlotLine.deleteConfirmation")}
          onConfirm={e => {
            e?.stopPropagation();
            dispatcher({ type: "removePlotLine", data: plotLine.id });
          }}
          onCancel={e => e?.stopPropagation()}
          okText={localizer.resolve("Global.buttonCaption.confirmDeletion")}
          cancelText={localizer.resolve("Global.buttonCaption.no")}
        >
          <Tooltip title={localizer.resolve("Global.tooltip.delete")}>
            <DeleteOutlined
              disabled={isDisabled}
              onClick={e => {
                e.stopPropagation();
              }}
              key="delete"
            />
          </Tooltip>
        </Popconfirm>
      </Space>
    </div>
  );
}
