import React, { useEffect } from "react";
import { getBrowserLocale } from "di-common";

type PageProps = {
  lang?: string;
  children: any;
};

function Page(props: PageProps) {
  useEffect(() => {
    document.documentElement.lang = props.lang || getBrowserLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{props.children}</>;
}

export default Page;
