import Axios from "axios";
import { User } from "../StateContext";
import { ProjectTypeName } from "../utils/OutlineManager";

/* The bare minimum properties defined for ProjectMeta. Will be extended before we are finished with additional properties */
export type ProjectMeta = {
  id: string;
  workTitle: string;
  coverUrl?: string;
  projectType: ProjectTypeName;
  language?: string;
};

export type StructuralTypeName =
  | "CHAPTER"
  | "SCENE"
  | "POEM"
  | "ACT"
  | "SECTION"
  | "TEXT";

export type ContentMeta = {
  key: string; //serverside: this is the uuid
  pathDescriptor: string;
  type: StructuralTypeName;
  title: string;
  wordCount: number;
};

export type Content = {
  notes?: string;
  content: string;
  contentMeta: ContentMeta;
};

class ProjectDao {
  loadProjects(currentUser: User, responseCallback: any, errorCallback: any) {
    Axios.get("/api/private/projects/" + currentUser.id, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  loadInitialData(
    projectId: string,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get(`/api/private/projects/${currentUser.id}/${projectId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(response => responseCallback(response), errorCallback);
  }

  updateProjectMeta(
    projectMeta: ProjectMeta,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.put(
      `/api/private/projects/${currentUser.id}/${projectMeta.id}`,
      projectMeta,
      {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }
    ).then(response => responseCallback(response), errorCallback);
  }

  promoteIdea(
    ideaToPromote: ProjectMeta,
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.post(`/api/private/ideas/promote/${currentUser.id}`, ideaToPromote, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }
}

const projectDao = new ProjectDao();
export default projectDao;
