import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { localizer } from "di-common";
import DispatchContext, { getDispatchContext } from "../../DispatchContext";
import StateContext, { getStateContext } from "../../StateContext";
import { Form, Button, Input, message } from "antd";
import { calculatePasswordHash } from "di-common";
import { AppInfo } from "../../Constants";

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 8
  }
};
const tailLayout = {
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      offset: 8,
      span: 16
    }
  }
};

export type LoginProps = { exitMessage?: string };

function Login({ exitMessage }: LoginProps) {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { dispatch: globalDispatch } = getDispatchContext(useContext(DispatchContext));

  const { state: globalState } = getStateContext(useContext(StateContext));
  const navigate = useNavigate();

  useEffect(() => {
    if (exitMessage) {
      message.info(exitMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Axios.get("/api/public/authrequired").then(
      response => {
        globalDispatch({
          type: "needsAuthentication",
          data: Boolean(response.data)
        });
      },
      error => {
        console.error(error);
        globalDispatch({ type: "needsAuthentication", data: false });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit() {
    const passwordHash = calculatePasswordHash(username, password);
    //use a fresh axios client to prevent custom 401 error handling by response interceptors
    Axios.create()
      .get("/api/private/user", {
        auth: {
          username: username,
          password: passwordHash
        }
      })
      .then(
        response => {
          const user = response.data;
          user.username = username;
          user.passwordHash = passwordHash;
          globalDispatch({ type: "login", data: user });
        },
        error => {
          if (error.response.status === 401) {
            message.error(localizer.resolve("Global.feedback.wrongCredentials"));
          }
          // logger.info(`${error}: ${error.response.data}`);
        }
      );
  }

  if (!globalState.needsAuthentication && !globalState.isLoggedIn) {
    handleSubmit();
    return <p>One moment please</p>;
  }

  return (
    <div className="login">
      <header className="header">
        <div className="branding">
          <img className="branding__name" src="logo-transparant.png" alt="digital-ink logo" />
          <span className="branding__version">{AppInfo.version}</span>
        </div>
      </header>
      <div className="login__form">
        <div className="login__title">
          <h2>{localizer.resolve("Login.title")}</h2>
        </div>
        <Form {...layout} name="basic" onFinish={handleSubmit}>
          <Form.Item label={localizer.resolve("Login.username")} name="username">
            <Input
              placeholder={localizer.resolve("Login.usernameHint")}
              onChange={e => setUsername(e.target.value.toLowerCase())} //username = emailaddress = case insensitive
              size={"middle"}
            />
          </Form.Item>
          <Form.Item label={localizer.resolve("Login.password")} name="password">
            <Input.Password onChange={e => setPassword(e.target.value)} size={"middle"} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              {localizer.resolve("Login.submitCaption")}
            </Button>
          </Form.Item>
        </Form>
        <div className="login__alternatives">
          <Button type="link" onClick={() => navigate("/register")}>
            {localizer.resolve("Register.createAccount")}
          </Button>
          <Button type="link" onClick={() => navigate("/agreement")}>
            {localizer.resolve("Legal.agreement")}
          </Button>
          <Button type="link" onClick={() => navigate("/privacy")}>
            {localizer.resolve("Legal.privacy")}
          </Button>
          <Button type="link" onClick={() => navigate("/aboutus")}>
            {localizer.resolve("Legal.aboutus")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Login;
