import React from "react";
import { useNavigate } from "react-router-dom";
import { localizer } from "di-common";
import { Button, Tooltip } from "antd";
import { CheckOutlined, CloseOutlined, DownloadOutlined, SaveOutlined } from "@ant-design/icons";

type SaveButtonProps = {
  isDisabled?: boolean;
  isDirty: boolean;
  isSaving: boolean;
  save: (e: any) => void;
};

export function SaveButton({ isDisabled = false, isDirty, isSaving, save }: SaveButtonProps) {
  return (
    <Tooltip title={localizer.resolve(`Global.buttonCaption.save${isDirty ? "Dirty" : ""}`)}>
      <Button
        disabled={isDisabled}
        type="default"
        size="large"
        danger={isDirty}
        shape="circle"
        icon={<SaveOutlined />}
        htmlType="button"
        onClick={save}
        loading={isSaving || false}
      />
    </Tooltip>
  );
}

type ExportButtonProps = {
  isDisabled?: boolean;
  name: string;
  exportFunction: (e: any) => void;
};

export function ExportButton({ isDisabled = false, name, exportFunction }: ExportButtonProps) {
  return (
    <Tooltip title={localizer.resolve("Global.buttonCaption.export", { name })}>
      <Button
        disabled={isDisabled}
        type="default"
        size="large"
        shape="circle"
        icon={<DownloadOutlined />}
        htmlType="button"
        onClick={exportFunction}
      />
    </Tooltip>
  );
}

type CloseButtonProps = {
  isDirty: boolean;
  close: string | ((e: any) => void);
};
export function CloseButton({ isDirty, close }: CloseButtonProps) {
  if (!isStringOrFunction(close)) {
    throw new Error("close should be a string or a (synthetic) click event handler");
  }

  const navigate = useNavigate();
  const closeAction =
    typeof close === "string"
      ? (event: any) => {
          navigate(close);
        }
      : close;

  return (
    <Tooltip title={localizer.resolve(`Global.buttonCaption.close${isDirty ? "Dirty" : ""}`)}>
      <Button
        type="default"
        size="large"
        shape="circle"
        icon={isDirty ? <CloseOutlined /> : <CheckOutlined />}
        htmlType="button"
        onClick={closeAction}
      />
    </Tooltip>
  );
}

function isStringOrFunction(value: any): boolean {
  return value && (typeof value === "string" || typeof value === "function");
}
