import React, { useState } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { HistoryEditor, withHistory } from "slate-history";
import { ConsoleLogger, LogLevel } from "di-common";
import { BaseEditor, Descendant, Node, createEditor } from "slate";
import { ReactEditor } from "slate-react";
import { localizer } from "di-common";

type CustomElement = { type: "paragraph"; children: CustomText[] };
type CustomText = { text: string };

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}
const logger = new ConsoleLogger("SlateEditor", LogLevel.DEBUG);

type SlateEditorProps = {
  /** Plain text value for the content of the editor */
  value?: string;
  onChange?: (newContent: string) => void;
  disabled?: boolean;
};

/**
 * This component manages it's own state.
 */
function SlateEditor({ value, onChange, disabled = false }: SlateEditorProps) {
  const [editor] = useState(() => withReact(withHistory(createEditor())));

  const initialValue: Descendant[] = [
    {
      type: "paragraph",
      children: [{ text: value || "" }]
    }
  ];

  const serialize = (value: Node[]) => {
    return (
      value
        // Return the string content of each paragraph in the value's children.
        .map(node => Node.string(node))
        // Join them all with line breaks denoting paragraphs.
        .join("\n")
    );
  };

  const currentEditorValue = serialize(editor.children);
  if (value !== currentEditorValue) {
    logger.debug("Replacing current editor content with text of length:", value ? value.length : 0);
    editor.children = initialValue;
  }

  function exposeChange(value: string) {
    if (typeof onChange === "function") {
      onChange(value);
    }
  }

  const classNames = "text-editor ant-input" + (disabled === true ? " readonly" : "");
  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={value => {
        const isAstChange = editor.operations.some(op => "set_selection" !== op.type);
        if (isAstChange) {
          // Serialize the value and pass it on the onChange property function
          exposeChange(serialize(value));
        }
      }}
    >
      <Editable readOnly={disabled} className={classNames} placeholder={localizer.resolve("Global.placeholder.editor")} />
    </Slate>
  );
}

export default React.memo(SlateEditor);
