import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import rfdc from "rfdc";
import { localizer } from "di-common";
import { Entity, getDao } from "../../dao/EntityDao";
import StateContext, { getStateContext } from "../../StateContext";
import EntityStateContext, { getEntityStateContext } from "./EntityStateContext";
import ImageUpload from "../general/ImageUpload";
import { SINGLE_VIEW, ViewTypes, formFieldsByEntityType } from "./EntityConstants";
import { FormInstance } from "antd/es/form/Form";
import IconFont from "../general/IconFont";
import { isNotEqual } from "../../utils/MiscellaneousUtil";
import { ConsoleLogger } from "di-common";

const deepClone = rfdc();
const logger = new ConsoleLogger("EntityForm");

type EntityFormProps = {
  entity?: Entity;
  form: FormInstance;
  setEntity: React.Dispatch<React.SetStateAction<Entity | undefined>>;
  setMode: React.Dispatch<React.SetStateAction<ViewTypes>>;
  setSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

function EntityForm({ entity, form, setEntity, setMode, setSaving, setDirty }: EntityFormProps) {
  const { entityType } = getEntityStateContext(useContext(EntityStateContext));
  // const entityDao = getDao(entityType);
  const { projectId } = useParams();

  //variable to prevent closing the form when it is autosaved
  const isAutoSaved = useRef(false);
  const {
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  const syncedEntity = useRef<Record<string, string | number | boolean>>(entity || {});
  /**
   * Very limited Form.Item factory method, specific for this component; there is little customization possible
   * @param {*} fieldName
   */
  function createFormItem(fieldName: string): React.JSX.Element {
    //possible generated input fields: textarea, zeroOrPositiveNumber and date
    let requiredInput = "textarea"; //the common case
    const lowerCaseFieldName = fieldName.toLowerCase();
    if (lowerCaseFieldName.includes("date")) {
      requiredInput = "date";
    } else if (lowerCaseFieldName.startsWith("age" || lowerCaseFieldName.endsWith("age"))) {
      requiredInput = "zeroOrPositiveNumber";
    } else if (lowerCaseFieldName === "gender") {
      requiredInput = "gender";
    }

    switch (requiredInput) {
      case "date":
        return (
          <Form.Item name={fieldName} label={localizer.resolve(`${entityType}.${fieldName}.label`)} key={fieldName}>
            <DatePicker format="YYYY-MM-DD" placeholder={localizer.resolve(`${entityType}.${fieldName}.placeholder`)} />
          </Form.Item>
        );
      case "zeroOrPositiveNumber":
        return (
          <Form.Item name={fieldName} label={localizer.resolve(`${entityType}.${fieldName}.label`)}>
            <InputNumber min={0} />
          </Form.Item>
        );
      case "gender":
        return (
          <Form.Item name={fieldName} label={localizer.resolve(`${entityType}.${fieldName}.label`)}>
            <Select allowClear={true}>
              <Select.Option value="MALE">
                {localizer.resolve("Character.gender.MALE")} <IconFont type="icon-gendermale" />
              </Select.Option>
              <Select.Option value="FEMALE">
                {localizer.resolve("Character.gender.FEMALE")} <IconFont type="icon-genderfemale" />
              </Select.Option>
              <Select.Option value="TRANS">
                {localizer.resolve("Character.gender.TRANS")} <IconFont type="icon-gendermalefemale" />
              </Select.Option>
            </Select>
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            name={fieldName}
            label={localizer.resolve(`${entityType}.${fieldName}.label`)}
            rules={[
              {
                /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
                max: 65000,
                message: localizer.resolve("Global.message.max65KDescription")
              }
            ]}
          >
            <TextArea maxLength={65001} />
          </Form.Item>
        );
    }
  }

  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        autosave();
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      console.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      console.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  function isDirtyCheck(): boolean {
    const fieldNames = ["name", "avatarUrl"].concat(formFieldsByEntityType.get(entityType) || []);
    const syncedEntityData = syncedEntity.current;
    for (const fieldName of fieldNames) {
      if (isNotEqual(syncedEntityData[fieldName], form.getFieldValue(fieldName))) {
        logger.info("Formfield dirty: " + fieldName);
        return true;
      }
    }
    return false;
  }

  function autosave() {
    if (isDirtyCheck()) {
      isAutoSaved.current = true;
      form.submit();
    }
  }

  function sendForm(values: any): void {
    if (projectId && currentUser) {
      setSaving(true);
      const entityToSave = { ...deepClone(entity), ...values };
      getDao(entityType).insertOrUpdateEntity(
        projectId,
        entityToSave,
        currentUser,
        (response: any) => {
          setSaving(false);
          setDirty(false);
          setEntity(entityToSave);
          syncedEntity.current = entityToSave;
          if (isAutoSaved.current === false) {
            setMode(SINGLE_VIEW);
          }
          isAutoSaved.current = false;
        },
        (error: any) => {
          setSaving(false);
          isAutoSaved.current = false;
          console.error(error);
        }
      );
    }
  }

  const { TextArea } = Input;
  return (
    <Form
      name={entityType}
      form={form}
      initialValues={entity}
      layout="vertical"
      onFinish={values => sendForm(values)}
      onFieldsChange={() => setDirty(isDirtyCheck())}
      preserve
      size="small"
    >
      <Form.Item
        name="name"
        label={localizer.resolve(`${entityType}.name.label`)}
        rules={[
          {
            max: 100,
            message: localizer.resolve(`${entityType}.name.message.maxLength`)
          },
          {
            required: true,
            message: localizer.resolve(`${entityType}.name.message.mandatory`)
          }
        ]}
      >
        <Input maxLength={101} />
      </Form.Item>
      <ImageUpload
        name="avatarUrl"
        label={localizer.resolve(`${entityType}.avatar.label`)}
        extra={localizer.resolve(`${entityType}.avatar.extra`)}
        initialUrl={entity && entity.avatarUrl}
      />
      {formFieldsByEntityType.get(entityType)?.map(fieldName => createFormItem(fieldName))}
    </Form>
  );
}

export default EntityForm;
