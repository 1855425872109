/**
 * Count the number of words in the input text
 * @param {string} input the text we want a word count from
 */
export function wordCount(input = ""): number {
  let wordCount = 0;
  const text = input.split(/[ \n]+/);
  for (let i = 0; i < text.length; i++) {
    if (text[i].trim().length > 0 && isWord(text[i])) {
      wordCount++;
    }
  }
  return wordCount;
}

function isWord(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    const code = str.charCodeAt(i);
    if (
      (code > 47 && code < 58) || // numeric (0-9)
      (code > 64 && code < 91) || // upper alpha (A-Z)
      (code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return true;
    }
  }
  return false;
}
