import { Modal } from "antd";
import { localizer } from "di-common";
import React from "react";
import { RegistryEntry } from "../../utils/AutosaveRegistry";

type UnsavedChangesModalProps = {
  registryEntries: RegistryEntry[];
  onOk(): void;
  onCancel(): void;
};

export default function UnsavedChangesModal({ onOk, onCancel, registryEntries }: UnsavedChangesModalProps) {
  return (
    <Modal
      className="unsaved-changes-modal"
      title={localizer.resolve("UnsavedChangesModal.title")}
      open={true}
      onOk={onOk}
      okText={localizer.resolve("UnsavedChangesModal.okText")}
      onCancel={onCancel}
      cancelText={localizer.resolve("UnsavedChangesModal.cancelText")}
    >
      <p>{localizer.resolve("UnsavedChangesModal.bodyHeader")}</p>
      <ul>
        {registryEntries
          .filter(entry => entry.isDirty() === true)
          .map(entry => (
            <li key={entry.id}>{entry.name}</li>
          ))}
      </ul>
      <p>{localizer.resolve("UnsavedChangesModal.bodyFooter")}</p>
    </Modal>
  );
}
