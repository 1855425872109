import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Form, Upload, UploadFile, message, Spin } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import resourceDao from "../../dao/ResourceDao";

const supportedTypes = ["image/apng", "image/gif", "image/jpeg", "image/png", "image/svg+xml", "image/webp"];

type ImageUploadProps = {
  isDisabled?: boolean;
  name: string;
  label: string;
  extra: string;
  initialUrl?: string;
  maxKb?: number;
};

/**
 * This component supports maintaining a SINGLE image
 */
function ImageUpload({ isDisabled = false, name, label, extra, initialUrl, maxKb = 512 }: ImageUploadProps) {
  const baseUrl = window.location.protocol + "//" + window.location.host;
  const { projectId } = useParams();
  const [imageLoading, setImageLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(initialUrl != null);
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  const initialFileList = useRef<UploadFile[]>(
    initialUrl
      ? [
          {
            uid: "-1",
            name: "file",
            status: "done",
            url: baseUrl + initialUrl
          }
        ]
      : []
  );

  const normFile = (e: any): any => {
    if (e.file.status === "done") {
      return e.file.response;
    }
  };

  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    setImageLoading(info.file.status === "uploading");
  }

  // (file: RcFile, FileList: RcFile[]) => BeforeUploadValueType | Promise<BeforeUploadValueType>) | undefined
  function beforeUpload(file: RcFile): string | boolean {
    const imageType = file.type ? file.type : localizer.resolve("Global.unknown");
    const isSupported = supportedTypes.includes(imageType.toLowerCase());
    if (!isSupported) {
      message.error(
        localizer.resolve("Global.message.imageTypeNotSupported", {
          imageType
        })
      );
      return Upload.LIST_IGNORE;
    }
    const isLtMaxKb = file.size / 1024 / maxKb < 1;
    if (!isLtMaxKb) {
      message.error(
        localizer.resolve("Global.message.imageTooBig", {
          maxKb
        })
      );
      return Upload.LIST_IGNORE;
    }
    return true;
  }

  function getBasicAuthHeaderValue(): string {
    // This basic auth. header is added to the upload request
    let headerValue = "Basic ";
    if (currentUser) {
      const credentials = currentUser.username + ":" + currentUser.passwordHash;
      // Firstly, escape the string using encodeURIComponent to get the UTF-8 encoding of the characters,
      // Secondly, we convert the percent encodings into raw bytes, and add it to btoa() function.
      headerValue += btoa(
        encodeURIComponent(credentials).replace(/%([0-9A-F]{2})/g, function (match, p1) {
          return String.fromCharCode(Number("0x" + p1));
        })
      );
    }
    return headerValue;
  }

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const fileListConfig = {
    showPreviewIcon: false,
    showDownloadIcon: false,
    showRemoveIcon: true
  };

  useEffect(() => {
    if (initialFileList.current.length === 1 && initialUrl && currentUser) {
      resourceDao.getResource(
        currentUser,
        baseUrl + initialUrl,
        (response: any) => {
          const binary = new Blob([response.data]);
          const objectUrl = URL.createObjectURL(binary);
          initialFileList.current[0].thumbUrl = objectUrl;
          setInitialLoading(false);
        },
        (error: any) => {
          console.dir(error);
          setInitialLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    return <Spin />;
  }

  return (
    <Form.Item name={name} label={label} valuePropName="file" getValueFromEvent={normFile} extra={extra}>
      <Upload
        disabled={isDisabled}
        name="file"
        listType="picture-card"
        showUploadList={fileListConfig}
        defaultFileList={initialFileList.current}
        action={`/api/private/resources/${currentUser?.id}/${projectId}`}
        maxCount={1}
        accept={supportedTypes.join(",")}
        beforeUpload={beforeUpload}
        headers={{ Authorization: getBasicAuthHeaderValue() }}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload>
    </Form.Item>
  );
}

export default ImageUpload;
