import { AxiosError } from "axios";
import { User } from "../StateContext";
import outlineDao, { Outline, PlotItem, PlotLine } from "../dao/OutlineDao";

export type PlotData = [Outline, (PlotLine | PlotItem)[]];

/**
 * Retrieve the outline and plotlines from the server
 */
export default class RemotePlotCollection {
  constructor(private currentUser: User, private projectId: string) {}

  async getOutlineAndPlotlines(): Promise<PlotData> {
    const outline = (await outlineDao.loadOutline(this.projectId, this.currentUser)).data;
    const plotlines = await loadPlotboard(this.projectId, this.currentUser);
    return [outline, plotlines];
  }
}

async function loadPlotboard(projectId: string, user: User) {
  let cardsPerPlotline: (PlotLine | PlotItem)[] = [];
  try {
    //when there is no plotboard (yet), the server returns a 404
    const plotboardData = (await outlineDao.loadPlotboard(projectId, user)).data;
    for (const plotLine of plotboardData.plotLines) {
      const cardsThisLine: PlotItem[] = [];
      for (const card of plotboardData.plotCards) {
        if (card.location.x === plotLine.columnIndex) {
          cardsThisLine.push(card);
        }
      }
      //sort by row number
      cardsThisLine.sort((a, b) => a.location.y - b.location.y);
      cardsPerPlotline.push(plotLine);
      cardsPerPlotline = cardsPerPlotline.concat(cardsThisLine);
    }
  } catch (error) {
    if (error instanceof AxiosError && error.status === 404) {
      console.warn("No plotboard saved yet");
    } else {
      console.log(error);
    }
  }
  return cardsPerPlotline;
}
