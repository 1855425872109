import { createContext } from "react";
import { OutlineState, ProjectMetaState } from "./ProjectView";
import { GlobalAction } from "../..";

export type ProjectViewDispatchContextType = {
  outlineState: OutlineState;
  outlineDispatch: React.Dispatch<GlobalAction>;
  projectMetaState: ProjectMetaState;
  projectMetaDispatch: React.Dispatch<GlobalAction>;
  setModalComponent: React.Dispatch<React.SetStateAction<React.JSX.Element | null>>;
};

const ProjectViewDispatchContext = createContext<ProjectViewDispatchContextType | null>(null);

export function getProjectViewDispatchContext(
  dispatchContext: ProjectViewDispatchContextType | null
): ProjectViewDispatchContextType {
  if (!dispatchContext) {
    throw new Error("ProjectViewDispatch context not defined");
  }
  return dispatchContext;
}

export default ProjectViewDispatchContext;
