import React, { useEffect, useContext, useRef, useState } from "react";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { Spin, Form, Input, Collapse, Button, message, Dropdown, Popconfirm, Typography, Tooltip, Space } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import rfdc from "rfdc";
import { wordCount } from "../../utils/wordCount";
import StateContext, { getStateContext } from "../../StateContext";
import ProjectDispatchContext, { getProjectViewDispatchContext } from "./ProjectDispatchContext";
import { localizer } from "di-common";
import { SessionConstants } from "../../Constants";
import OutlineManager, { OutlineNode, isContainerType, isContentType } from "../../utils/OutlineManager";
import { getOrFail, isNotEqual } from "../../utils/MiscellaneousUtil";
import { getImportFragmentSubmenu, ImportFragmentDialog } from "./ImportFragmentItem";
import { ExportButton, SaveButton } from "../general/CommonButtons";
import SlateEditor from "../general/SlateEditor";
import fragmentDao from "../../dao/FragmentDao";
import { GlobalAction } from "../..";
import { Content, ContentMeta, StructuralTypeName } from "../../dao/ProjectDao";
import { ConsoleLogger, LogLevel } from "di-common";
import docxExportService from "../../services/DocXExportService";
import RemoteContentCollection from "../../utils/RemoteContentCollection";
import AutosaveContext, { getAutosaveContext } from "../../utils/AutosaveContext";

const logger = new ConsoleLogger("ContentPane", LogLevel.DEBUG);

const deepClone = rfdc();

export type ImportCommand = {
  nodeType: string;
  position: "append" | string;
  keyPath?: string[];
  fragmentId?: string;
  deleteFragment?: boolean;
  title?: string;
  content?: string;
  afterImport?: Function;
};

type ContentWorkingCopy = Omit<Content, "contentMeta"> & {
  contentMeta: Partial<ContentMeta>;
};

/** All properties and child properties are optional, content is managed not as state but as Ref */
type ContentStateWorkingCopy = Omit<ContentWorkingCopy, "content">;

type ContentState = {
  /* False means: content is not yet initialized (or it is a container such as CHAPTER ) */
  content: ContentStateWorkingCopy | false;
  saveCount: number;
};

type ContentPaneProps = {
  isDisabled?: boolean;
  contentId: string;
  outlineData: OutlineNode[];
  projectId?: string;
  selectedNodeKey?: string;
  exchangeViaStorageCount: number;
};

/**
 * This React functional component is responsible to display a single content-item from a project. Any changes made
 * are saved through this component (either triggered by the user or by auto-save functionality).
 */
function ContentPane({
  isDisabled = false,
  contentId,
  outlineData,
  projectId,
  selectedNodeKey,
  exchangeViaStorageCount
}: ContentPaneProps) {
  /**
   * As of the use of SlateEditor, content is no longer managed as State by the ContentPane, but by
   * SlateEditor. In order to save the data, we keep a copy of the editor contents here.
   */
  const contentRef = useRef("");

  function createEmptyContent(): ContentWorkingCopy {
    return {
      content: "",
      contentMeta: {
        title: undefined,
        type: "SCENE",
        wordCount: 0
      },
      notes: undefined
    };
  }

  //Represents the most recently known state of the node as it is stored on the server (excl. the content property)
  const syncedContent = useRef(createEmptyContent());

  //Represents a recent contentState of the content in the browser, that is saved to the server
  const copyToSave = useRef(createEmptyContent());

  const {
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  /**
   * The form instance to reference in rendering and to access it's API
   */
  const [form] = Form.useForm();

  /**
   * Flag that indicates that content is being retrieved from the server or not
   */
  const [isLoading, setLoading] = useState(false);

  /**
   * Flag that indicates that the editor contains unsaved content or not. In other words,
   * the content differs from the content stored on the server, or not.
   */
  const [isDirty, setDirty] = useState(false);

  /* Can have three types of values: "saving" means that a save operation is running and we are waiting for server response,
   * "success" means that the previous request was succesfully finished, otherwise it has the value of the error received from
   * the server
   */
  const [saveState, setSaveState] = useState<"success" | "saving" | string>("success");

  /**
   * Obtain a reference to the project metadata and the dispatcher for outline-changes from the context (some parent component that is holding this state)
   */
  const { outlineState, outlineDispatch, projectMetaState } = getProjectViewDispatchContext(useContext(ProjectDispatchContext));

  const autosaveRegistry = getAutosaveContext(useContext(AutosaveContext));

  /**
   * Convenience wrapper around the outline state data
   */
  const outlineManager = OutlineManager.createFromTreeData(projectMetaState.projectMeta!.projectType, outlineData);

  //Pull up this state + useEffect that loads fragments
  const [fragments, setFragments] = useState([]);

  const [showSelectFragmentDialog, setShowSelectFragmentDialog] = useState(false);

  /* temporary store the data event that we will need to dispatch. It will be enriched in various async steps */
  const importCommandRef = useRef<ImportCommand>();

  //Indicate if the current save operation is triggered by the user or by the autosave mechanism
  const [saveSource, setSaveSource] = useState<"none" | "user" | "autosave">("none");

  const initialContentState: ContentState = {
    content: false,
    //Use saveCount to trigger displaying feedback message via a useEffect
    saveCount: 0
  };

  const [contentState, contentDispatch] = useImmerReducer(contentReducer, initialContentState);

  function contentReducer(draft: ContentState, action: GlobalAction) {
    logger.debug("ContentReducer: processing action :", action.type);
    switch (action.type) {
      case "setContent":
        draft.content = action.data; //Should we remove content property, because it is stored under contentRef?
        contentRef.current = action.data.content || "";
        //Make sure null-values from the server are cleared in the GUI when switching between content objects
        const initialValues = Object.assign(createEmptyContent(), draft.content, {
          content: contentRef.current
        });
        syncedContent.current = deepClone(initialValues);
        form.setFieldsValue(initialValues); //react warning: changing state during render
        setDirty(isDirtyCheck());
        break;
      case "saveSuccess":
        setSaveState("success");
        draft.saveCount++; // This may trigger success message via useEffect
        if (draft.content && copyToSave.current.contentMeta.pathDescriptor === draft.content.contentMeta.pathDescriptor) {
          //only copy this when not saved during contentnode-switch
          syncedContent.current = deepClone(copyToSave.current);
        }
        setDirty(isDirtyCheck());
        break;
      case "saveFailed":
        console.error(`${saveSource}: There was a problem saving the content: ${action.data}`);
        setSaveState(action.data);
        draft.saveCount++; // This may trigger error message via useEffect
        break;
      case "updateWordCount":
        if (draft.content) {
          draft.content.contentMeta.wordCount = action.data.wordCount;
        }
        break;
      case "clearAndSaveContent":
        if (draft.content) {
          emptyCurrentContentNode(draft.content);
          sendForm(draft);
        }
        break;
      case "loadContentFailed":
        console.error(`There was a problem loading the content: ${action.data}`);
        break;
      case "exportContent":
        {
          let meta = typeof draft.content === "boolean" ? undefined : draft.content.contentMeta;
          if (meta != null && isContentType(meta.type)) {
            exportLeafNode(meta);
          }
        }
        break;
      case "exportContainer":
        {
          let meta = typeof draft.content === "boolean" ? undefined : draft.content.contentMeta;
          if (meta != null && isContainerType(meta.type)) {
            let title = meta.title;
            const iterableContent = new RemoteContentCollection(
              action.data.currentUser,
              action.data.outlineData,
              meta.pathDescriptor
            );
            iterableContent
              .getContentCollection()
              .then(collection =>
                docxExportService.processProject(
                  projectMetaState.projectMeta?.projectType || "NOVEL",
                  title || "",
                  collection,
                  true
                )
              )
              .catch(console.error);
          }
        }
        break;
      default:
        console.error(`ContentPane: Unexpected action type: ${action.type}`);
    }
  }

  function exportLeafNode(meta: Partial<ContentMeta>) {
    let content: Content[] = [
      {
        content: contentRef.current,
        contentMeta: {
          key: meta.key || "1",
          pathDescriptor: meta.pathDescriptor || "0001",
          title: meta.title || "",
          type: meta.type || "SCENE",
          wordCount: meta.wordCount || 0
        }
      }
    ];
    docxExportService.processProject("SHORT_STORY", meta.title || "", content);
  }

  useEffect(() => {
    let meta = typeof contentState.content === "boolean" ? undefined : contentState.content.contentMeta;
    const mainEditorName =
      meta != null
        ? `${localizer.resolve("UnsavedChangesModal.mainEditor")} (${localizer.resolve("StructuralType." + meta.type)})`
        : "ContentEditor";
    autosaveRegistry.addEntry({
      id: "ContentEditor",
      name: mainEditorName,
      isDirty: () => isDirty,
      autosave
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, contentState.content]);

  /**
   * Load and cache fragments the first time the content pane is rendered
   */
  useEffect(() => {
    // load all fragment excerpts in order to assess if we need to display the import button
    // and to fill the selection list in the modal window
    if (currentUser) {
      logger.debug("loading fragments");
      fragmentDao.loadFragmentList(
        currentUser,
        (response: any) => {
          setFragments(response.data);
        },
        console.dir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Load content from the server when the selected node has changed
   */
  useEffect(() => {
    //Load content treenode selected has changed
    if (selectedNodeKey && currentUser) {
      if (userPreferences.isAutoSaveOn && isDirty) {
        //Before loading other content, make sure changes to current content are saved (before autosave interval has passed)
        autosave();
      }
      setLoading(true);
      logger.debug(`load content node for key ${selectedNodeKey} (path = ${contentId})`);
      Axios.get(`/api/private/content/${currentUser.id}/${selectedNodeKey}`, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response => {
          logger.debug(`Received data from the server for node @${response.data.contentMeta.pathDescriptor}`);
          contentDispatch({ type: "setContent", data: response.data });
          setLoading(false);
        },
        error => {
          contentDispatch({ type: "loadContentFailed", data: error });
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodeKey]);

  /**
   * Exchange data via event bus; this does not fit React's developing model. Must go. (TODO)
   * At present only used to clear and save the contents of the only node (like a delete
   * and create new empty node in one)
   */
  useEffect(() => {
    //pickup dispatch event that is exchanged via sessionStore -- try to get rid of this...
    if (exchangeViaStorageCount > 0) {
      const rawEvent = sessionStorage.getItem(SessionConstants.CONTENTPANE_EVENT_KEY);
      if (rawEvent) {
        const dispatchCommand = rawEvent ? JSON.parse(rawEvent) : null;
        sessionStorage.removeItem(SessionConstants.CONTENTPANE_EVENT_KEY);
        logger.debug("ContentPane: Receiving content via the session store", dispatchCommand);
        contentDispatch(dispatchCommand);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeViaStorageCount]);

  /**
   * Give user feedback about result of manual save operation. This is triggered by changing the saveState state value.
   */
  useEffect(() => {
    if (saveState !== "saving" && saveSource === "user" && contentState.saveCount > 0) {
      if (saveState === "success") {
        message.success(localizer.resolve("Global.feedback.saveSuccess"));
      } else {
        message.error(localizer.resolve("Global.feedback.saveError"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveState]);

  /**
   * Do autosave every so-many seconds (set in userPreferences)
   */
  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        autosave();
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      logger.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      logger.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  function handleChangedFields(changedFields: any) {
    setDirty(isDirtyCheck());
    if (changedFields.length > 0) {
      if (changedFields[0].name.join(".") === "contentMeta.title") {
        outlineDispatch({
          type: "updateTitle",
          data: {
            newTitle: changedFields[0].value || "",
            projectType: getOrFail(projectMetaState.projectMeta?.projectType)
          }
        });
      }
    }
  }

  function handleChangedContent(updatedContent: string): void {
    contentRef.current = updatedContent;
    const updateWordcountAction: GlobalAction = {
      type: "updateWordCount",
      data: {
        wordCount: wordCount(updatedContent),
        projectType: getOrFail(projectMetaState.projectMeta?.projectType)
      }
    };
    setDirty(isDirtyCheck());
    outlineDispatch(updateWordcountAction);
    contentDispatch(updateWordcountAction);
  }

  function handleInsertMenuClick(data: { keyPath: string[] }) {
    //The keyPath are the keys ordered from leaf node to root node.
    const { keyPath } = data;
    const projectType = getOrFail(projectMetaState.projectMeta?.projectType);
    let action: GlobalAction;
    if (keyPath[keyPath.length - 1].startsWith("fragment")) {
      importCommandRef.current = {
        nodeType: keyPath[keyPath.length - 1].substring(9), //9 insead of 8, because fragment is followed by C (child) or S (sibling), to make the keys unique
        position: keyPath.length === 1 ? "append" : keyPath[0]
      };
      setShowSelectFragmentDialog(true);
    } else {
      if (keyPath.length === 1) {
        action = {
          type: "appendChild",
          data: {
            projectId,
            projectType,
            type: keyPath[0].substring(5) //remove prefix 'child'
          }
        };
      } else {
        action = {
          type: "insertSibling",
          data: {
            projectId,
            projectType,
            type: keyPath[1],
            insertAfter: keyPath[0].startsWith("after")
          }
        };
      }
      outlineDispatch(action);
    }
  }

  function emptyCurrentContentNode(draftContentNode: ContentStateWorkingCopy) {
    draftContentNode.notes = undefined;
    contentRef.current = "";

    const projectType = getOrFail(projectMetaState.projectMeta?.projectType);
    const defaultTitle = localizer.resolve("Content.title.for" + draftContentNode.contentMeta.type);
    draftContentNode.contentMeta.title = defaultTitle;
    draftContentNode.contentMeta.wordCount = 0;
    outlineDispatch({
      type: "updateTitle",
      data: {
        newTitle: defaultTitle,
        projectType
      }
    });
    outlineDispatch({
      type: "updateWordCount",
      data: {
        wordCount: 0,
        projectType
      }
    });
    form.setFieldsValue(draftContentNode);
  }

  function sendForm(draftState?: ContentState, formData?: any): void {
    logger.info("Preparing content to save");
    const state = draftState ? draftState : contentState;
    if (state && state.content) {
      copyToSave.current = Object.assign(deepClone(state.content), {
        content: contentRef.current || ""
      });
    }
    if (formData) {
      copyToSave.current.notes = formData.notes;
      copyToSave.current.contentMeta.title = formData.contentMeta.title || "";
    }

    if (currentUser) {
      logger.info("Sending content to server");
      setSaveState("saving");
      Axios.put(`api/private/content/${currentUser.id}`, copyToSave.current, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response => contentDispatch({ type: "saveSuccess" }),
        error => contentDispatch({ type: "saveFailed", data: error })
      );
    }
  }

  function isDirtyCheck() {
    //Check the contents of the editor
    const isDirty =
      isNotEqual(syncedContent.current.contentMeta.title, form.getFieldValue(["contentMeta", "title"]) || "") ||
      isNotEqual(syncedContent.current.content, contentRef.current) ||
      isNotEqual(syncedContent.current.notes, form.getFieldValue("notes"));
    return isDirty;
  }

  function autosave() {
    if (!isDisabled && isDirtyCheck()) {
      setSaveSource("autosave");
      form.submit();
    }
  }

  const previousNode = outlineManager.getPreviousContentNode(contentId);
  const nextNode = outlineManager.getNextContentNode(contentId);
  function makeInsertMenu() {
    const fragmentMenu = getImportFragmentSubmenu(outlineManager, contentId, getNodeType(contentState.content), fragments.length);
    let menu = {
      onClick: handleInsertMenuClick,
      items: [
        ...outlineManager.getAllowedSiblingTypes(contentId).map(nodeType => {
          return {
            key: nodeType,
            icon: <PlusOutlined />,
            label: localizer.resolve("projectview.button.new" + nodeType),
            children: [
              {
                key: `before${nodeType}`,
                label: localizer.resolve("projectview.button.before" + nodeType)
              },
              {
                key: `after${nodeType}`,
                label: localizer.resolve("projectview.button.after" + nodeType)
              }
            ]
          };
        }),
        ...outlineManager.getAllowedChildTypes(getNodeType(contentState.content)).map(nodeType => {
          return {
            key: "child" + nodeType,
            icon: <PlusOutlined />,
            label: localizer.resolve("projectview.button.new" + nodeType)
          };
        })
      ]
    };
    if (fragmentMenu) {
      menu.items = [...menu.items, ...fragmentMenu];
    }
    return menu;
  }

  function getNodeType(contentNode: ContentStateWorkingCopy | false): StructuralTypeName {
    if (!contentNode || !contentNode.contentMeta.type) {
      throw new Error("Content not yet properly initialized");
    }
    return contentNode.contentMeta.type;
  }

  const { Panel } = Collapse;
  const { Paragraph } = Typography;
  const projectType = getOrFail(projectMetaState.projectMeta?.projectType);
  return (
    <>
      {!isLoading && contentState.content && (
        <div className="content-area">
          <Space style={{ paddingBottom: "1rem" }} size="small">
            <SaveButton
              isDisabled={isDisabled}
              isDirty={isDirty}
              isSaving={saveState === "saving"}
              save={() => {
                setSaveSource("user");
                form.submit();
              }}
            />
            <Tooltip title={localizer.resolve("Global.buttonCaption.insert")}>
              <Dropdown disabled={isDisabled} trigger={["click"]} menu={makeInsertMenu()}>
                <Button icon={<PlusOutlined />} shape="circle" size="large" />
              </Dropdown>
            </Tooltip>
            <Popconfirm
              disabled={isDisabled}
              title={localizer.resolve("projectview.title.delete" + contentState.content.contentMeta.type)}
              onConfirm={e => {
                e?.stopPropagation();
                outlineDispatch({
                  type: "deleteNode",
                  data: {
                    projectType
                  }
                });
              }}
              onCancel={e => e?.stopPropagation()}
              okText={localizer.resolve("Global.buttonCaption.confirmDeletion")}
              cancelText={localizer.resolve("Global.buttonCaption.no")}
            >
              <Tooltip title={localizer.resolve("projectview.button.delete" + contentState.content.contentMeta.type)}>
                <Button
                  disabled={isDisabled}
                  icon={<DeleteOutlined />}
                  shape="circle"
                  size="large"
                  onClick={e => e.stopPropagation()}
                />
              </Tooltip>
            </Popconfirm>
            <ExportButton
              isDisabled={typeof contentState.content === "boolean"}
              name={contentState.content.contentMeta.title || ""}
              exportFunction={() => {
                let meta = typeof contentState.content === "boolean" ? undefined : contentState.content.contentMeta;
                if (meta != null && isContentType(meta.type)) {
                  contentDispatch({ type: "exportContent" });
                } else if (meta != null && isContainerType(meta.type)) {
                  contentDispatch({ type: "exportContainer", data: { currentUser, outlineData: outlineState.outlineData } });
                }
              }}
            />
            <Tooltip title={localizer.resolve("Global.buttonCaption.previousText")}>
              <Button
                disabled={previousNode === null}
                icon={<ArrowLeftOutlined />}
                size="large"
                shape="circle"
                onClick={() => {
                  outlineDispatch({
                    type: "treeNodeSelected",
                    data: {
                      selectedKeys: [previousNode?.key],
                      projectType
                    }
                  });
                }}
              />
            </Tooltip>
            <Tooltip title={localizer.resolve("Global.buttonCaption.nextText")}>
              <Button
                disabled={nextNode === null}
                icon={<ArrowRightOutlined />}
                size="large"
                shape="circle"
                onClick={() => {
                  outlineDispatch({
                    type: "treeNodeSelected",
                    data: {
                      selectedKeys: [nextNode?.key],
                      projectType
                    }
                  });
                }}
              />
            </Tooltip>
          </Space>
          <Form
            form={form}
            initialValues={contentState.content}
            onFieldsChange={handleChangedFields}
            onFinish={values => sendForm(contentState, values)}
          >
            <Form.Item name={["contentMeta", "title"]} label={localizer.resolve("Content.title.label")}>
              <Input className="content-font" disabled={isDisabled} />
            </Form.Item>
            {outlineManager.isContentNode(getOrFail(contentState.content.contentMeta.type)) ? (
              <SlateEditor value={contentRef.current} onChange={handleChangedContent} disabled={isDisabled} />
            ) : (
              <Paragraph type="secondary">
                {localizer.resolve("Content.help.addText", {
                  containerName: localizer.resolve(`Content.help.containerName.${contentState.content.contentMeta.type}`),
                  textblockName: localizer.resolve(`Content.help.childblockNames.${contentState.content.contentMeta.type}`)
                })}
              </Paragraph>
            )}
            <Collapse
              bordered={false}
              {...(!outlineManager.isContentNode(getOrFail(contentState.content.contentMeta.type))
                ? { defaultActiveKey: ["1"] }
                : {})}
            >
              <Panel header={localizer.resolve("Content.notes.label")} key="1">
                <Form.Item name="notes">
                  <Input.TextArea rows={5} className="content-font" disabled={isDisabled} />
                </Form.Item>
              </Panel>
            </Collapse>
            <ImportFragmentDialog
              outlineManager={outlineManager}
              projectId={projectId}
              fragments={fragments}
              showSelectFragmentDialog={showSelectFragmentDialog}
              setShowSelectFragmentDialog={setShowSelectFragmentDialog}
              importCommandRef={importCommandRef}
            />
          </Form>
        </div>
      )}
      {isLoading && <Spin />}
    </>
  );
}

export default ContentPane;
