import React, { useState, useEffect, useContext } from "react";
import { Spin, Typography, Button, Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import StateContext, { getStateContext } from "../../StateContext";
import { Entity, getDao } from "../../dao/EntityDao";
import { localizer } from "di-common";
import { displayField } from "../general/FieldFormatterUtil";
import RelationForm from "./RelationForm";
import ParticipantsList from "./participants/ParticipantsList";
import {
  RELATION,
  SINGLE_VIEW,
  EDIT_VIEW,
  ViewTypes
} from "../entity/EntityConstants";

const { Title } = Typography;

/* The order in which to display the fields of a relation object */
const fieldsInOrder = [
  "age",
  "startDate",
  "roleInStory",
  "history",
  "development"
];

type RelationDetailsProps = {
  isDisabled?: boolean;
  relationId?: string;
  mode: ViewTypes;
  setMode: React.Dispatch<React.SetStateAction<ViewTypes>>;
};

function RelationDetails({
  isDisabled = false,
  relationId,
  mode,
  setMode
}: RelationDetailsProps) {
  const relationDao = getDao(RELATION);
  const [isLoading, setLoading] = useState(relationId !== null);
  const [relation, setRelation] = useState<Entity | Partial<Entity>>({});
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  useEffect(() => {
    if (relationId && currentUser) {
      setLoading(true);
      relationDao.loadEntity(
        relationId,
        currentUser,
        (response: any) => {
          setLoading(false);
          setRelation(response.data);
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationId]);

  if (isLoading) {
    return <Spin />;
  }

  return mode === SINGLE_VIEW ? (
    <Typography>
      <Title level={4}>
        {relation.name}
        <Tooltip title={localizer.resolve("Global.buttonCaption.edit")}>
          <Button
            disabled={isDisabled}
            size="large"
            type="link"
            icon={<EditOutlined />}
            onClick={() => setMode(EDIT_VIEW)}
          />
        </Tooltip>
      </Title>
      {fieldsInOrder.map(fieldName =>
        displayField("Relation", fieldName, relation)
      )}
      <ParticipantsList editable={false} participants={[]} ignoreList={[]} />
    </Typography>
  ) : (
    <RelationForm
      relation={relation as Entity}
      setRelation={setRelation}
      setMode={setMode}
    />
  );
}

export default RelationDetails;
