import React, { useEffect, useState, useContext } from "react";
import fragmentDao, { FragmentExcerpt } from "../../dao/FragmentDao";
import StateContext, { getStateContext } from "../../StateContext";
import { Checkbox, Form, Modal, Select } from "antd";
import IconFont from "../general/IconFont";
import { localizer } from "di-common";
import ProjectDispatchContext, { getProjectViewDispatchContext } from "./ProjectDispatchContext";
import OutlineManager from "../../utils/OutlineManager";
import { ImportCommand } from "./ContentPane";
import { StructuralTypeName } from "../../dao/ProjectDao";
import { ConsoleLogger } from "di-common";

type ImportFragmentDialogProps = {
  outlineManager: OutlineManager;
  projectId?: string;
  fragments: FragmentExcerpt[];
  showSelectFragmentDialog: boolean;
  setShowSelectFragmentDialog: React.Dispatch<React.SetStateAction<boolean>>;
  importCommandRef: React.MutableRefObject<ImportCommand | undefined>;
};

const logger = new ConsoleLogger("ImportFragmentDialog");

/**
 * This component creates menu items suitable for a dropdown menu, allowing to import fragments
 * based on the context provided by the displayed content type (outlineManager + contenId)
 * Remark: the list with fragments is loaded for every node, we may need to cache this list in the future
 */
export function ImportFragmentDialog({
  outlineManager,
  projectId,
  fragments,
  showSelectFragmentDialog,
  setShowSelectFragmentDialog,
  importCommandRef
}: ImportFragmentDialogProps) {
  //a form is presented in a modal dialog to select fragment
  const [form] = Form.useForm();
  const [selectedFragmentId, setSelectedFragmentId] = useState(null);
  const [isFragmentLoading, setFragmentLoading] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const { outlineDispatch } = getProjectViewDispatchContext(useContext(ProjectDispatchContext));

  function processSelectedFragment(values: any) {
    if (values.fragmentId) {
      setFragmentLoading(true);
      if (importCommandRef.current) {
        importCommandRef.current.keyPath = ["importFragment"];
        importCommandRef.current.fragmentId = values.fragmentId;
        importCommandRef.current.deleteFragment = values.deleteFragment === true;
      }
      setSelectedFragmentId(values.fragmentId);
    }
  }

  /**
   * Callback function when fragment has been succesfully inported
   */
  function afterImport(command: ImportCommand) {
    if (command.deleteFragment === true) {
      setDeleteCount(deleteCount + 1);
    } else {
      closeDialog();
    }
  }

  function deleteSuccess() {
    logger.info("Succesfully deleted fragment with id " + selectedFragmentId);
    closeDialog();
  }

  function deleteFailed(error: any) {
    console.dir(error);
    closeDialog();
  }

  function closeDialog() {
    setFragmentLoading(false);
    setShowSelectFragmentDialog(false);
  }

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  useEffect(() => {
    // load selected fragment and add it's content to the import command
    if (selectedFragmentId !== null && currentUser) {
      fragmentDao.loadFragment(
        selectedFragmentId,
        currentUser,
        (response: any) => {
          if (!importCommandRef.current) {
            throw new Error("No ImportCommand available");
          }
          importCommandRef.current.title = response.data.title;
          importCommandRef.current.content = response.data.fragment;
          importCommandRef.current.afterImport = afterImport; //set callback function back into ImportFragmentItem
          outlineDispatch({
            type: "importFragment",
            data: {
              importCommand: importCommandRef.current,
              projectType: outlineManager.getProjectType(),
              projectId
            }
          });
        },
        console.error
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFragmentId]);

  useEffect(() => {
    if (deleteCount > 0 && selectedFragmentId && currentUser) {
      fragmentDao.deleteFragment(selectedFragmentId, currentUser, deleteSuccess, deleteFailed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCount]);

  if (fragments.length === 0) {
    return null;
  }

  const { Option } = Select;
  return (
    <Modal
      title={localizer.resolve("ImportFragmentItem.modalTitle")}
      open={showSelectFragmentDialog}
      okText={localizer.resolve("Global.buttonCaption.select")}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={form.submit}
      confirmLoading={isFragmentLoading}
      onCancel={() => setShowSelectFragmentDialog(false)}
      destroyOnClose={true}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={processSelectedFragment}
        initialValues={{ fragmentId: null, deleteFragment: true }}
      >
        <Form.Item label={localizer.resolve("ImportFragmentItem.fragmentTitle")} name="fragmentId">
          <Select>
            {fragments.map(fragment => (
              <Option key={fragment.id} value={fragment.id} title={fragment.fragmentExcerpt}>
                {fragment.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="deleteFragment" valuePropName="checked">
          <Checkbox defaultChecked>{localizer.resolve("ImportFragmentItem.deleteAfterUse")}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function getImportFragmentSubmenu(
  outlineManager: OutlineManager,
  contentId: string,
  currentNodeType: StructuralTypeName,
  fragmentsCount: number
) {
  if (fragmentsCount === 0) {
    return null;
  }

  return [
    ...outlineManager
      .getAllowedSiblingTypes(contentId)
      .filter(nodeType => outlineManager.isContentNode(nodeType))
      .map(nodeType => {
        return {
          key: `fragmentS${nodeType}`,
          icon: <IconFont type="icon-import_fragment" />,
          label: localizer.resolve("projectview.button.importFragment"),
          children: [
            {
              key: "before",
              label: localizer.resolve("projectview.button.before" + nodeType)
            },
            {
              key: "after",
              label: localizer.resolve("projectview.button.after" + nodeType)
            }
          ]
        };
      }),
    ...outlineManager
      .getAllowedChildTypes(currentNodeType)
      .filter(nodeType => outlineManager.isContentNode(nodeType))
      .map(nodeType => {
        return {
          key: `fragmentC${nodeType}`,
          icon: <IconFont type="icon-import_fragment" />,
          label: localizer.resolve("projectview.button.importFragment")
        };
      })
  ];
}
