/* We use the ant.design layout system, which uses a system with 24 columns.
 * The application has several detail views (a.o. for ideas, projects and fragments) and card views. On a large screen,
 * the top level views on details can be split in is split in three
 * columns, a main pane which takes half of the screen (12 columns) and two side panes (left and right), both 6 columns wide.
 * When the window gets smaller
 */

/* Breakpoints (see https://ant.design/components/grid/#Col):

  xs -> screen < 576px
  sm -> screen ≥ 576px
  md -> screen ≥ 768px
  lg -> screen ≥ 992px
  xl -> screen ≥ 1200px
 xxl -> screen ≥ 1600px

*/

export const fragmentMainCols = Object.freeze({
  span: 18,
  xs: 24,
  sm: 24,
  md: 24,
  lg: 18,
  xl: 18,
  xxl: 18
});

export const fragmentFormLayout = {
  labelCol: {
    span: 6,
    xs: 6,
    sm: 6,
    md: 6,
    lg: 4,
    xl: 4,
    xxl: 3
  },
  wrapperCol: {
    span: 12,
    xs: 18,
    sm: 18,
    md: 18,
    lg: 16,
    xl: 14,
    xxl: 13
  }
};

/** Layout when form.item has no label */
export const fragmentTailLayout = {
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      offset: 6
    },
    lg: {
      offset: 4,
      span: 16
    },
    xl: {
      offset: 4,
      span: 14
    },
    xxl: {
      offset: 3,
      span: 13
    }
  }
};

/** Layout for forms in a content-box */
export const contentboxFormLayout = {
  labelCol: {
    span: 6,
    xs: 6,
    sm: 6,
    md: 6,
    lg: 5,
    xl: 5,
    xxl: 4
  },
  wrapperCol: {
    span: 18,
    xs: 18,
    sm: 18,
    md: 18,
    lg: 19,
    xl: 17,
    xxl: 16
  }
};

/** Layout for forms in a content-box when form.item has no label */
export const contentboxTailLayout = {
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      offset: 6
    },
    lg: {
      offset: 5,
      span: 19
    },
    xl: {
      offset: 5,
      span: 17
    },
    xxl: {
      offset: 4,
      span: 16
    }
  }
};
