import Axios from "axios";
import { User } from "../StateContext";
import { OutlineNode } from "./OutlineManager";
import { Content } from "../dao/ProjectDao";

/**
 * Provide access to the content from the server as denoted by the provided hierarchical content meta.
 */
export default class RemoteContentCollection {
  private user: User;
  private contentKeys: string[];

  constructor(user: User | null, treeData: OutlineNode[], nodePathDescriptor?: string) {
    if (!user) {
      throw new Error("No User object present; are you logged in?");
    }
    this.user = user;
    this.contentKeys = flatten(treeData, nodePathDescriptor);
  }

  /** Retrieve and collect all content in the project, optionally applying the chapter filter that is set in the constructor */
  public async getContentCollection() {
    return Promise.all(this.contentKeys.map(contentKey => loadContent(this.user, contentKey)));
  }
}

async function loadContent(user: User, contentId: string) {
  //TODO: allow canceling / respond to network errors
  const response = await Axios.get<Content>(`/api/private/content/${user.id}/${contentId}`, {
    auth: {
      username: user.username,
      password: user.passwordHash
    }
  });
  return response.data;
}

/**
 * Collect all database identifiers for the nodes that match the given nodePathDescriptor. If no
 * nodePathDescriptor is given, the identifiers of all nodes in the project are collected in the pro
 */
export function flatten(nodes: OutlineNode[], nodePathDescriptor?: string): string[] {
  if (!nodes) {
    return [];
  }

  let flatContentKeys: string[] = [];
  for (const contentMeta of nodes) {
    if (nodePathDescriptor == null || contentMeta.pathDescriptor.startsWith(nodePathDescriptor)) {
      if (contentMeta.key) {
        flatContentKeys.push(contentMeta.key);
      }
      if (contentMeta.children) {
        flatContentKeys = flatContentKeys.concat(flatten(contentMeta.children));
      }
    }
  }
  return flatContentKeys;
}
