import React, { useContext } from "react";
import { Button, Dropdown, Tooltip } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { localizer } from "di-common";
import { getProjectViewDispatchContext } from "./ProjectDispatchContext";
import docxExportService, { CoverImageConfig } from "../../services/DocXExportService";
import RemoteContentCollection from "../../utils/RemoteContentCollection";
import ProjectDispatchContext from "./ProjectDispatchContext";
import ProjectMetaForm from "./ProjectMetaForm";
import StateContext, { getStateContext } from "../../StateContext";
import rfdc from "rfdc";
import { ProjectMeta } from "../../dao/ProjectDao";
import { useParams } from "react-router-dom";
import { RemoteStoryElementsCollection } from "../../utils/RemoteStoryElementsCollection";
import RemotePlotCollection from "../../utils/RemotePlotCollection";

const deepClone = rfdc();

type ProjectMenuOptions = {
  isStoryProject: boolean;
};

function ProjectMenu({ isStoryProject }: ProjectMenuOptions) {
  const { projectId } = useParams();
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  const { outlineState, projectMetaState, setModalComponent } = getProjectViewDispatchContext(useContext(ProjectDispatchContext));

  function handleProjectMenuClick(data: { key: string }) {
    const { key } = data;
    switch (key) {
      case "exportManuscript":
        {
          //TODO: rewrite this code so we only load one content item in memory at a time (async problems)
          if (!projectMetaState.projectMeta) {
            throw new Error("No ProjectMeta available in the ProjectViewDispatchContext");
          }
          const projectMeta: ProjectMeta = projectMetaState.projectMeta;
          const coverImageConfig: CoverImageConfig | undefined =
            projectMeta.coverUrl && currentUser
              ? {
                  coverUrl: projectMeta.coverUrl,
                  currentUser
                }
              : undefined;
          if (projectMeta.coverUrl) {
          }
          const iterableContent = new RemoteContentCollection(currentUser, outlineState.outlineData);
          iterableContent
            .getContentCollection()
            .then(collection =>
              docxExportService.processProject(projectMeta.projectType, projectMeta.workTitle, collection, false, coverImageConfig)
            )
            .catch(console.error);
        }
        break;

      /**
       * Export the story elements per type in the specified type order. Within a type,
       * they will be ordered by name
       */
      case "exportStoryElements":
        //collect all story elements from the server
        if (currentUser && projectId && projectMetaState.projectMeta) {
          const projectMeta = projectMetaState.projectMeta;
          const storyElementsCollector = new RemoteStoryElementsCollection(currentUser, projectId);
          storyElementsCollector
            .getStoryElementsByType()
            .then(data => {
              // data is returned as array of tuples; Map didn't work for unknown reasons :-(
              docxExportService.processStoryElements(currentUser, projectMeta.workTitle, data);
            })
            .catch(console.error);
        }
        break;
      case "exportPlot":
        if (currentUser && projectId && projectMetaState.projectMeta) {
          const projectMeta = projectMetaState.projectMeta;
          const plotitemCollector = new RemotePlotCollection(currentUser, projectId);
          plotitemCollector.getOutlineAndPlotlines().then(data => {
            docxExportService.processPlot(projectMeta.workTitle, data);
          });
        }
        break;
      case "meta":
        if (!projectMetaState.projectMeta) {
          throw new Error("No ProjectMeta available in the ProjectViewDispatchContext");
        }
        setModalComponent(<ProjectMetaForm projectMeta={deepClone(projectMetaState.projectMeta)} />);
        break;
      default:
        console.error(`Unexpected key: ${key}`);
        break;
    }
  }

  const menu = {
    onClick: handleProjectMenuClick,
    items: [
      {
        key: "export",
        label: localizer.resolve("projectview.menu.export"),
        children: [
          {
            key: "exportManuscript",
            label: localizer.resolve("projectview.menu.manuscript")
          },
          //conditionally add option to export story elements and plot (E.g. not available for poetry)
          ...(isStoryProject
            ? [
                {
                  key: "exportStoryElements",
                  label: localizer.resolve("projectview.menu.storyElements")
                },
                {
                  key: "exportPlot",
                  label: localizer.resolve("projectview.menu.plot")
                }
              ]
            : [])
        ]
      },
      {
        key: "maintain",
        label: localizer.resolve("projectview.menu.maintain"),
        children: [
          { key: "meta", label: localizer.resolve("projectview.menu.meta") }
          // ,{
          //   key: "settings",
          //   label: localizer.resolve("projectview.menu.settings")
          // }
        ]
      }
    ]
  };

  return (
    <Dropdown trigger={["click"]} menu={menu}>
      <Tooltip title={localizer.resolve("projectview.menu.tooltip")}>
        <Button type="text" icon={<MoreOutlined />} />
      </Tooltip>
    </Dropdown>
  );
}

export default ProjectMenu;
