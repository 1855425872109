import Dinero from "dinero.js";
import { DateTime } from "luxon";

export const flexFee = Dinero({ amount: 695, currency: "EUR" });
export const monthlyFee = Dinero({ amount: 595, currency: "EUR" });
export const quarterlyFee = Dinero({ amount: 1650, currency: "EUR" });
export const annualFee = Dinero({ amount: 5995, currency: "EUR" });

export function calculateFlexFee(startDate: DateTime, endDate: DateTime) {
  //this code must be closely in sync with the serverside implementation
  const diff = endDate.diff(startDate, ["months", "days"]).toObject();
  const months = diff.months ? diff.months : 0;
  const days = diff.days ? diff.days : 0;
  return flexFee.multiply(months).add(flexFee.multiply(days / 30));
}
