import { Coordinates } from "../../dao/OutlineDao";
import { ConsoleLogger } from "di-common";

const logger = new ConsoleLogger("GridHelper");

const boxHeight = 100;
const boxWidth = 200;
const gap = 20;

/**
 * Translate grid cell coordinates to screen coordinates relative to PlotBoard for a PlotCard.
 * The screen coordinates are at the top left corner of the cell.
 */
export function calculateCardPosition(cell: Coordinates): Coordinates {
  const pixelX = cell.x * boxWidth + cell.x * gap;
  const pixelY = gap + cell.y * boxHeight + cell.y * gap;
  return { x: pixelX, y: pixelY };
}

/**
 * Translate grid column number to spixel coordinate relative to PlotBoard for a vertical axis.
 * A vertical axis is a line drawn from top to bottom straight through the middle of a grid column.
 * @returns The left pixel coordinate of the axis (top is assumed to be at 0)
 */
export function calculateYAxisPosition(columnNumber: number): number {
  // the line drawn is 4px width, therefore, we substract 2 (half the width) to get the center position of the column
  return Math.round(columnNumber * boxWidth + columnNumber * gap + 0.5 * boxWidth - 2);
}

export function calculateTitlePosition(columnNumber: number): number {
  return columnNumber * boxWidth + columnNumber * gap;
}

/**
 * Take a mouse event and calculate which grid cell the client pixel coordinates are bound to
 */
export function calculateGridCell(event: React.MouseEvent<HTMLDivElement, MouseEvent>): Coordinates {
  const rect: DOMRect = event.currentTarget.getBoundingClientRect();
  const clientX = event.clientX - rect.left;
  const clientY = event.clientY - rect.top;

  logger.debug(`Clicked on pixel (${clientX}, ${clientY})`);
  const gridX = Math.round(clientX / (boxWidth + gap) - 0.5);
  const gridY = Math.round(clientY / (boxHeight + gap) - 0.5);
  logger.debug(`Clicked inside grid cell (${gridX}, ${gridY})`);
  if (gridX < 0 || gridY < 0) {
    throw new Error(`click coordinates (${clientX}, ${clientY}) are not on grid: cell coordinates (${gridX}, ${gridY})`);
  }
  return { x: gridX, y: gridY };
}

export function calculateGridWidth(columnCount: number): string {
  return columnCount * boxWidth + (columnCount - 1) * gap + "px";
}

export function calculateGridHeight(rowCount: number): string {
  return gap + rowCount * boxHeight + (rowCount - 1) * gap + "px";
}
