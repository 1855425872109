import Axios from "axios";
import { CHARACTER, EntityTypes, EVENT, GROUP, LOCATION, THING } from "../components/entity/EntityConstants";
import { Entity } from "../dao/EntityDao";
import { User } from "../StateContext";

export class RemoteStoryElementsCollection {
  private elementTypes = [CHARACTER, EVENT, GROUP, LOCATION, THING];
  constructor(private currentUser: User, private projectId: string) {}

  public async getStoryElementsByType() {
    const clonedTypes = [...this.elementTypes];
    return Promise.all(clonedTypes.map(typeName => loadStoryElements(this.currentUser, this.projectId, typeName)));
  }
}

async function loadStoryElements(currentUser: User, projectId: string, entityType: EntityTypes): Promise<[EntityTypes, Entity[]]> {
  const response = await Axios.get<Entity[]>(`/api/private/${entityType.toLowerCase()}s/full/${currentUser.id}/${projectId}`, {
    auth: {
      username: currentUser.username,
      password: currentUser.passwordHash
    }
  });
  return [entityType, response.data];
}
