import React, { useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import DeleteResourceAction from "../general/DeleteResourceAction";
import { useImmerReducer } from "use-immer";
import { v4 as uuidv4 } from "uuid";
import { Card, Form, Input, message, Modal, Spin, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Axios from "axios";
import ProtectedImage from "../general/ProtectedImage";
import { ProjectMeta } from "../../dao/ProjectDao";
import { GlobalAction } from "../..";

type IdeaBookState = {
  isLoading: boolean;
  showNewIdeaDialog: boolean;
  newIdeaTitle?: string;
  isCreating: boolean;
  creationFailed: boolean;
  ideas: ProjectMeta[];
};

function Ideabook({ isDisabled = false }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const initialState: IdeaBookState = {
    isLoading: true,
    showNewIdeaDialog: false,
    newIdeaTitle: undefined,
    isCreating: false,
    creationFailed: false,
    ideas: []
  };

  function localReducer(draft: IdeaBookState, action: GlobalAction) {
    switch (action.type) {
      case "ideasLoaded":
        draft.ideas = action.data;
        draft.isLoading = false;
        break;
      case "loadingFailed":
        console.error(action.data);
        draft.isLoading = false;
        break;
      case "newDialog":
        draft.showNewIdeaDialog = true;
        break;
      case "createIdea":
        draft.newIdeaTitle = action.data.workTitle;
        draft.isCreating = true;
        break;
      case "cancelNewIdea":
        draft.showNewIdeaDialog = false;
        break;
      case "ideaCreated":
        draft.isCreating = false;
        draft.showNewIdeaDialog = false;
        draft.creationFailed = false;
        navigate(`/idea/${action.data}`);
        break;
      case "failedCreatingIdea":
        draft.isCreating = false;
        draft.showNewIdeaDialog = false;
        draft.creationFailed = true;
        break;
      case "removeIdeaSuccess":
        draft.ideas = action.data; //we receive back current list of ideas
        break;
      default:
        console.error(`Unexpected action type: ${action.type}`);
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(localReducer, initialState);

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  useEffect(() => {
    //Load the list of current ideas for display
    if (currentUser) {
      Axios.get("/api/private/ideas/" + currentUser.id, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response => dispatch({ type: "ideasLoaded", data: response.data }),
        error => dispatch({ type: "loadingFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.isCreating && currentUser) {
      //Create a new idea on the server - the server responds with the UUID of the newly created idea
      Axios.post(
        "/api/private/ideas/" + currentUser.id,
        { id: uuidv4(), workTitle: state.newIdeaTitle },
        {
          auth: {
            username: currentUser.username,
            password: currentUser.passwordHash
          }
        }
      ).then(
        response => dispatch({ type: "ideaCreated", data: response.data }),
        error => dispatch({ type: "failedCreatingIdea", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCreating, state.newIdeaTitle]);

  if (state.isLoading) {
    return <Spin size="large" />;
  }

  const { Meta } = Card;
  const { Text } = Typography;
  return (
    <>
      <span>
        <Link to="/">
          &laquo; {localizer.resolve("Global.linkText.backToDashboard")}
        </Link>
      </span>
      <main className="dashboardview">
        <Card
          className="projectcard"
          hoverable={!state.showNewIdeaDialog && !isDisabled}
          onClick={
            isDisabled
              ? () =>
                  message.info(
                    localizer.resolve(
                      `Global.message.${currentUser?.reasonBecomingInvalid}`
                    )
                  )
              : () => dispatch({ type: "newDialog" })
          }
          cover={
            <img
              style={{ objectFit: "scale-down", height: "280px" }}
              alt=""
              src="images/idea.svg"
            />
          }
          actions={[<PlusOutlined key="add" />]}
        >
          <Meta title={localizer.resolve("Ideabook.addTitle")} />
        </Card>
        {state.ideas.map(idea => {
          return (
            <Card
              key={idea.id}
              className="projectcard"
              onClick={() => navigate(`/idea/${idea.id}`)}
              hoverable
              cover={
                <ProtectedImage
                  style={{ objectFit: "scale-down", height: "280px" }}
                  alt=""
                  src={idea.coverUrl ? idea.coverUrl : "images/add-image.svg"}
                />
              }
              actions={[
                <DeleteResourceAction
                  title={localizer.resolve("Idea.deleteConfirmation")}
                  urlResourceName="ideas"
                  resourceId={idea.id}
                  dispatcher={dispatch}
                  actionType="removeIdeaSuccess"
                />
              ]}
            >
              <Meta
                title={
                  <Text
                    style={{ width: "100%" }}
                    ellipsis={{
                      tooltip: idea.workTitle
                    }}
                  >
                    {idea.workTitle}
                  </Text>
                }
              />
            </Card>
          );
        })}
      </main>
      <Modal
        title={localizer.resolve("Ideabook.addTitle")}
        open={state.showNewIdeaDialog}
        okText={localizer.resolve("Global.buttonCaption.create")}
        cancelText={localizer.resolve("Global.buttonCaption.cancel")}
        onOk={() => form.submit()}
        confirmLoading={state.isCreating}
        onCancel={() => dispatch({ type: "cancelNewIdea" })}
        destroyOnClose={true}
      >
        <Form
          form={form}
          preserve={false}
          initialValues={{ workTitle: "" }}
          onFinish={values => dispatch({ type: "createIdea", data: values })}
        >
          <Form.Item
            label={localizer.resolve("ProjectMeta.workTitle.label")}
            name="workTitle"
            rules={[
              {
                max: 254,
                message: localizer.resolve(
                  "ProjectMeta.workTitle.message.maxLength"
                )
              },
              {
                required: true,
                message: localizer.resolve(
                  "ProjectMeta.workTitle.message.mandatory"
                )
              }
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Ideabook;
