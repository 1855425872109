import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Button, Card, Popconfirm, Spin, Tooltip } from "antd";
import ProtectedAvatar from "../general/ProtectedAvatar";
import { UserOutlined, DeleteOutlined } from "@ant-design/icons";
import StateContext, { getStateContext } from "../../StateContext";
import { Entity, getDao } from "../../dao/EntityDao";
import { RELATION } from "../entity/EntityConstants";
import { localizer } from "di-common";

type RelationListProps = {
  isDisabled?: boolean;
  onSelectRelation: (relationId: string) => void;
};

function RelationList({
  isDisabled = false,
  onSelectRelation
}: RelationListProps) {
  const relationDao = getDao(RELATION);
  const { projectId } = useParams();
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  const [loading, setLoading] = useState(true);
  const [relations, setRelations] = useState<Entity[]>([]);
  const [relationToDelete, setRelationToDelete] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (projectId && currentUser) {
      setLoading(true);
      relationDao.loadEntityList(
        projectId,
        currentUser,
        (response: any) => {
          setLoading(false);
          setRelations(response.data);
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (relationToDelete && projectId && currentUser) {
      relationDao.deleteEntity(
        projectId,
        relationToDelete,
        currentUser,
        (response: any) => {
          if (response.data === true) {
            setRelations(removeRelationFromList(relationToDelete));
          }
          setRelationToDelete(undefined);
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationToDelete]);

  function removeRelationFromList(identifier: string) {
    return relations.filter(relation => relation.id !== identifier);
  }

  if (loading) {
    return <Spin size="large" />;
  }

  if (relations && relations.length === 0) {
    return <span>{localizer.resolve("Global.message.noRelations")}</span>;
  }
  const { Meta } = Card;
  return (
    <>
      {relations.map(relation => (
        <Card
          className="projectcard"
          key={relation.id}
          hoverable
          onClick={() => onSelectRelation(relation.id)}
          loading={relationToDelete === relation.id}
        >
          <Meta
            avatar={
              relation.avatarUrl ? (
                <ProtectedAvatar src={relation.avatarUrl} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )
            }
            title={
              <>
                {relation.name}{" "}
                <Popconfirm
                  title={localizer.resolve("Relation.deleteConfirmation", {
                    name: relation.name
                  })}
                  onConfirm={e => {
                    e?.stopPropagation();
                    setRelationToDelete(relation.id);
                  }}
                  onCancel={e => e?.stopPropagation()}
                  okText={localizer.resolve(
                    "Global.buttonCaption.confirmDeletion"
                  )}
                  cancelText={localizer.resolve("Global.buttonCaption.no")}
                >
                  <Tooltip
                    title={localizer.resolve(
                      "RelationPane.tooltip.deleteRelation",
                      {
                        name: relation.name
                      }
                    )}
                  >
                    <Button
                      disabled={isDisabled}
                      type="link"
                      icon={
                        <DeleteOutlined
                          onClick={e => {
                            e.stopPropagation();
                          }}
                          spin={relationToDelete === relation.id}
                          key="delete"
                        />
                      }
                    />
                  </Tooltip>
                </Popconfirm>
              </>
            }
            description="We should display relation avatars here?"
          />
        </Card>
      ))}
    </>
  );
}

export default RelationList;
