import React, { useContext, useEffect } from "react";
import { localizer } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import { Card, Form, Input, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import ProjectTypeFormItem from "../projects/ProjectTypeFormItem";
import ProjectLanguageFormItem from "../projects/ProjectLanguageFormItem";
import { ProjectMeta } from "../../dao/ProjectDao";
import { GlobalAction } from "../..";
import { ConsoleLogger } from "di-common";

const logger = new ConsoleLogger("NewProjectCard");

export type NewProjectCardState = {
  showNewProjectDialog: boolean;
  isCreating: boolean;
  creationFailed: boolean;
  projectMeta?: ProjectMeta;
};

/* This component is responsible to display a card to collect data and create a new Project in
 * the backend. After creation, it switches to the window to start writing.
 */
function NewProjectCard({ isDisabled = false }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  function localReducer(draft: NewProjectCardState, action: GlobalAction): void {
    logger.debug("ContentReducer: processing action :", action.type);
    switch (action.type) {
      case "newProjectDialog":
        draft.showNewProjectDialog = true;
        break;
      case "createProject":
        draft.projectMeta = action.data;
        draft.isCreating = true;
        break;
      case "cancelNewProject":
        draft.showNewProjectDialog = false;
        break;
      case "projectCreated":
        draft.isCreating = false;
        draft.showNewProjectDialog = false;
        draft.creationFailed = false;
        navigate(`/projects/${action.data}`);
        break;
      case "failedCreatingProject":
        draft.isCreating = false;
        draft.showNewProjectDialog = false;
        draft.creationFailed = true;
        break;
      default:
        logger.info(`NewProjectCard: Unexpected action type: ${action.type}`);
        break;
    }
  }

  const [state, dispatch] = useImmerReducer<NewProjectCardState, GlobalAction>(localReducer, {
    showNewProjectDialog: false,
    isCreating: false,
    creationFailed: false,
    projectMeta: undefined
  });

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  useEffect(() => {
    if (state.isCreating && currentUser) {
      //Create a new project on the server - the server responds with the UUID of the newly created project
      Axios.post(
        "/api/private/projects/" + currentUser.id,
        { ...state.projectMeta, id: uuidv4() },
        {
          auth: {
            username: currentUser.username,
            password: currentUser.passwordHash
          }
        }
      ).then(
        response => dispatch({ type: "projectCreated", data: response.data }),
        error => dispatch({ type: "failedCreatingProject", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isCreating]);

  const { Meta } = Card;
  return (
    <>
      <Card
        className="projectcard"
        hoverable={!isDisabled}
        onClick={
          isDisabled
            ? () => message.info(localizer.resolve(`Global.message.${currentUser?.reasonBecomingInvalid}`))
            : () => dispatch({ type: "newProjectDialog" })
        }
        cover={<img alt="" src="images/book.svg" />}
        actions={[<PlusOutlined key="add" />]}
      >
        <Meta
          title={localizer.resolve("Dashboard.addProject")}
          description={localizer.resolve("Dashboard.newProjectLongDescription")}
        />
      </Card>
      <Modal
        title={localizer.resolve("Dashboard.addProject")}
        open={state.showNewProjectDialog}
        okText={localizer.resolve("Global.buttonCaption.create")}
        cancelText={localizer.resolve("Global.buttonCaption.cancel")}
        onOk={form.submit}
        confirmLoading={state.isCreating}
        onCancel={() => dispatch({ type: "cancelNewProject" })}
        destroyOnClose={true}
      >
        <Form
          form={form}
          preserve={false}
          initialValues={{ language: localizer.getCurrentLocale() }}
          onFinish={values => {
            dispatch({ type: "createProject", data: values });
          }}
        >
          <Form.Item
            label={localizer.resolve("ProjectMeta.workTitle.label")}
            name="workTitle"
            rules={[
              {
                max: 254,
                message: localizer.resolve("ProjectMeta.workTitle.message.maxLength")
              },
              {
                required: true,
                message: localizer.resolve("ProjectMeta.workTitle.message.mandatory")
              }
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
          <ProjectLanguageFormItem />
          <ProjectTypeFormItem />
          <Form.Item
            label={localizer.resolve("ProjectMeta.genre.label")}
            name="genre"
            rules={[
              {
                max: 254,
                message: localizer.resolve("ProjectMeta.genre.message.maxLength")
              }
            ]}
          >
            <Input maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default NewProjectCard;
