import React, { ReactElement, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DispatchContext, { getDispatchContext } from "../../DispatchContext";
import StateContext, { getStateContext } from "../../StateContext";
import { localizer } from "di-common";
import UserSettingsForm from "./UserSettingsForm";
import ChangePasswordForm from "./ChangePasswordForm";
import SubscriptionPanel from "./SubscriptionPanel";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { DownOutlined, LockOutlined, PoweroffOutlined, SettingOutlined } from "@ant-design/icons";
import IconFont from "../general/IconFont";
import Icon from "@ant-design/icons/lib/components/Icon";

function HeaderMenu({ isLocked = false }) {
  const navigate = useNavigate();
  const { dispatch: globalDispatch } = getDispatchContext(useContext(DispatchContext));
  const { state: globalState } = getStateContext(useContext(StateContext));
  const [modalComponent, setModalComponent] = useState<ReactElement<any, any> | null>(null);

  function getDisplayName(): string {
    let displayName: string;
    if (globalState.currentUser && globalState.currentUser.fullName) {
      displayName = globalState.currentUser.fullName;
    } else {
      displayName = localizer.resolve("Global.noName");
    }
    return displayName;
  }

  const menu: MenuProps = {
    mode: "vertical",
    items: [
      {
        key: 1,
        icon: <SettingOutlined />,
        label: localizer.resolve("Global.menu.user.preferences"),
        onClick: () => setModalComponent(<UserSettingsForm setModalComponent={setModalComponent} />)
      }
    ]
  };
  if (globalState.needsAuthentication && globalState.currentUser) {
    menu.items?.push({
      key: 2,
      icon: <IconFont type="icon-password" />,
      label: localizer.resolve("Global.menu.user.password"),
      onClick: () =>
        setModalComponent(<ChangePasswordForm setModalComponent={setModalComponent} currentUser={globalState.currentUser!} />)
    });
    menu.items?.push({
      key: 3,
      icon: <Icon type="none" />, //no-icon -- what value should it be?
      label: localizer.resolve("Global.menu.user.subscription"),
      onClick: () =>
        setModalComponent(
          <SubscriptionPanel
            setModalComponent={setModalComponent}
            currentUser={globalState.currentUser!}
            globalDispatch={globalDispatch}
          />
        )
    });
  }

  return (
    <>
      <div className="header__actions">
        <Dropdown menu={menu} trigger={["click"]}>
          <Button type="link" onClick={e => e.preventDefault()}>
            {isLocked && (
              <Tooltip title={localizer.resolve(`Global.message.${globalState.currentUser?.reasonBecomingInvalid}`)}>
                <LockOutlined />
              </Tooltip>
            )}{" "}
            {getDisplayName()} <DownOutlined />
          </Button>
        </Dropdown>
        {globalState.needsAuthentication && (
          <Button
            type="link"
            icon={<PoweroffOutlined />}
            onClick={() => {
              navigate("/");
              globalDispatch({ type: "userLogout" });
            }}
          >
            {localizer.resolve("Logout.submitCaption")}
          </Button>
        )}
      </div>
      {modalComponent !== null && modalComponent}
    </>
  );
}

export default HeaderMenu;
