import React, { createContext } from "react";
import { EntityTypes } from "./EntityConstants";

export type SharedEntityState = {
  singularEntityName: string;
  pluralEntityName: string;
  entityType: EntityTypes;
  avatarIcon: React.JSX.Element;
};

const EntityStateContext = createContext<SharedEntityState | undefined>(undefined);

export function getEntityStateContext(stateContext: SharedEntityState | undefined): SharedEntityState {
  if (!stateContext) {
    throw new Error("SharedEntityState context not defined");
  }
  return stateContext;
}

export default EntityStateContext;
