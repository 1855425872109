import React from "react";
import { useNavigate } from "react-router-dom";
import { localizer } from "di-common";
import { AppInfo } from "../../Constants";
import { Button } from "antd";

type FeedBackProps = {
  feedback: string;
  forwardUrl?: string;
  allowQuestions?: boolean;
};

function Feedback({
  feedback,
  forwardUrl = "/",
  allowQuestions = true
}: FeedBackProps) {
  const navigate = useNavigate();
  return (
    <div className="content-box">
      <div className="content-box__content">
        <p>{feedback}</p>
        <div>
          <Button
            type="primary"
            onClick={() => {
              navigate(forwardUrl);
            }}
          >
            {localizer.resolve("Global.buttonCaption.close")}
          </Button>{" "}
          {allowQuestions && (
            <Button type="default" href={`mailto:${AppInfo.supportMail}`}>
              {localizer.resolve("Global.buttonCaption.question")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Feedback;
