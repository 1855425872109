import React from "react";
import { Select, Form } from "antd";
import { localizer } from "di-common";

function ProjectTypeFormItem() {
  const { Option } = Select;
  return (
    <Form.Item
      label={localizer.resolve("ProjectMeta.projectType.label")}
      name="projectType"
      rules={[
        {
          required: true,
          message: localizer.resolve(
            "ProjectMeta.projectType.message.mandatory"
          )
        }
      ]}
    >
      <Select>
        <Option value="SHORT_STORY">
          {localizer.resolve("ProjectType.SHORT_STORY")}
        </Option>
        <Option value="NOVEL">{localizer.resolve("ProjectType.NOVEL")}</Option>
        <Option value="POETRY_BUNDLE">
          {localizer.resolve("ProjectType.POETRY_BUNDLE")}
        </Option>
      </Select>
    </Form.Item>
  );
}

export default ProjectTypeFormItem;
