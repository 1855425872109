import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Card, Popconfirm, Spin, Tooltip, Typography } from "antd";
import ProtectedAvatar from "../general/ProtectedAvatar";
import Meta from "antd/lib/card/Meta";
import { DeleteOutlined } from "@ant-design/icons";
import StateContext, { getStateContext } from "../../StateContext";
import { capitalizeFirstCharacter, concatIdentity } from "../general/FieldFormatterUtil";
import { Entity, getDao } from "../../dao/EntityDao";
import EntityStateContext, { getEntityStateContext } from "./EntityStateContext";
import { localizer } from "di-common";
import { CHARACTER } from "./EntityConstants";

type EntityListProps = {
  isDisabled: boolean;
  onSelectEntity: (entityId: string) => void;
};

function EntityList({ isDisabled = false, onSelectEntity }: EntityListProps) {
  const { projectId } = useParams();
  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));
  const sharedEntityState = getEntityStateContext(useContext(EntityStateContext));
  const { singularEntityName, entityType, avatarIcon } = sharedEntityState;
  const entityDao = getDao(entityType);
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (projectId && currentUser) {
      setLoading(true);
      entityDao.loadEntityList(
        projectId,
        currentUser,
        (response: any) => {
          setLoading(false);
          setEntities(response.data);
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entityToDelete && projectId && currentUser) {
      entityDao.deleteEntity(
        projectId,
        entityToDelete,
        currentUser,
        (response: any) => {
          if (response.data === true) {
            setEntities(removeEntityFromList(entityToDelete));
          }
          setEntityToDelete(null);
        },
        (error: any) => {
          setLoading(false);
          console.error(error);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityToDelete]);

  function removeEntityFromList(identifier: string) {
    return entities.filter(entity => entity.id !== identifier);
  }

  if (loading) {
    return <Spin size="large" />;
  }

  if (entities && entities.length === 0) {
    return (
      <span>
        {localizer.resolve("Global.message.noEntities", {
          entityName: singularEntityName
        })}
      </span>
    );
  }

  const { Paragraph } = Typography;
  return (
    <>
      {entities.map(entity => (
        <Card
          className="projectcard"
          key={entity.id}
          hoverable
          onClick={() => onSelectEntity(entity.id)}
          loading={entityToDelete === entity.id}
        >
          <Meta
            avatar={entity.avatarUrl ? <ProtectedAvatar src={entity.avatarUrl} /> : <Avatar icon={avatarIcon} />}
            title={
              <>
                {entityType === CHARACTER ? entity && concatIdentity(entity) : entity?.name}{" "}
                <Popconfirm
                  title={capitalizeFirstCharacter(
                    localizer.resolve("Global.title.deleteConfirmation", {
                      entityName: singularEntityName,
                      name: entity.name
                    })
                  )}
                  onConfirm={e => {
                    e?.stopPropagation();
                    setEntityToDelete(entity.id);
                  }}
                  onCancel={e => e?.stopPropagation()}
                  okText={localizer.resolve("Global.buttonCaption.confirmDeletion")}
                  cancelText={localizer.resolve("Global.buttonCaption.no")}
                >
                  <Tooltip
                    title={localizer.resolve("Global.tooltip.deleteEntity", {
                      name: entity.name
                    })}
                  >
                    <DeleteOutlined
                      disabled={isDisabled}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      spin={entityToDelete === entity.id}
                      key="delete"
                    />
                  </Tooltip>
                </Popconfirm>
              </>
            }
            description={
              <Paragraph style={{ width: "100%" }} ellipsis={{ rows: 5 }}>
                {entity.roleInStory}
              </Paragraph>
            }
          />
        </Card>
      ))}
    </>
  );
}

export default EntityList;
