/**
 * This service is responsible to generate a docx file from a collection of content items
 */
import { AlignmentType, Document, ImageRun, ISectionOptions, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { Content } from "../dao/ProjectDao";
import { ProjectTypeName } from "../utils/OutlineManager";
import { ConsoleLogger, localizer } from "di-common";
import { Entity } from "../dao/EntityDao";
import { CHARACTER, displayFieldsByEntityType, EntityTypes } from "../components/entity/EntityConstants";
import { DateTime } from "luxon";
import Axios from "axios";
import { User } from "../StateContext";
import { PlotData } from "../utils/RemotePlotCollection";
import { Outline, PlotItem, PlotLine } from "../dao/OutlineDao";

const logger = new ConsoleLogger("DocXExportService");

export type CoverImageConfig = {
  currentUser: User;
  coverUrl: string;
};

class DocXExportService {
  public processProject(
    projectType: ProjectTypeName,
    title: string,
    iterableContent: Content[],
    surpressTitlePage = false,
    imageConfig?: CoverImageConfig
  ) {
    title = title?.trim() || localizer.resolve("Global.untitled");
    const urls: string[] = imageConfig ? [imageConfig.coverUrl] : [];
    preloadImages(imageConfig?.currentUser, urls).then(preloadedImages => {
      const doc = this.projectToDoc(preloadedImages, projectType, title, iterableContent, surpressTitlePage, imageConfig?.coverUrl);
      Packer.toBlob(doc).then(blob => {
        saveAs(blob, title + ".docx");
        console.log(`Document .docx for ${projectType} named ${title} created succesfully`);
      });
    });
  }

  public processStoryElements(currentUser: User, title: string, entities: [EntityTypes, Entity[]][]) {
    //Generate document containing the story elements, handle in order provided
    const avatarUrls = collectAvatarUrls(entities);
    title = title?.trim() || localizer.resolve("Global.untitled");
    preloadImages(currentUser, avatarUrls).then(preloadedImages => {
      const doc = this.storyElementsToDoc(preloadedImages, title, entities);
      Packer.toBlob(doc).then(blob => {
        saveAs(blob, title + ".story-elements.docx");
        console.log(`Story elements .docx for project named '${title}' created succesfully`);
      });
    });
  }

  public processPlot(title: string, plotData: PlotData) {
    title = title?.trim() || localizer.resolve("Global.untitled");
    const doc = this.plotToDoc(title, plotData);
    Packer.toBlob(doc).then(blob => {
      saveAs(blob, title + ".plot.docx");
      console.log(`Plot .docx for project named '${title}' created succesfully`);
    });
  }

  private plotToDoc(title: string, plotData: PlotData): Document {
    const paragraphsPerSection: Paragraph[][] = [];
    if (plotData) {
      //create section for outline
      let firstSectionParagraphs: Paragraph[] = [createTitleParagraph(title)];
      const outline = plotData[0];
      if (outline) {
        firstSectionParagraphs = firstSectionParagraphs.concat(generateOutline(outline));
      }
      paragraphsPerSection.push(firstSectionParagraphs);

      //Go through remainder of plotline/plotcards, do a section switch for every new plotline
      let plotcardsForPlotLine: Paragraph[] = [];
      for (const item of plotData[1]) {
        if (isPlotLine(item)) {
          if (plotcardsForPlotLine.length > 0) {
            paragraphsPerSection.push(plotcardsForPlotLine);
            plotcardsForPlotLine = [];
          }
          plotcardsForPlotLine = plotcardsForPlotLine.concat(generatePlotline(item));
        } else if (item) {
          plotcardsForPlotLine = plotcardsForPlotLine.concat(generatePlotCard(item));
        }
      }
      if (plotcardsForPlotLine.length > 0) {
        paragraphsPerSection.push(plotcardsForPlotLine);
      }
    }

    const sections: ISectionOptions[] = paragraphsPerSection.map(paragraphs => createSection(paragraphs));
    return createDocument(title, sections);
  }

  private storyElementsToDoc(
    preloadedImages: [string, ArrayBuffer, number, number][],
    title: string,
    entities: [EntityTypes, Entity[]][]
  ): Document {
    // for each type group, create a section, like a chapter. For each type instance create like a scene
    const paragraphsPerSection: Paragraph[][] = [];
    for (let [elementType, storyElements] of entities) {
      let sectionParagraphs: Paragraph[] = [];
      if (storyElements.length > 0) {
        sectionParagraphs.push(createTitleParagraph(localizer.resolve(`Global.entity.${elementType}.plural`), "di-project-title"));

        for (const storyElement of storyElements) {
          sectionParagraphs = sectionParagraphs.concat(generateStoryElement(preloadedImages, storyElement, elementType));
        }
        paragraphsPerSection.push(sectionParagraphs);
      }
    }

    const sections: ISectionOptions[] = paragraphsPerSection.map(paragraphs => createSection(paragraphs));
    return createDocument(title, sections);
  }

  private projectToDoc(
    preloadedImages: [string, ArrayBuffer, number, number][],
    projectType: ProjectTypeName,
    title: string,
    iterableContent: Content[],
    surpressTitlePage: boolean,
    coverUrl?: string
  ): Document {
    verifyIterability(iterableContent);
    logger.info(`Generating .docx for ${projectType} named '${title}'`);

    /* For CHAPTER create section with title in the same section, listing SCENE objects,
     * otherwise, just list the SCENE objects
     * When there are non-SCENE objects, (e.g. CHAPTER or POEM objects), we put the
     * project's title in a separate section, otherwise, the project's title is in the
     * same section as the SCENE objects
     */
    const paragraphsPerSection: Paragraph[][] = [];
    let sectionParagraphs: Paragraph[] = [];
    if (surpressTitlePage === false) {
      sectionParagraphs.push(createTitleParagraph(title));
      if (coverUrl) {
        for (const [url, imageBuffer, width, height] of preloadedImages) {
          if (url === coverUrl) {
            sectionParagraphs.push(addImageParagraph(imageBuffer, width, height, 700, true));
            break;
          }
        }
        //insert pagebreak, so that a titlepage with image is always alone on a page
        if (sectionParagraphs.length > 0) {
          paragraphsPerSection.push(sectionParagraphs);
          sectionParagraphs = [];
        }
      }
    }

    for (const content of iterableContent) {
      if (content.contentMeta.title && content.contentMeta.type !== "SCENE") {
        //Add a section per chapter / poem
        if (sectionParagraphs.length > 0) {
          paragraphsPerSection.push(sectionParagraphs);
          sectionParagraphs = [];
        }
        const chapterParagraph = createTitleParagraph(content.contentMeta.title, "di-chapter-title");
        sectionParagraphs.push(chapterParagraph);
      }

      if (content.content) {
        const paragraphStyle = content.contentMeta.type === "POEM" ? "di-line" : "di-text";
        sectionParagraphs = sectionParagraphs.concat(createParagraphs(content.content, paragraphStyle));
      }
    }
    paragraphsPerSection.push(sectionParagraphs);

    const sections: ISectionOptions[] = paragraphsPerSection.map(paragraphs => createSection(paragraphs));
    return createDocument(title, sections);
  }
}

function generateOutline(outline: Outline): Paragraph[] {
  let paragraphs: Paragraph[] = [];
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("Outline.ideaSummary.label"), outline.ideaSummary));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("Outline.plot.label"), outline.plot));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("Outline.synopsis.label"), outline.synopsis));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("Outline.notes.label"), outline.notes));
  return paragraphs;
}

function generatePlotline(plotLine: PlotLine): Paragraph[] {
  let paragraphs: Paragraph[] = [new Paragraph({ thematicBreak: true })];
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotLine.caption"), plotLine.title));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotLine.summary.label"), plotLine.summary));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotLine.background.label"), plotLine.background));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotLine.notes.label"), plotLine.notes));
  return paragraphs;
}

function generatePlotCard(plotCard: PlotItem): Paragraph[] {
  let paragraphs: Paragraph[] = [new Paragraph({ style: "di-blank" }), ...createParagraphs("###")];
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotItemDetails.title"), plotCard.recap));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotItem.action.label"), plotCard.action));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotItem.motivation.label"), plotCard.motivation));
  paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve("PlotItem.notes.label"), plotCard.notes));
  return paragraphs;
}

function generateStoryElement(
  preloadedImages: [string, ArrayBuffer, number, number][],
  storyElement: Entity,
  elementType: EntityTypes
): Paragraph[] {
  let paragraphs: Paragraph[] = [];
  paragraphs.push(createTitleParagraph(storyElement.name, "di-chapter-title", false));

  // select and output avatar image based on url (images are preloaded in previous stage)
  const tartgetUrl = (storyElement as any)["avatarUrl"];
  if (tartgetUrl) {
    for (const [url, imageBuffer, width, height] of preloadedImages) {
      if (url === tartgetUrl) {
        paragraphs.push(addImageParagraph(imageBuffer, width, height));
        break;
      }
    }
  }

  let outputFieldsInOrder = displayFieldsByEntityType.get(elementType) || [];
  if (elementType === CHARACTER && outputFieldsInOrder) {
    outputFieldsInOrder = ["age", "gender", "nickNames", ...outputFieldsInOrder];
  }

  //output remaining fields in the given order
  outputFieldsInOrder.forEach(fieldName => {
    let fieldValue = (storyElement as any)[fieldName];
    if (fieldValue) {
      const lowercaseFieldName = fieldName.toLowerCase();
      if (lowercaseFieldName.includes("date")) {
        //Localize date values
        fieldValue = DateTime.fromISO(fieldValue).setLocale(localizer.getCurrentLocale()).toLocaleString(DateTime.DATE_FULL);
      } else if (lowercaseFieldName === "age") {
        fieldValue = fieldValue + localizer.resolve("Global.abbreviation.year");
      } else if (elementType === CHARACTER && fieldName === "nickNames" && fieldValue.constructor === Array) {
        fieldValue = fieldValue.join("\n");
      } else if (elementType === CHARACTER && fieldName === "gender") {
        fieldValue = localizer.resolve("Character.gender." + fieldValue);
      }
    }
    try {
      paragraphs = paragraphs.concat(generateFieldParagraphs(localizer.resolve(`${elementType}.${fieldName}.label`), fieldValue));
    } catch (e) {
      console.error(`Error processing ${elementType} / ${fieldName}`, e, storyElement);
    }
  });
  paragraphs.push(new Paragraph({ thematicBreak: true }));
  return paragraphs;
}

function generateFieldParagraphs(heading: string, content?: string) {
  if (!content) {
    return [];
  }
  let paragraphs = createParagraphs(content, "di-line", false);
  paragraphs.unshift(createTitleParagraph(heading, "di-paragraph-title", false));
  return paragraphs;
}

function createSection(paragraphs: Paragraph[]): ISectionOptions {
  return {
    properties: {},
    children: paragraphs
  };
}

function verifyIterability(iterableContent: Content[]) {
  if (!iterableContent) {
    throw new Error("No iterable content provided");
  }

  if (typeof iterableContent[Symbol.iterator] !== "function" || typeof iterableContent[Symbol.iterator]().next !== "function") {
    throw new Error("'Iterable content' does not provide an iterator");
  }
}

function createDocument(title: string, sections: ISectionOptions[]) {
  const doc = new Document({
    title: title,
    sections,
    styles: {
      paragraphStyles: [
        {
          id: "di-line",
          name: "Digital-Ink Line text",
          basedOn: "Normal",
          next: "di-line",
          run: {
            size: "11pt",
            font: "Arial",
            color: "000000"
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
            spacing: {
              line: 300
            }
          }
        },
        {
          id: "di-text",
          name: "Digital-Ink Paragraph text",
          basedOn: "Body text",
          next: "di-text",
          run: {
            size: "11pt",
            font: "Arial",
            color: "000000"
          },
          paragraph: {
            indent: {
              firstLine: 90
            },
            spacing: {
              line: 300
            }
          }
        },
        {
          id: "di-blank",
          name: "Digital-Ink Blank paragraph",
          basedOn: "Normal",
          next: "di-text",
          run: {
            size: "11pt",
            font: "Arial",
            color: "000000"
          },
          paragraph: {}
        },
        {
          id: "di-project-title",
          name: "Digital-Ink Project title",
          basedOn: "Heading1",
          next: "di-text",
          run: {
            size: "24pt",
            font: "Times New Roman",
            color: "000000"
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 270
            }
          }
        },
        {
          id: "di-chapter-title",
          name: "Digital-Ink Chapter title",
          basedOn: "Heading1",
          next: "di-text",
          run: {
            size: "18pt",
            font: "Times New Roman",
            color: "000000"
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
            spacing: {
              before: 90,
              after: 180
            }
          }
        },
        {
          id: "di-paragraph-title",
          name: "Digital-Ink Paragraph title",
          basedOn: "Normal",
          next: "di-text",
          run: {
            size: "14pt",
            font: "Times New Roman",
            color: "000000",
            bold: true
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
            spacing: {
              before: 60
            }
          }
        }
      ]
    }
  });
  return doc;
}

function createParagraphs(content: string, styleName = "di-text", withEmptyLine = true): Paragraph[] {
  const paragraphs = [];
  let isLastParagraphBlank = true;
  const paragraphTexts = content.split("\n");
  for (const paragraphText of paragraphTexts) {
    if (paragraphText.trim().length > 0) {
      paragraphs.push(
        new Paragraph({
          style: styleName,
          children: [new TextRun(paragraphText)]
        })
      );
      isLastParagraphBlank = false;
    } else {
      //remove multiple empty lines
      if (isLastParagraphBlank === false) {
        appendBlankLine(paragraphs);
        isLastParagraphBlank = true;
      }
    }
  }

  if (isLastParagraphBlank === false && withEmptyLine === true) {
    appendBlankLine(paragraphs); //end with an empty line when desired
  }
  return paragraphs;
}

/**
 * Append a blank line to the bottom of the last paragraph in the array of paragraphs.
 * If the collection of paragraphs isempty, do nothing.
 * @param {*} paragraphs an Array with Paragraph objects
 */
function appendBlankLine(paragraphs: Paragraph[]): void {
  if (paragraphs.length > 0) {
    paragraphs.push(new Paragraph({ style: "di-blank" }));
  }
}

function collectAvatarUrls(entities: [EntityTypes, Entity[]][]): string[] {
  // Collect avatarUrls from different entities
  let avatarUrls: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [_, entityItems] of entities) {
    avatarUrls = avatarUrls.concat(entityItems.filter(item => item?.avatarUrl != null).map(item => item.avatarUrl!));
  }
  return avatarUrls;
}

async function preloadImages(currentUser?: User, avatarUrls: string[] = []): Promise<[string, ArrayBuffer, number, number][]> {
  if (currentUser) {
    return Promise.all(avatarUrls.map(url => resolveImageData(currentUser, url)));
  }
  return [];
}

async function resolveImageData(currentUser: User, url: string): Promise<[string, ArrayBuffer, number, number]> {
  const response = await Axios.get<ArrayBuffer>(url, {
    responseType: "arraybuffer",
    auth: {
      username: currentUser.username,
      password: currentUser.passwordHash
    }
  });

  const image = new Image();
  await new Promise(resolve => {
    image.onload = resolve;
    image.src = URL.createObjectURL(new Blob([new Uint8Array(response.data)]));
  });

  return [url, response.data, image.naturalWidth, image.naturalHeight];
}

function addImageParagraph(
  imageData: ArrayBuffer,
  width: number,
  height: number,
  wantedHeight?: number,
  horizontalCenter: boolean = false
) {
  const ratio = width / height;
  const newHeight = wantedHeight ? wantedHeight : 200;
  const newWidth = newHeight * ratio;

  return new Paragraph({
    alignment: horizontalCenter ? AlignmentType.CENTER : AlignmentType.LEFT,
    children: [
      new ImageRun({
        data: imageData,
        transformation: {
          width: newWidth,
          height: newHeight
        }
      })
    ]
  });
}

function createTitleParagraph(title: string, styleName = "di-project-title", withBreak = true): Paragraph {
  return new Paragraph({
    text: title,
    style: styleName,
    pageBreakBefore: withBreak
  });
}

function isPlotLine(value: object): value is PlotLine {
  return value && "columnIndex" in value;
}
const docxExportService = new DocXExportService();
export default docxExportService;
