import { createContext } from "react";

// for example, see https://blog.logrocket.com/how-to-use-react-context-typescript/

/**
 * Describes a natural person that is logged into the application. Account management is performed server-side.
 */
export type User = {
  id: string; //should be UUID
  username: string;
  fullName: string;
  passwordHash: string;
  hasOngoingSubscription: boolean;
  validTill: string; //date/time in ISO format
  reasonBecomingInvalid?: string;
};

export type LoginInfo = {
  needsAuthentication: boolean;
  isLoggedIn: boolean;
  currentUser: User | null;
  renderLocale: string | undefined; //should be Locale
  isAccountFrozen: boolean;
};

export type UserPreferences = {
  [name: string]: string | number | boolean;
};

export type StateContextType = {
  /* If the horizontal viewport size decreases below the @breakpoint-smallest size, this
   * value is set to true. It allows us to layout components differently on small screens.
   */
  isBelowBreakpointSmallest: boolean;
  state: LoginInfo;
  userPreferences: UserPreferences;
};

export function getStateContext(stateContext: StateContextType | null): StateContextType {
  if (!stateContext) {
    throw new Error("State context not defined");
  }
  return stateContext;
}

const StateContext = createContext<StateContextType | null>(null);

export default StateContext;
