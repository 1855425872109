import OutlineManager, { OutlineNode, isOutlineNode } from "../../utils/OutlineManager";
import { SessionConstants } from "../../Constants";
import { wordCount } from "../../utils/wordCount";
import { GlobalAction } from "../..";
import { OutlineState } from "./ProjectView";
import { Content, StructuralTypeName } from "../../dao/ProjectDao";
import { ConsoleLogger, localizer } from "di-common";

const logger = new ConsoleLogger("ContentPane");

export default function outlineReducer(draft: OutlineState, action: GlobalAction) {
  logger.info("outlineReducer: processing action: " + action.type);
  switch (action.type) {
    case "initialDataRetrieved": {
      draft.isInitialLoading = false;
      const outlineManager = OutlineManager.createFromFlatData(action.data.metaData.projectType, action.data.projectStructure);
      draft.outlineData = outlineManager.getTreeData();
      const mustInitialize = draft.outlineData.length === 0;
      let newContent = null;
      if (mustInitialize) {
        //initialize empty content for a new project
        logger.debug("Initializing new empty project");
        const contentMeta = outlineManager.initialize(localizer);
        const projectId = action.data.metaData.id;
        insertContent(draft, contentMeta, projectId, null);
        newContent = {
          notes: null,
          content: "",
          contentMeta
        };
        // Trigger setContent-action on ContentPane, because the insertContent-call above might be too
        // slow for the content to be retrieved by the ContentPane
        sessionStorage.setItem(SessionConstants.CONTENTPANE_EVENT_KEY, JSON.stringify({ type: "setContent", data: newContent }));
        draft.exchangeViaStorageCount = draft.exchangeViaStorageCount + 1;
      } else {
        newContent = action.data.lastModifiedContent;
      }
      draft.contentId = newContent ? newContent.contentMeta.pathDescriptor : null;
      draft.selectedNodeKey = newContent ? newContent.contentMeta.key : undefined;
      draft.projectWordCount = outlineManager.updateWordCount();
      break;
    }
    case "initialDataFailed":
      draft.isInitialLoading = false;
      console.error(action.data);
      break;
    case "treeNodeSelected": {
      if (action.data.selectedKeys[0] && action.data.selectedKeys[0] !== draft.selectedNodeKey) {
        //multiselect is off, so we expect a single selected node at the most
        const selectedNodeKey = action.data.selectedKeys[0];
        const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
        const targetPathDescriptor = outlineManager.getPathDescriptor(selectedNodeKey);
        if (targetPathDescriptor) {
          draft.selectedNodeKey = selectedNodeKey;
          draft.contentId = targetPathDescriptor;
        } else {
          console.warn(`No pathDescriptor found for node with key=${selectedNodeKey}`);
        }
      }
      break;
    }
    case "updateTitle": {
      const title = action.data.newTitle;
      const targetPathDescriptor = draft.contentId;
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      outlineManager.updateTitle(targetPathDescriptor, title);
      break;
    }
    case "updateWordCount": {
      const count = action.data.wordCount;
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      const contentMeta = outlineManager.getNode(draft.contentId);
      if (isOutlineNode(contentMeta) && count !== contentMeta.wordCount) {
        draft.projectWordCount = outlineManager.updateWordCount(draft.contentId, count);
      }
      break;
    }
    case "moveNode":
      {
        const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
        const { dropPosition, node, dragNode } = action.data.moveInfo;
        const renumberedNodes = outlineManager.moveNode(dragNode.pathDescriptor, node.pathDescriptor, dropPosition);
        draft.relocateNodesRequest = serializeForServer(renumberedNodes);
        draft.relocateCount++;
        draft.projectWordCount = outlineManager.updateWordCount();

        //update contentId of current node
        const targetPathDescriptor = outlineManager.getPathDescriptor(draft.selectedNodeKey || "");
        if (targetPathDescriptor && targetPathDescriptor !== draft.contentId) {
          draft.contentId = targetPathDescriptor;
        }
      }
      break;
    case "importFragment": {
      /* This is a compound operation, containing a few (async) operations: (1) create new node in outlineManager (2) copy fragment
       * text/title to new node and send insert request to server and (3) optionally delete fragment. The last action may only be
       * executed when the former succeeds.
       */
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      const importCommand = action.data.importCommand;
      const projectId = action.data.projectId;
      if (importCommand.position === "append") {
        appendChild(draft, outlineManager, importCommand.nodeType, projectId);
      } else {
        insertSibling(draft, outlineManager, importCommand.nodeType, importCommand.position === "after", projectId);
      }

      //set text/title from fragment on newContent node, before the request is send to the server
      if (enrichNewContent(draft.insertNodeRequest.newContent, importCommand.title, importCommand.content)) {
        draft.projectWordCount = outlineManager.updateWordCount();
      }

      if (typeof importCommand.afterImport === "function") {
        importCommand.afterImport(importCommand);
      }

      break;
    }
    case "appendChild": {
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      appendChild(draft, outlineManager, action.data.type, action.data.projectId);
      break;
    }
    case "insertSibling": {
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      insertSibling(draft, outlineManager, action.data.type, action.data.insertAfter, action.data.projectId);
      break;
    }
    case "deleteNode": {
      const deleteNodePathDescriptor = draft.contentId;
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);

      const mustEmptyCurrentNode = outlineManager.isLastRootNode(deleteNodePathDescriptor);

      const { nodesToDelete, renumberedAterDeletion } = outlineManager.deleteNode(deleteNodePathDescriptor);
      draft.deleteNodesRequest = {
        nodesToDelete,
        renumberedNodes: serializeForServer(renumberedAterDeletion)
      };
      draft.projectWordCount = outlineManager.updateWordCount();
      draft.deleteCount++; //this will send delete request to the server

      if (mustEmptyCurrentNode) {
        //store emptied node to server
        sessionStorage.setItem(SessionConstants.CONTENTPANE_EVENT_KEY, JSON.stringify({ type: "clearAndSaveContent" }));
        draft.exchangeViaStorageCount++;
      }
      break;
    }
    case "insertSuccess": {
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      draft.contentId = draft.insertNodeRequest.newContent.contentMeta.pathDescriptor;
      draft.selectedNodeKey = outlineManager.getNode(draft.contentId).key;
      break;
    }
    case "deleteSuccess":
      const outlineManager = OutlineManager.createFromTreeData(action.data.projectType, draft.outlineData);
      const nextNode = outlineManager.getNearestNode(draft.contentId);
      draft.contentId = nextNode ? nextNode.pathDescriptor : "0001";
      draft.selectedNodeKey = outlineManager.getNode(draft.contentId).key;
      break;
    case "updateFailed":
      console.warn(`There was a problem updating the outline: ${action.data}`);
      break;
    // case "saveSuccess":
    //   doBroadcast({
    //     count: broadcast.count + 1,
    //     message: localizer.resolve("Global.feedback.saveSuccess")
    //   });
    //   break;
    default:
      console.error(`ProjectView: Unexpected action type: ${action.type}`);
  }

  function enrichNewContent(contentNode: Content, title?: string, content?: string) {
    if (title) {
      contentNode.contentMeta.title = title;
    }
    if (content) {
      contentNode.content = content;
      contentNode.contentMeta.wordCount = wordCount(content);
      return true; // to indicate that there is content added
    }
    return false; //to indicate that no words are added to the 'story'
  }

  function appendChild(draft: OutlineState, outlineManager: OutlineManager, nodeType: StructuralTypeName, projectId: string) {
    const containerPathDescriptor = draft.contentId;
    const newChildNode = outlineManager.appendChild(nodeType, containerPathDescriptor, localizer);

    //and now we need to sync with backend
    insertContent(draft, newChildNode, projectId, null);
  }

  function insertSibling(
    draft: OutlineState,
    outlineManager: OutlineManager,
    nodeType: StructuralTypeName,
    insertAfter: boolean,
    projectId: string
  ) {
    const siblingPathDescriptor = draft.contentId;
    const { newSiblingNode, renumberedNodes } = outlineManager.insertSibling(
      nodeType,
      siblingPathDescriptor,
      insertAfter,
      localizer
    );

    //and now we need to sync with backend
    insertContent(draft, newSiblingNode, projectId, renumberedNodes);
  }

  function insertContent(
    draftState: OutlineState,
    newNode: OutlineNode,
    projectId: string,
    renumberedNodes: Map<string, string> | null
  ) {
    draftState.insertNodeRequest = {
      newContent: {
        projectId: projectId,
        contentMeta: newNode
      },
      renumberedNodes: serializeForServer(renumberedNodes)
    };
    draftState.insertCount++;
  }

  function serializeForServer(allStringsMap: Map<string, string> | null): Record<string, string> {
    const serializedMap: Record<string, string> = {};
    if (allStringsMap) {
      allStringsMap.forEach((value, key) => {
        serializedMap[key] = value;
      });
    }
    return serializedMap;
  }
}
