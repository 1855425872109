import React from "react";
import HeaderMenu from "../components/user/HeaderMenu";
import { AppInfo } from "../Constants";
import Login from "../components/user/Login";
import { Outlet } from "react-router-dom";

export type MainLayoutProps = {
  isLocked: boolean;
  isLoggedIn: boolean;
  exitMessage?: string;
};

function MainLayout({ isLocked, isLoggedIn, exitMessage }: MainLayoutProps) {
  return isLoggedIn ? (
    <div className="app-container">
      <header className="header">
        <div className="branding">
          <img className="branding__name" src="logo-transparant.png" alt="digital-ink logo" />
          <span className="branding__version">{AppInfo.version}</span>
        </div>
        <HeaderMenu isLocked={isLocked} />
      </header>
      <Outlet />
    </div>
  ) : (
    <Login exitMessage={exitMessage} />
  );
}

export default MainLayout;
