import React, { useEffect, useState } from "react";
import { Button, Modal, Popconfirm, Radio, Slider, Spin, Typography } from "antd";
import { DateTime } from "luxon";
import * as Fees from "../../utils/FeeCalculations";
import { localizer } from "di-common";
import subscriptionDao, { Subscription } from "../../dao/SubscriptionDao";
import { copyObject } from "../../utils/MiscellaneousUtil";
import { Dinero } from "dinero.js";
import { User } from "../../StateContext";
import { GlobalAction } from "../..";

export type SubscriptionPanelProps = {
  setModalComponent: React.Dispatch<React.SetStateAction<React.ReactElement<any, any> | null>>;
  currentUser: User;
  globalDispatch: React.Dispatch<GlobalAction>;
};

function SubscriptionPanel({ setModalComponent, currentUser, globalDispatch }: SubscriptionPanelProps) {
  const [reasonInvalid, setReasonInvalid] = useState(currentUser.reasonBecomingInvalid);
  const startDateFlex = determineStartDateFlex();
  const [isLoading, setLoading] = useState(true);
  const [isCheckingOut, setCheckingOut] = useState(false);
  const [isCanceling, setCanceling] = useState(false);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [buyOption, setBuyOption] = useState<string | null>(null);
  const [flexPrice, setFlexPrice] = useState(Fees.flexFee);
  const [endDateFlex, setEndDateFlex] = useState(startDateFlex.plus({ days: 7 }));

  function format(amount: Dinero) {
    return amount.setLocale(localizer.getCurrentLocale()).toFormat("$0,0.00");
  }

  function determineStartDateFlex() {
    const dateValid = currentUser.validTill ? DateTime.fromISO(currentUser.validTill) : DateTime.now();
    if (reasonInvalid && ["TRIAL_ENDED", "PREPAID_ENDED", "PERIOD_ENDED"].includes(reasonInvalid) && DateTime.now() > dateValid) {
      return DateTime.now();
    }
    return dateValid;
  }

  function determineDaysPrepayable(): number {
    //For accounting purposes (to keep accruals simple), you can only pay in advance till the end of next year
    const validTillNextYearEnd = DateTime.fromObject({
      year: DateTime.utc().plus({ years: 2 }).year,
      day: 1,
      month: 1
    });
    return validTillNextYearEnd.diff(startDateFlex.plus({ months: 1 }), "days").toObject().days || 0;
  }

  function updateEndDateAndFee(nrOfDays: number) {
    const endDate = startDateFlex.plus({ months: 1, days: nrOfDays });
    setEndDateFlex(endDate);
    setFlexPrice(Fees.calculateFlexFee(startDateFlex, endDate));
  }

  function calculateDaysOnSlider(): number {
    return endDateFlex.diff(startDateFlex.plus({ months: 1 }), "days").toObject().days || 0;
  }

  function sliderToDateFormatter(nrOfDays: number | null | undefined) {
    let sliderDate = startDateFlex;
    if (nrOfDays) {
      sliderDate = sliderDate.plus({ months: 1, days: nrOfDays });
    }
    return sliderDate.setLocale(localizer.getCurrentLocale()).toLocaleString(DateTime.DATE_FULL);
  }

  function selectPastOrPresentTense(pastTenseKey: string, presentTenseKey: string): string {
    const validTill = DateTime.fromISO(currentUser.validTill);
    const dateValidFormatted = validTill
      .minus({
        days: 1
      })
      .setLocale(localizer.getCurrentLocale())
      .toLocaleString(DateTime.DATE_FULL);
    if (DateTime.utc() > validTill) {
      return localizer.resolve(pastTenseKey, {
        dateValid: dateValidFormatted
      });
    } else {
      return localizer.resolve(presentTenseKey, {
        dateValid: dateValidFormatted
      });
    }
  }

  function getSubscriptionText(mySubscription: Subscription): string {
    let text = "";
    if (mySubscription.terminationDate) {
      const endDate = DateTime.fromISO(currentUser.validTill)
        .setLocale(localizer.getCurrentLocale())
        .toLocaleString(DateTime.DATE_FULL);
      text += localizer.resolve("Subscription.canceled", {
        endDate
      });
    } else {
      const type = localizer.resolve(`Subscription.${mySubscription.type}`);
      text += localizer.resolve("Subscription.running", { type });
      if (mySubscription.type === "PREPAID_FLEX") {
        if (reasonInvalid === "PREPAID_ENDED") {
          text += selectPastOrPresentTense("Subscription.flexEnded", "Subscription.flexRunning");
        } else if (reasonInvalid === "PAYMENT_FAILED") {
          text += localizer.resolve("Subscription.flexPaymentFailed");
          text += selectPastOrPresentTense("Subscription.flexPeriodNone", "Subscription.flexPeriodRemaining");
        }
      } else if (mySubscription.type !== "LIFETIME") {
        const validTill = DateTime.fromISO(currentUser.validTill)
          .setLocale(localizer.getCurrentLocale())
          .toLocaleString(DateTime.DATE_FULL);
        if (reasonInvalid === "FIRST_PAYMENT_FAILED") {
          text += localizer.resolve("Subscription.firstPaymentFailed");
        } else if (reasonInvalid === "PERIOD_ENDED") {
          text += localizer.resolve("Subscription.renewal", {
            renewDate: validTill
          });
        } else if (reasonInvalid === "SUBSCRIPTION_ENDED") {
          text += localizer.resolve("Subscription.terminated", {
            endDate: validTill
          });
        }
      }
    }
    return text;
  }

  function getNoSubscriptionText(): string {
    let text = localizer.resolve("Subscription.none");
    if (isTrial()) {
      text += selectPastOrPresentTense("Subscription.trialEnded", "Subscription.trialRunning");
    }
    return text;
  }

  function isTrial(): boolean {
    return reasonInvalid === "TRIAL_ENDED";
  }

  function doCheckout(): void {
    setCheckingOut(true);
    if (buyOption === "PREPAY_PERIOD") {
      buyFlexPeriod();
    } else if (buyOption) {
      buySubscription(buyOption);
    } else {
      // mistake: we cannot do checkout
      setCheckingOut(false);
    }
  }

  function buySubscription(subscriptionType: string) {
    const subscriptionRequest: Subscription = {
      subscriberId: currentUser.id,
      subscriptionId: undefined,
      type: subscriptionType,
      startDateFlex: undefined,
      endDateFlex: undefined,
      flexFee: undefined,
      terminationDate: undefined
    };
    if (buyOption === "PREPAID_FLEX") {
      subscriptionRequest.startDateFlex = startDateFlex.toISODate();
      subscriptionRequest.endDateFlex = endDateFlex.toISODate();
      subscriptionRequest.flexFee = flexPrice.toFormat("0.00");
    }

    subscriptionDao.takeoutSubscription(
      currentUser,
      subscriptionRequest,
      (response: any) => {
        setCheckingOut(false);
        if (typeof response.data === "string") {
          window.location.href = response.data;
        }
        // setModalComponent(null);
      },
      (error: any) => {
        setCheckingOut(false);
        console.dir(error);
      }
    );
  }

  function buyFlexPeriod() {
    setCheckingOut(true);
    const subscriptionRequest = {
      subscriberId: currentUser.id,
      subscriptionId: subscription?.subscriptionId,
      type: "PREPAID_FLEX",
      startDateFlex: startDateFlex.toISODate(),
      endDateFlex: endDateFlex.toISODate(),
      flexFee: flexPrice.toFormat("0.00"),
      terminationDate: undefined
    };
    subscriptionDao.prepayPeriod(
      currentUser,
      subscriptionRequest,
      (response: any) => {
        setCheckingOut(false);
        if (typeof response.data === "string") {
          window.location.href = response.data;
        }
        // setModalComponent(null);
      },
      (error: any) => {
        setCheckingOut(false);
        console.dir(error);
      }
    );
  }

  function cancelSubscription() {
    setCanceling(true);
    if (!subscription || !subscription.subscriptionId) {
      throw new Error("Cannot cancel without subscriptionId");
    }
    subscriptionDao.cancelSubscription(
      currentUser,
      {
        subscriberId: currentUser.id,
        subscriptionId: subscription.subscriptionId
      },
      (response: any) => {
        setCanceling(false);
        if (response.data === true) {
          const userCopy = copyObject(currentUser);
          userCopy.reasonBecomingInvalid = "SUBSCRIPTION_ENDED";
          const subscriptionCopy = copyObject(subscription);
          subscriptionCopy.terminationDate = currentUser.validTill;
          globalDispatch({ type: "changeUserInfo", data: userCopy });
          setSubscription(subscriptionCopy);
          setReasonInvalid(userCopy.reasonBecomingInvalid);
        }
      },
      (error: any) => {
        setCanceling(false);
        console.dir(error);
        setModalComponent(null);
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      subscriptionDao.getSubscription(
        currentUser,
        (response: any) => {
          setLoading(false);
          if (response.data === "") {
            setSubscription(null);
          } else {
            setSubscription(response.data);
          }
        },
        (error: any) => {
          setLoading(false);
          setError(error);
        }
      );
    }
  }, [currentUser]);

  const { Paragraph } = Typography;
  const radioStyle = {
    display: "block",
    lineHeight: "200%"
  };

  function makeFlexOption(radioTextKey: string, radioValue: string): React.JSX.Element {
    return (
      <Radio disabled={determineDaysPrepayable() <= 0} style={radioStyle} value={radioValue}>
        {localizer.resolve(radioTextKey, {
          price: format(flexPrice),
          endDate: endDateFlex.setLocale(localizer.getCurrentLocale()).toLocaleString(DateTime.DATE_FULL)
        })}
        {buyOption === radioValue && (
          <Paragraph style={{ marginLeft: "24px" }}>
            {localizer.resolve("Subscription.slideEnddate")}
            <Slider
              onChange={updateEndDateAndFee}
              tooltip={{ formatter: sliderToDateFormatter }}
              max={determineDaysPrepayable()}
              value={calculateDaysOnSlider()}
            />
          </Paragraph>
        )}
      </Radio>
    );
  }

  return (
    <Modal
      title={localizer.resolve("Subscription.title")}
      open={true}
      style={{ minWidth: "50vw" }}
      okText={buyOption ? localizer.resolve("Global.buttonCaption.checkout") : localizer.resolve("Global.buttonCaption.close")}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={doCheckout}
      onCancel={() => {
        setModalComponent(null);
      }}
      confirmLoading={isCheckingOut}
      destroyOnClose={true}
    >
      {isLoading ? (
        <Spin size="large" />
      ) : error ? (
        <Paragraph>{localizer.resolve("Global.message.loadError")}</Paragraph>
      ) : (
        <>
          <Paragraph>{subscription ? getSubscriptionText(subscription) : getNoSubscriptionText()}</Paragraph>
          {(!subscription || subscription.terminationDate) && (
            <>
              <Paragraph>{localizer.resolve("Subscription.askAction")}</Paragraph>
              <Radio.Group
                onChange={event => setBuyOption(event.target.value)}
                defaultValue="ONGOING_QUARTERLY"
                value={buyOption}
                style={{ width: "100%" }}
              >
                {makeFlexOption("Subscription.buyFlex", "PREPAID_FLEX")}
                <Radio style={radioStyle} value="ONGOING_MONTHLY">
                  {localizer.resolve("Subscription.buyMonthly", {
                    price: format(Fees.monthlyFee)
                  })}
                </Radio>
                <Radio style={radioStyle} value="ONGOING_QUARTERLY">
                  {localizer.resolve("Subscription.buyQuarterly", {
                    price: format(Fees.quarterlyFee)
                  })}
                </Radio>
                <Radio style={radioStyle} value="ONGOING_ANNUALLY">
                  {localizer.resolve("Subscription.buyAnnually", {
                    price: format(Fees.annualFee)
                  })}
                </Radio>
              </Radio.Group>
            </>
          )}
          {subscription &&
            !subscription.terminationDate &&
            subscription.type === "PREPAID_FLEX" &&
            determineDaysPrepayable() > 0 && (
              <>
                <Paragraph>{localizer.resolve("Subscription.askAction")}</Paragraph>
                <Radio.Group onChange={event => setBuyOption(event.target.value)} value={buyOption} style={{ width: "100%" }}>
                  {DateTime.now() >= DateTime.fromISO(currentUser.validTill)
                    ? makeFlexOption("Subscription.newFlexPeriod", "PREPAY_PERIOD")
                    : makeFlexOption("Subscription.extendFlexPeriod", "PREPAY_PERIOD")}
                </Radio.Group>
              </>
            )}
          {subscription && subscription.type !== "LIFETIME" && !subscription.terminationDate && (
            <Popconfirm
              title={localizer.resolve("Subscription.cancelConfirmTitle")}
              onConfirm={e => {
                if (e) {
                  e.stopPropagation();
                }
                cancelSubscription();
              }}
              onCancel={e => {
                if (e) {
                  e.stopPropagation();
                }
              }}
              okText={localizer.resolve("Global.buttonCaption.confirmDeletion")}
              cancelText={localizer.resolve("Global.buttonCaption.no")}
            >
              <Button
                loading={isCanceling}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {localizer.resolve("Subscription.cancel")}
              </Button>
            </Popconfirm>
          )}
        </>
      )}
    </Modal>
  );
}

export default SubscriptionPanel;
