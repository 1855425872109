export type ViewTypes = "list" | "display" | "edit" | "new";

export const LIST_VIEW: ViewTypes = "list";
export const SINGLE_VIEW: ViewTypes = "display";
export const EDIT_VIEW: ViewTypes = "edit";
export const NEW_VIEW: ViewTypes = "new";

export type EntityTypes = "Character" | "Event" | "Group" | "Location" | "Relation" | "Thing";

export const CHARACTER: EntityTypes = "Character";
export const EVENT: EntityTypes = "Event";
export const GROUP: EntityTypes = "Group";
export const LOCATION: EntityTypes = "Location";
export const RELATION: EntityTypes = "Relation";
export const THING: EntityTypes = "Thing";

export const displayFieldsByEntityType = Object.freeze(getDisplayFieldsByEntityType());
export const formFieldsByEntityType = Object.freeze(getFormFieldsByEntityType());

//We need more info for some fields, when we are reendering character, such as rendermethod
function getDisplayFieldsByEntityType(): Map<EntityTypes, readonly string[]> {
  let fieldNamesInOrder: Map<EntityTypes, readonly string[]> = new Map();
  fieldNamesInOrder.set(
    CHARACTER,
    Object.freeze([
      "dateOfBirth",
      "biography",
      "roleInStory",
      "objectives",
      "appearance",
      "personality",
      "strengths",
      "weaknesses",
      "development"
    ])
  );
  fieldNamesInOrder.set(
    EVENT,
    Object.freeze(["roleInStory", "description", "occasion", "when", "specifics", "recurrenceInterval"])
  );
  fieldNamesInOrder.set(
    GROUP,
    Object.freeze(["age", "dateOfFormation", "roleInStory", "description", "history", "size", "composition"])
  );
  fieldNamesInOrder.set(
    LOCATION,
    Object.freeze(["age", "dateOfConstruction", "roleInStory", "description", "history", "directions"])
  );
  fieldNamesInOrder.set(RELATION, Object.freeze(["age", "startDate", "roleInStory", "history", "development"]));
  fieldNamesInOrder.set(THING, Object.freeze(["age", "dateOfCreation", "roleInStory", "description", "appearance", "history"]));
  return fieldNamesInOrder;
}

function getFormFieldsByEntityType(): Map<EntityTypes, readonly string[]> {
  let fieldNamesInOrder: Map<EntityTypes, readonly string[]> = getDisplayFieldsByEntityType();

  //deviate on the character fields only
  fieldNamesInOrder.set(
    CHARACTER,
    Object.freeze([
      "gender",
      "age",
      "dateOfBirth",
      "biography",
      "roleInStory",
      "objectives",
      "appearance",
      "personality",
      "strengths",
      "weaknesses",
      "development"
    ])
  );

  return fieldNamesInOrder;
}
