import Axios from "axios";
import { User } from "../StateContext";

class ResourceDao {
  getResource(
    currentUser: User,
    resourceUrl: string,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get(resourceUrl, {
      responseType: "arraybuffer",
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }
}

const resourceDao = new ResourceDao();
export default resourceDao;
