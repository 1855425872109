import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link, useBlocker } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import Axios from "axios";
import { Spin, Tree, message } from "antd";
import StateContext, { getStateContext } from "../../StateContext";
import Page from "../general/Page";
import { isStory, isValidDropTarget } from "../../utils/OutlineManager";
import { localizer, NotFound } from "di-common";
import projectMetaReducer from "./ProjectMetaReducer";
import outlineReducer from "./OutlineReducer";
import ActionBar from "../general/ActionBar";
import ProjectDispatchContext from "./ProjectDispatchContext";
import ContentPane from "./ContentPane";
import ProjectMenu from "./ProjectMenu";
import projectDao, { ProjectMeta } from "../../dao/ProjectDao";
import { OutlineNode } from "../../utils/OutlineManager";
import AutosaveContext from "../../utils/AutosaveContext";
import { RegistryEntry, autosaveRegistryDispatcher } from "../../utils/AutosaveRegistry";
import { GlobalAction } from "../..";
import { useBeforeunload } from "react-beforeunload";
import UnsavedChangesModal from "../general/UnsavedChangesModal";
import { ConsoleLogger } from "di-common";

export type ProjectMetaState = {
  updateCount: number;
  projectMeta?: ProjectMeta; // | Partial<ProjectMeta>;
};

export type OutlineState = {
  outlineData: OutlineNode[];
  /**
   * The logical identifier that identifies the location of the node in the tree.
   */
  contentId: string;

  /* A trigger to indicate that new content is ready to be exchanged via the browsers session storage
   */
  exchangeViaStorageCount: number;
  /**
   * A database identifier that is unique within the tree over time,
   * even when nodes are added and removed
   */
  selectedNodeKey?: string;
  isInitialLoading: boolean;
  projectWordCount: number;
  insertCount: number;
  insertNodeRequest?: any;
  deleteCount: number;
  deleteNodesRequest?: any;
  relocateCount: number;
  relocateNodesRequest?: any;
  notFound: boolean;
};

const logger = new ConsoleLogger("ProjectView");

function ProjectView({ isDisabled = false }) {
  const draggedNode = useRef<OutlineNode | null>(null);
  const { projectId } = useParams();
  //TODO: refactor -> three pieces of outlineState: projectMeta, displayedContent and Structure
  //TODO: refactor -> add 'dao'-type of layer

  /**
   * modalComponent state is used to define or clear the contents of a
   * modal component for popup conversations with the user.
   */
  const [modalComponent, setModalComponent] = useState<React.JSX.Element | null>(null);

  /**
   * Boolean flag that indicates if the drawer is opened or closed
   */
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  type BroadcastState = {
    count: number;
    message?: string;
  };
  /**
   * This state contains a message that will be displayed to the user.
   * Clearing the message state will clear the message.
   */
  const [broadcast, doBroadcast] = useState<BroadcastState>({
    count: 0,
    message: undefined
  });

  const initialMetaState: ProjectMetaState = {
    updateCount: 0,
    projectMeta: undefined
  };

  const [registryEntries, registryEntriesDispatcher] = useImmerReducer<RegistryEntry[], GlobalAction>(
    autosaveRegistryDispatcher,
    []
  );

  /**
   * Hold project metadata as state
   */
  const [projectMetaState, projectMetaDispatch] = useImmerReducer(projectMetaReducer, initialMetaState);

  const initialOutlineState: OutlineState = {
    outlineData: [],
    /**
     * The logical identifier that identifies the location of the node in the tree.
     */
    contentId: "",
    exchangeViaStorageCount: 0,
    /**
     * A database identifier that is unique within the tree over time,
     * even when nodes are added and removed
     */
    selectedNodeKey: undefined,
    isInitialLoading: true,
    projectWordCount: 0,
    insertCount: 0,
    insertNodeRequest: undefined,
    deleteCount: 0,
    deleteNodesRequest: undefined,
    relocateCount: 0,
    relocateNodesRequest: undefined,
    notFound: false
  };

  /**
   * Hold outline data as state
   */
  const [outlineState, outlineDispatch] = useImmerReducer(outlineReducer, initialOutlineState);

  /**
   * State holding the logged in user
   */
  const {
    isBelowBreakpointSmallest,
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  function handleClose() {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    logger.info(`${dirtyEntries.length} entries are dirty`);
    if (dirtyEntries.length > 0) {
      if (userPreferences.isAutoSaveOn === true) {
        dirtyEntries.forEach(entry => {
          logger.info(`Saving changes in ${entry.name}`);
          entry.autosave();
        });
      } else {
        return "Unsaved changes, do not close!";
      }
    }
    return null;
  }

  useBeforeunload(() => {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    if (dirtyEntries.length > 0) {
      return "Do not close!";
    }
    return null;
  });
  const blocker = useBlocker(() => handleClose() != null);

  /**
   * Load data when the component is initialy rendered
   */
  useEffect(() => {
    if (projectId && currentUser) {
      projectDao.loadInitialData(
        projectId,
        currentUser,
        (response: any) => {
          outlineDispatch({
            type: "initialDataRetrieved",
            data: response.data
          });
          projectMetaDispatch({
            type: "initialize",
            data: response.data.metaData
          });
        },
        (error: any) => outlineDispatch({ type: "initialDataFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Send insertNode request to the backend. This is triggered by changing the insertCount state value.
   */
  useEffect(() => {
    if (outlineState.insertNodeRequest && currentUser) {
      Axios.post(`api/private/content/${currentUser.id}`, outlineState.insertNodeRequest, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        (response: any) =>
          outlineDispatch({
            type: "insertSuccess",
            data: {
              projectType: projectMetaState.projectMeta?.projectType
            }
          }),
        (error: any) => outlineDispatch({ type: "updateFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineState.insertCount]);

  /**
   * Send deleteNode request to the backend. This is triggered by changing the deleteCount state value.
   */
  useEffect(() => {
    if (outlineState.deleteNodesRequest && currentUser) {
      Axios.delete(`api/private/content/${currentUser.id}`, {
        data: outlineState.deleteNodesRequest,
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response =>
          outlineDispatch({
            type: "deleteSuccess",
            data: {
              projectType: projectMetaState.projectMeta?.projectType
            }
          }),
        error => outlineDispatch({ type: "updateFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineState.deleteCount]);

  /**
   * Send relocateNode request to the backend. This is triggered by changing the relocateCount state value.
   */
  useEffect(() => {
    if (outlineState.relocateNodesRequest && currentUser) {
      Axios.patch(`api/private/content/${currentUser.id}`, outlineState.relocateNodesRequest, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response =>
          doBroadcast({
            count: broadcast.count + 1,
            message: localizer.resolve("Global.feedback.saveSuccess")
          }),
        // response => outlineDispatch({ type: "saveSuccess" }),
        error => outlineDispatch({ type: "updateFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineState.relocateCount]);

  /**
   * Send an updateProjectMetadata request to the backend. This is triggered by changing the updateCount state value.
   */
  useEffect(() => {
    if (projectMetaState.updateCount > 0 && currentUser) {
      projectDao.updateProjectMeta(
        projectMetaState.projectMeta as ProjectMeta,
        currentUser,
        (response: any) =>
          doBroadcast({
            count: broadcast.count + 1,
            message: localizer.resolve("Global.feedback.saveSuccess")
          }),
        (error: any) => outlineDispatch({ type: "saveFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectMetaState.updateCount]);

  /**
   * Display a broadcast message in the UI. Triggered by changes in the broadcast.count value.
   */
  useEffect(() => {
    if (broadcast.count > 0 && broadcast.message !== null) {
      message.success(broadcast.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcast.count]);

  /**
   * Display a reason in the UI when the interface is disabled
   */
  useEffect(() => {
    if (isDisabled === true) {
      message.info(localizer.resolve(`Global.message.${currentUser?.reasonBecomingInvalid}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDragEnd() {
    draggedNode.current = null;
  }

  function onDragStart({ node }: { node: OutlineNode }) {
    draggedNode.current = node;
  }

  function allowDrop({ dropNode, dropPosition }: { dropNode: OutlineNode; dropPosition: -1 | 0 | 1 }): boolean {
    return isValidDropTarget(projectMetaState.projectMeta!.projectType, draggedNode.current!, dropNode, dropPosition);
  }

  function onDrop(info: any) {
    outlineDispatch({
      type: "moveNode",
      data: {
        moveInfo: info,
        projectType: projectMetaState.projectMeta?.projectType
      }
    });
  }

  if (outlineState.notFound) {
    return <NotFound />;
  }

  if (
    outlineState.isInitialLoading ||
    // !outlineState.contentId ||
    !projectMetaState.projectMeta?.projectType
  ) {
    return <Spin size="large" />;
  }
  /**
   * Boolean value derived from state to flag if this project is a story or not. The
   * value is used to display or hide action bar items.
   */
  const isStoryProject = isStory(projectMetaState.projectMeta.projectType);

  function createContentTree(): React.JSX.Element {
    return (
      <Tree
        treeData={outlineState.outlineData}
        titleRender={nodeData => {
          return <>{`${nodeData.title} [${nodeData.wordCount} ${localizer.resolve("projectview.wordsLabel")}]`}</>;
        }}
        showLine={true}
        selectedKeys={outlineState.selectedNodeKey ? [outlineState.selectedNodeKey] : []}
        defaultSelectedKeys={outlineState.selectedNodeKey ? [outlineState.selectedNodeKey] : []}
        autoExpandParent={true}
        defaultExpandAll={true}
        draggable={!isDisabled}
        allowDrop={allowDrop}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onSelect={selectedKeys =>
          outlineDispatch({
            type: "treeNodeSelected",
            data: {
              selectedKeys,
              projectType: projectMetaState.projectMeta?.projectType
            }
          })
        }
      />
    );
  }

  return (
    <ProjectDispatchContext.Provider
      value={{
        outlineState,
        outlineDispatch,
        projectMetaState,
        projectMetaDispatch,
        setModalComponent
      }}
    >
      <AutosaveContext.Provider value={{ registryEntries, registryEntriesDispatcher }}>
        <Page lang={projectMetaState.projectMeta.language}>
          <div>
            <span>
              <Link to="/" className="link-area">
                &laquo; {localizer.resolve("Global.linkText.backToDashboard")}
              </Link>
            </span>
            <h1 className="title-area">
              {projectMetaState.projectMeta.workTitle}
              <span>{` [${outlineState.projectWordCount} ${localizer.resolve("projectview.wordsLabel")}]`}</span>
              <ProjectMenu isStoryProject={isStoryProject} />
            </h1>
          </div>
          <main className={["writertools", isDrawerOpen ? "writertools--drawer-open" : "writertools--drawer-closed"].join(" ")}>
            <div className="left-aside">{!isBelowBreakpointSmallest && createContentTree()}</div>
            <ContentPane
              isDisabled={isDisabled}
              projectId={projectId}
              contentId={outlineState.contentId}
              selectedNodeKey={outlineState.selectedNodeKey}
              outlineData={outlineState.outlineData}
              exchangeViaStorageCount={outlineState.exchangeViaStorageCount}
            />
            <ActionBar
              isDisabled={isDisabled}
              characterOn={isStoryProject}
              groupOn={isStoryProject}
              locationOn={isStoryProject}
              eventOn={isStoryProject}
              thingOn={isStoryProject}
              storylineOn={isStoryProject}
              setDrawerOpenCallback={setDrawerOpen}
              contentTree={isBelowBreakpointSmallest ? createContentTree() : undefined}
            />
            {modalComponent !== null && modalComponent}
          </main>
          {blocker.state === "blocked" && (
            <UnsavedChangesModal
              onOk={() => {
                blocker.proceed();
              }}
              onCancel={() => {
                registryEntriesDispatcher({ type: "clear" });
                blocker.reset();
              }}
              registryEntries={registryEntries}
            />
          )}
        </Page>
      </AutosaveContext.Provider>
    </ProjectDispatchContext.Provider>
  );
}

export default ProjectView;
