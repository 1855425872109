import { DateTime } from "luxon";
import { User } from "../../StateContext";
import { ConsoleLogger } from "di-common";

const logger = new ConsoleLogger("UserUtils");

export function isAccountFrozen(currentUser: User): boolean {
  if (!currentUser) {
    logger.info("User not yet loaded, assume not frozen");
    return false;
  }
  const validTillDate = getValidTillDate(currentUser);
  return validTillDate !== null && validTillDate !== undefined && DateTime.utc() > validTillDate;
}

/**
 * Get the Date (within UTC timeline) untill which (excluding) the account is valid.
 * When the account has an active ongoing subscription, a grace period of 7 days may be taken into account.
 * @param {*} currentUser
 */
export function getValidTillDate(currentUser: User): DateTime | null {
  if (!currentUser) {
    return DateTime.fromMillis(0); //TODO: throw error?
  }

  if (!currentUser.validTill) {
    return null;
  }

  let validTillDate = DateTime.fromISO(currentUser.validTill);
  if (currentUser.hasOngoingSubscription === true && currentUser.reasonBecomingInvalid === "PERIOD_ENDED") {
    validTillDate = validTillDate.plus({ days: 7 });
  }
  return validTillDate;
}
