import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import Feedback from "../general/Feedback";
import userDao from "../../dao/UserDao";
import { localizer } from "di-common";

function ActivationAction() {
  const { activationCode } = useParams();
  const { userId } = useParams();

  const [isProcessing, setProcessing] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>();

  useEffect(() => {
    if (activationCode && userId) {
      setProcessing(true);
      userDao.activateUser(userId, activationCode, handleResponse, handleError);
    }
  }, [activationCode, userId]);

  function handleResponse(response: any): void {
    setProcessing(false);
    console.dir(response);
    switch (response.data) {
      case "OK":
        setFeedback(localizer.resolve("Register.feedback.activated"));
        break;
      case "UNKNOWN":
      case "EXPIRED":
        setFeedback(localizer.resolve("Register.feedback.registrationExpired"));
        break;
      case "ACTIVE":
        setFeedback(
          localizer.resolve("Register.feedback.accountAlreadyActivated")
        );
        break;
      default:
        setFeedback(localizer.resolve("Register.feedback.unknownProblem"));
        break;
    }
  }

  function handleError(error: any): void {
    setProcessing(false);
    console.dir(error);
  }

  if (isProcessing) {
    return <Spin size="large" />;
  }

  if (feedback != null) {
    return <Feedback feedback={feedback} allowQuestions={false} />;
  }

  return <span>Oeps</span>;
}

export default ActivationAction;
