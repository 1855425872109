import { createContext } from "react";
import AutosaveRegistry, { RegistryEntry } from "./AutosaveRegistry";
import { GlobalAction } from "..";

export type AutosaveContextType = {
  registryEntries: RegistryEntry[];
  registryEntriesDispatcher: React.Dispatch<GlobalAction>;
};

const AutosaveContext = createContext<AutosaveContextType>({ registryEntries: [], registryEntriesDispatcher: console.error });

export function getAutosaveContext({ registryEntries, registryEntriesDispatcher }: AutosaveContextType): AutosaveRegistry {
  return new AutosaveRegistry(registryEntries, registryEntriesDispatcher);
}

export default AutosaveContext;
