import React, { useContext } from "react";
import { Modal, Form, Switch, InputNumber, Select } from "antd";
import StateContext, { getStateContext } from "../../StateContext";
import DispatchContext, { getDispatchContext } from "../../DispatchContext";
import { localizer, SUPPORTED_LOCALES, getBrowserLocale } from "di-common";

export type UserSettingsFormProps = {
  setModalComponent: React.Dispatch<
    React.SetStateAction<React.ReactElement<any, any> | null>
  >;
};

function UserSettingsForm({ setModalComponent }: UserSettingsFormProps) {
  const [form] = Form.useForm();
  const { userPreferences } = getStateContext(useContext(StateContext));
  const { updateUserPreferences } = getDispatchContext(
    useContext(DispatchContext)
  );

  const { Option } = Select;
  return (
    <Modal
      title={localizer.resolve("Settings.title")}
      open={true}
      okText={localizer.resolve("Global.buttonCaption.close")}
      cancelText={localizer.resolve("Global.buttonCaption.cancel")}
      onOk={() => {
        setModalComponent(null);
      }}
      onCancel={() => {
        form.resetFields();
        setModalComponent(null);
      }}
      destroyOnClose={true}
    >
      <Form layout="horizontal" form={form}>
        <Form.Item
          name="enableAutosave"
          label={localizer.resolve("Settings.label.autosave")}
        >
          <Switch
            defaultChecked={userPreferences.isAutoSaveOn as boolean}
            checked={userPreferences.isAutoSaveOn as boolean}
            onChange={() =>
              updateUserPreferences(draft => {
                draft.isAutoSaveOn = !draft.isAutoSaveOn;
              })
            }
          />
        </Form.Item>
        <Form.Item label={localizer.resolve("Settings.label.interval")}>
          <InputNumber
            defaultValue={userPreferences.autoSaveInterval as number}
            disabled={!userPreferences.isAutoSaveOn as boolean}
            maxLength={5}
            step={5}
            min={10}
            onChange={value => {
              if (value) {
                updateUserPreferences(
                  draft => void (draft.autoSaveInterval = value)
                );
              }
            }}
          />
          <span> {localizer.resolve("Settings.label.seconds")}</span>
        </Form.Item>
        <Form.Item label={localizer.resolve("Settings.label.locale")}>
          <Select
            defaultValue={userPreferences.uiLocale || getBrowserLocale()}
            onChange={value =>
              updateUserPreferences(draft => {
                draft.uiLocale = value;
              })
            }
          >
            {[...SUPPORTED_LOCALES].map(([key, value]) => {
              return (
                <Option key={`${key}`} value={`${key}`}>
                  {localizer.getLanguageDescription(key)}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UserSettingsForm;
