import { Cascader, Spin } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { localizer } from "di-common";
import { DefaultOptionType } from "antd/lib/cascader";
import { SingleValueType } from "rc-cascader/lib/Cascader";

const stateOptions = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" }
];

type VatRegimeSelectorProps = {
  /** the function to call when another vat regime is selected */
  onChange: (
    value: SingleValueType,
    selectOptions: DefaultOptionType[]
  ) => void;
};

type VatRegimeOptionType = {
  value: string;
  label: string;
  children?: VatRegimeOptionType[];
};

function VatRegimeSelector({ onChange }: VatRegimeSelectorProps) {
  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<VatRegimeOptionType[]>();

  useEffect(() => {
    //Load localized country codes and transform them to option list for cascader (only once per instance)
    setLoading(true);
    Axios.get(
      `/localizations/iso3166-1-countries.${localizer.getCurrentLocale()}.json`
    ).then(
      (response: any) => {
        setOptions(transform(response.data));
        setLoading(false);
      },
      (error: any) => {
        console.error(
          "Something failed somehow, somewhere... that happens sometimes :-(",
          error
        );
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Transform the localised countrycode / descriptions to something that can serve as an
   * option for Cascader. The option for 'US' is enriched with an children array containing
   * the  state names.
   * @param {*} countryLocalizations dictionary of iso3166-1 alpha-2 country codes mapped to their localized descriptions
   */
  function transform(countryLocalizations: any) {
    let options: VatRegimeOptionType[] = [];
    Object.keys(countryLocalizations).forEach(function (key: string) {
      let option: VatRegimeOptionType = {
        value: key,
        label: countryLocalizations[key]
      };
      if (key === "US") {
        option.children = stateOptions;
      }
      options.push(option);
    });
    options.sort(function (
      option1: VatRegimeOptionType,
      option2: VatRegimeOptionType
    ): number {
      return option1.label.localeCompare(option2.label);
    });
    return options;
  }

  function optionFilter(
    inputValue: string,
    options: DefaultOptionType[]
  ): boolean {
    return options.some(
      option =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );
  }

  return isLoading ? (
    <Spin />
  ) : (
    <Cascader
      options={options}
      onChange={onChange}
      changeOnSelect={true}
      showSearch={{ filter: optionFilter, matchInputWidth: true }}
    />
  );
}

export default VatRegimeSelector;
