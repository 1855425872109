import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Drawer, Form, Space, Tooltip } from "antd";
import { ArrowLeftOutlined, CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useImmer } from "use-immer";
import EntityStateContext, { SharedEntityState } from "./EntityStateContext";
import { localizer } from "di-common";
import { capitalizeFirstCharacter } from "../general/FieldFormatterUtil";
import EntityList from "./EntityList";
import EntityDetails from "./EntityDetails";
import { EntityTypes, EDIT_VIEW, LIST_VIEW, NEW_VIEW, SINGLE_VIEW, ViewTypes } from "./EntityConstants";
import IconFont from "../general/IconFont";
import { CloseButton, SaveButton } from "../general/CommonButtons";
import { Entity } from "../../dao/EntityDao";
import AutosaveContext, { getAutosaveContext } from "../../utils/AutosaveContext";

type EntityPaneProps = {
  isDisabled: boolean;
  entityType: EntityTypes;
  onClose: (e: React.MouseEvent | React.KeyboardEvent) => void;
  isVisible: boolean;
};

/**
 * Maintain the information for a type of entities in the story. See EntityConstants.EntityTypes for supported types.
 * When manually saving an Entity, the form will close which acts like a confirmation. So there will be no confirmation
 * message displayed for Entities.
 */
function EntityPane({ isDisabled = false, entityType, onClose, isVisible }: EntityPaneProps) {
  const singularEntityName = localizer.resolve(`Global.entity.${entityType}.name`);
  const pluralEntityName = localizer.resolve(`Global.entity.${entityType}.plural`);
  const avatarIcon = <IconFont type={`icon-${entityType}`} size={32} />;

  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [isSaving, setSaving] = useState(false);

  //Indicate if the current save operation is triggered by the user or by the autosave mechanism
  const [isDirty, setDirty] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sharedEntityState] = useImmer<SharedEntityState>({
    singularEntityName,
    pluralEntityName,
    entityType,
    avatarIcon
  });

  //This state is pulled up, because it is shared between child components
  const [entityId, setEntityId] = useState<string | undefined>(undefined);
  const [mode, setMode] = useState<ViewTypes>(LIST_VIEW);
  const entityRef = useRef<Entity | undefined>();

  const autosaveRegistry = getAutosaveContext(useContext(AutosaveContext));
  useEffect(() => {
    autosaveRegistry.addEntry({
      id: entityType,
      name: localizer.resolve("UnsavedChangesModal.entityEditor", {
        entityName: localizer.resolve("Global.entity." + entityType + ".name")
      }),
      isDirty: () => isDirty,
      autosave: () => formRef.current.submit()
    });
  });

  function addEntity() {
    setEntityId(undefined);
    setMode(NEW_VIEW);
  }

  function onSelectEntity(locationId: string): void {
    if (locationId) {
      setEntityId(locationId);
      setMode(SINGLE_VIEW);
    }
  }

  function addActionButtons(mode: ViewTypes): React.JSX.Element | null {
    if (mode === SINGLE_VIEW) {
      return (
        <Space size="small">
          <Tooltip title={localizer.resolve("Global.tooltip.backToList")}>
            <Button shape="circle" icon={<ArrowLeftOutlined />} size="small" onClick={() => setMode(LIST_VIEW)} />
          </Tooltip>
          <Tooltip title={localizer.resolve("Global.buttonCaption.edit")}>
            <Button disabled={isDisabled} size="large" type="link" icon={<EditOutlined />} onClick={() => setMode(EDIT_VIEW)} />
          </Tooltip>
        </Space>
      );
    }
    if (mode === LIST_VIEW) {
      return (
        <Tooltip
          title={localizer.resolve("Global.tooltip.addEntity", {
            entityName: singularEntityName
          })}
        >
          <Button disabled={isDisabled} shape="circle" icon={<PlusOutlined />} size="small" onClick={addEntity} />
        </Tooltip>
      );
    }
    if (mode === NEW_VIEW || mode === EDIT_VIEW) {
      return (
        <Space size="small">
          <SaveButton
            isDirty={isDirty}
            isSaving={isSaving}
            save={() => {
              form.submit();
            }}
          />
          <CloseButton
            isDirty={isDirty}
            close={() => {
              form.resetFields();
              setDirty(false);
              entityId //derive state, so we don't have to drill down the mode-property
                ? setMode(SINGLE_VIEW)
                : setMode(LIST_VIEW);
            }}
          />
        </Space>
      );
    }
    return null;
  }

  return (
    <EntityStateContext.Provider value={sharedEntityState}>
      <Drawer
        style={{ position: "absolute" }}
        title={
          mode === LIST_VIEW ? (
            <span>
              {capitalizeFirstCharacter(
                localizer.resolve(`Global.title.list`, {
                  entityName: pluralEntityName
                })
              )}
            </span>
          ) : (
            <span>{capitalizeFirstCharacter(singularEntityName)}</span>
          )
        }
        placement="right"
        getContainer={() => document.getElementById("drawerContainer") as HTMLElement}
        closable={true}
        onClose={onClose}
        open={isVisible}
        extra={addActionButtons(mode)}
        mask={false}
        width="100%"
        closeIcon={
          <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
            <CloseOutlined />
          </Tooltip>
        }
      >
        <div className="aside__scrollable">
          {mode === LIST_VIEW ? (
            <EntityList isDisabled={isDisabled} onSelectEntity={onSelectEntity} />
          ) : (
            <EntityDetails
              setSaving={setSaving}
              isDisabled={isDisabled}
              entityId={entityId}
              form={form}
              mode={mode}
              setMode={setMode}
              setDirty={setDirty}
              entityRef={entityRef}
            />
          )}
        </div>
      </Drawer>
    </EntityStateContext.Provider>
  );
}

export default EntityPane;
