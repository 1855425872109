import React from "react";
import { Entity } from "../../../dao/EntityDao";

type ParticipantsListProps = {
  editable?: boolean;
  participants: Entity[];
  ignoreList: Entity[];
};

/**
 * Display the list of participants in a relation. This component can be rendered readonly or editable, and a
 * filter can be applied to suppress certain instances of 'relatables'.
 *
 * We need to display participants:
 *  - in the relation list (readonly) -- participant icons only (vertical) (their name as tooltip on the icon)
 *  - in the relation details (editable) -- participant icon + their name (horizontal)
 *  - in 'relatable' details (readonly) -- participant icon + their name (horizontal)
 *  - in 'relatable' form (readonly) -- participant icon + their name (horizontal)
 *
 * @param {*} props The React properties passed on to this component
 */
function ParticipantsList({
  editable = false,
  participants,
  ignoreList
}: ParticipantsListProps) {
  /* props may contain:
   * Collection of all relatables -- only needed when editing
   * Collection of current participants
   * Collection of participants to ignore
   * displayMode (view or edit)
   *
   */
  return <div>Participants...</div>;
}

export default ParticipantsList;
