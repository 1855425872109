import React, { useEffect, useRef } from "react";
import { PlotLine } from "../../dao/OutlineDao";
import { Drawer, Form, Input, Tooltip } from "antd";
import { localizer } from "di-common";
import { GlobalAction } from "../..";
import { CloseOutlined } from "@ant-design/icons";

type PlotLineDetailsProps = {
  isDisabled?: boolean;
  isVisible?: boolean;
  plotLine?: PlotLine;
  onClose: () => void;
  dispatcher: React.Dispatch<GlobalAction>;
};

function getEmptyPlotLine(): PlotLine {
  return { id: "", columnIndex: 0, title: "", background: "", summary: "", notes: "" };
}

/**
 * Render a form on the left side to maintain the data of a PlotLine
 */
export function PlotLineDetails({
  isDisabled = false,
  isVisible = true,
  plotLine = getEmptyPlotLine(),
  dispatcher,
  onClose
}: PlotLineDetailsProps) {
  const [form] = Form.useForm();
  //Keep track of the PlotItem that is cerrently rendered in the
  const renderedIdRef = useRef(plotLine.id);

  /* For some unknown reason, the form is not rendered anew with updated (initial)
   * values, when the plotItem prop is different than previous render. Therefore, we
   * have this useEffect to accomplish this.
   */
  useEffect(() => {
    if (renderedIdRef.current !== plotLine.id) {
      form.setFieldsValue({ ...getEmptyPlotLine(), ...plotLine });
      renderedIdRef.current = plotLine.id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotLine.id]);

  function updatePlotLine(changedFields: any): void {
    if (plotLine.id) {
      dispatcher({ type: "updatePlotLine", data: { id: plotLine.id, changes: changedFields } });
    }
  }

  return (
    <Drawer
      style={{ position: "absolute" }}
      className="plotline__details"
      title={localizer.resolve("PlotLineDetails.title")}
      placement="left"
      getContainer={() => document.querySelector(".left-aside") as HTMLElement}
      closable={true}
      onClose={onClose}
      open={isVisible}
      mask={false}
      width="100%"
      closeIcon={
        <Tooltip title={localizer.resolve("Global.buttonCaption.slideShut")}>
          <CloseOutlined />
        </Tooltip>
      }
    >
      <Form
        form={form}
        initialValues={plotLine}
        layout="vertical"
        onValuesChange={changedFields => updatePlotLine(changedFields)}
        preserve
        size="small"
      >
        <Form.Item
          name="title"
          label={localizer.resolve("PlotLine.title.label")}
          rules={[
            {
              max: 255,
              message: localizer.resolve("PlotLine.title.message.maxLength")
            },
            {
              required: true,
              message: localizer.resolve("PlotLine.title.message.mandatory")
            }
          ]}
        >
          <Input.TextArea rows={4} maxLength={256} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="background"
          label={localizer.resolve("PlotLine.background.label")}
          extra={localizer.resolve("PlotLine.background.help")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="summary"
          label={localizer.resolve("PlotLine.summary.label")}
          extra={localizer.resolve("PlotLine.summary.help")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          name="notes"
          label={localizer.resolve("PlotLine.notes.label")}
          rules={[
            {
              max: 65000,
              message: localizer.resolve("Global.message.max65KDescription")
            }
          ]}
        >
          <Input.TextArea rows={8} maxLength={65001} disabled={isDisabled} />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
