import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { DatePicker, Form, Input, InputNumber, Space } from "antd";
import rfdc from "rfdc";
import { localizer } from "di-common";
import { Entity, getDao } from "../../dao/EntityDao";
import StateContext, { getStateContext } from "../../StateContext";
import {
  RELATION,
  SINGLE_VIEW,
  LIST_VIEW,
  ViewTypes
} from "../entity/EntityConstants";
import { SaveButton, CloseButton } from "../general/CommonButtons";

const deepClone = rfdc();

type RelationFormProps = {
  relation: Entity;
  setRelation: React.Dispatch<React.SetStateAction<Entity | Partial<Entity>>>;
  setMode: React.Dispatch<React.SetStateAction<ViewTypes>>;
};

function RelationForm({ relation, setRelation, setMode }: RelationFormProps) {
  const relationDao = getDao(RELATION);
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [isSaving, setSaving] = useState(false);

  const {
    state: { currentUser }
  } = getStateContext(useContext(StateContext));

  function sendForm(values: any) {
    if (projectId && currentUser) {
      setSaving(true);
      const relationToSave = { ...deepClone(relation), ...values };
      relationDao.insertOrUpdateEntity(
        projectId,
        relationToSave,
        currentUser,
        (response: any) => {
          setSaving(false);
          setRelation(relationToSave);
          setMode(SINGLE_VIEW);
          //TODO: show success feedback to user
        },
        (error: any) => {
          setSaving(false);
          console.error(error);
          //TODO: show error feedback to user
        }
      );
    }
  }

  const { TextArea } = Input;
  return (
    <Form
      form={form}
      initialValues={relation}
      layout="vertical"
      onFinish={values => sendForm(values)}
      preserve
      size="small"
    >
      <Form.Item
        name="name"
        label={localizer.resolve("Relation.name.label")}
        rules={[
          {
            max: 100,
            message: localizer.resolve("Relation.name.message.maxLength")
          },
          {
            required: true,
            message: localizer.resolve("Relation.name.message.mandatory")
          }
        ]}
      >
        <Input maxLength={101} />
      </Form.Item>
      <Form.Item name="age" label={localizer.resolve("Relation.age.label")}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="startDate"
        label={localizer.resolve("Relation.startDate.label")}
      >
        <DatePicker
          format="YYYY-MM-DD"
          placeholder={localizer.resolve("Relation.startDate.placeholder")}
        />
      </Form.Item>
      <Form.Item
        name="roleInStory"
        label={localizer.resolve("Relation.roleInStory.label")}
        rules={[
          {
            /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
            max: 65000,
            message: localizer.resolve("Global.message.max65KDescription")
          }
        ]}
      >
        <TextArea maxLength={65001} />
      </Form.Item>
      <Form.Item
        name="history"
        label={localizer.resolve("Relation.history.label")}
        rules={[
          {
            /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
            max: 65000,
            message: localizer.resolve("Global.message.max65KDescription")
          }
        ]}
      >
        <TextArea maxLength={65001} />
      </Form.Item>
      <Form.Item
        name="development"
        label={localizer.resolve("Relation.development.label")}
        rules={[
          {
            /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
            max: 65000,
            message: localizer.resolve("Global.message.max65KDescription")
          }
        ]}
      >
        <TextArea maxLength={65001} />
      </Form.Item>
      <Form.Item>
        <Space size="small">
          <SaveButton
            isDirty={false}
            isSaving={isSaving}
            save={() => {
              form.submit();
            }}
          />
          <CloseButton
            isDirty={true}
            close={() => {
              form.resetFields();
              relation.id //derive state, so we don't have to drill down the mode-property
                ? setMode(SINGLE_VIEW)
                : setMode(LIST_VIEW);
            }}
          />
        </Space>
      </Form.Item>
    </Form>
  );
}

export default RelationForm;
