import React, { useEffect, useContext, useState, useRef } from "react";
import { useParams, Link, useBlocker } from "react-router-dom";
import { localizer, NotFound } from "di-common";
import StateContext, { getStateContext } from "../../StateContext";
import Page from "../general/Page";
import { Button, Form, Input, message, Radio, Space, Spin } from "antd";
import ImageUpload from "../general/ImageUpload";
import Axios from "axios";
import { useImmerReducer } from "use-immer";
import ActionBar from "../general/ActionBar";
import IdeaToProjectForm from "./IdeaToProjectForm";
import { hasFormErrors as hasErrors } from "../../utils/MiscellaneousUtil";
import { ExportButton, SaveButton } from "../general/CommonButtons";
import { GlobalAction } from "../..";
import PlotDesignerButton from "../storyline/PlotDesignerButton";
import AutosaveRegistry, { RegistryEntry, autosaveRegistryDispatcher } from "../../utils/AutosaveRegistry";
import AutosaveContext from "../../utils/AutosaveContext";
import UnsavedChangesModal from "../general/UnsavedChangesModal";
import { useBeforeunload } from "react-beforeunload";
import { ConsoleLogger } from "di-common";
import RemotePlotCollection from "../../utils/RemotePlotCollection";
import docxExportService from "../../services/DocXExportService";

export type Idea = {
  id?: string;
  workTitle?: string;
  coverUrl?: string;
  ideaSummary?: string;
  plot?: string;
  synopsis?: string;
  notes?: string;
  genre?: string;
};

type IdeaDetailsState = {
  isLoading: boolean;
  saveCount: number;
  showLevelDetail: "1" | "2" | "3";
  notFound: boolean;
};

const logger = new ConsoleLogger("IdeaDetails");

function IdeaDetails({ isDisabled = false }) {
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const [isDirty, setDirty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  /* Can have three types of values: "saving" means that a save operation is running and we are waiting for server response,
   * "success" means that the previous request was succesfully finished, otherwise it has the value of the error received from
   * the server
   */
  const [saveState, setSaveState] = useState<"success" | "saving" | string | null>(null);

  const [saveSource, setSaveSource] = useState<"none" | "user" | "autosave">("none");
  const {
    state: { currentUser },
    userPreferences
  } = getStateContext(useContext(StateContext));

  const [registryEntries, registryEntriesDispatcher] = useImmerReducer<RegistryEntry[], GlobalAction>(
    autosaveRegistryDispatcher,
    []
  );

  //Represents the most recently known state of the idea as it is stored on the server
  const syncedIdeaRef = useRef<Idea>({});

  //Represents a recent state of the idea in the browser, that is saved to the server
  const copyToSaveRef = useRef<Idea>({});

  const hasFormErrorsRef = useRef(false);

  function handleClose() {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    logger.info(`${dirtyEntries.length} entries are dirty`);
    if (dirtyEntries.length > 0) {
      if (userPreferences.isAutoSaveOn === true) {
        dirtyEntries.forEach(entry => {
          logger.info(`Saving changes in ${entry.name}`);
          entry.autosave();
        });
      } else {
        return "Unsaved changes, do not close!";
      }
    }
    return null;
  }

  useBeforeunload(() => {
    const dirtyEntries = registryEntries.filter(entry => entry.isDirty() === true) || [];
    if (dirtyEntries.length > 0) {
      return "Do not close!";
    }
    return null;
  });
  const blocker = useBlocker(() => handleClose() != null);

  const initialState: IdeaDetailsState = {
    /* Indicates weather a load operation is active (waiting for server response). A load operation normally
       is only triggered when the page is first displayed
     */
    isLoading: true,

    //Use saveCount to trigger feedback message on save operations via useEffect
    saveCount: 0,

    showLevelDetail: "1",

    notFound: false
  };

  function localReducer(draft: IdeaDetailsState, action: GlobalAction): void {
    switch (action.type) {
      case "saveSuccess":
        //This piece of code is called twice when the idea is saved (!!) - I don't understand it
        logger.info(localizer.resolve("Global.feedback.saveSuccess"));
        setSaveState("success");
        syncedIdeaRef.current = { ...copyToSaveRef.current };
        setDirty(isDirtyCheck());
        draft.saveCount++;
        break;
      case "saveFailed":
        console.error(`There was a problem saving the idea: ${action.data}`);
        setSaveState(action.data);
        draft.saveCount++;
        break;
      case "ideaReceived":
        syncedIdeaRef.current = action.data;
        draft.showLevelDetail = syncedIdeaRef.current.synopsis ? "3" : syncedIdeaRef.current.plot ? "2" : "1";
        draft.isLoading = false;
        break;
      case "loadFailed":
        console.error(`There was a problem loading an idea: ${action.data.message}`);
        if (action.data.response.status === 404) {
          draft.notFound = true;
          syncedIdeaRef.current = {};
          copyToSaveRef.current = {};
        }
        draft.isLoading = false;
        break;
      case "changeLevelDetail":
        draft.showLevelDetail = action.data;
        break;
      case "exportPlot":
        if (currentUser && projectId) {
          const workTitle = syncedIdeaRef.current.workTitle || "";
          const plotitemCollector = new RemotePlotCollection(currentUser, projectId);
          plotitemCollector.getOutlineAndPlotlines().then(data => {
            docxExportService.processPlot(workTitle, data);
          });
        }
        break;
      default:
        console.error(`Command not defined: ${action.type}`);
    }
  }

  const [state, dispatch] = useImmerReducer(localReducer, initialState);

  function isDirtyCheck() {
    return (
      syncedIdeaRef.current.workTitle !== form.getFieldValue("workTitle") ||
      syncedIdeaRef.current.coverUrl !== form.getFieldValue("coverUrl") ||
      syncedIdeaRef.current.ideaSummary !== form.getFieldValue("ideaSummary") ||
      syncedIdeaRef.current.plot !== form.getFieldValue("plot") ||
      syncedIdeaRef.current.synopsis !== form.getFieldValue("synopsis") ||
      syncedIdeaRef.current.notes !== form.getFieldValue("notes") ||
      syncedIdeaRef.current.genre !== form.getFieldValue("genre")
    );
  }

  function autoSave() {
    if (isDirtyCheck()) {
      setSaveSource("autosave");
      form.submit();
    }
  }

  useEffect(() => {
    //load initial idea data when page is first loaded
    if (currentUser) {
      Axios.get("/api/private/ideas/" + currentUser.id + "/" + projectId, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response => dispatch({ type: "ideaReceived", data: response.data }),
        error => dispatch({ type: "loadFailed", data: error })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function sendForm(formData: any) {
    setSaveState("saving");
    copyToSaveRef.current = { ...syncedIdeaRef.current, ...formData };

    //send save request to server
    if (currentUser) {
      Axios.put("/api/private/ideas/" + currentUser.id + "/" + projectId, copyToSaveRef.current, {
        auth: {
          username: currentUser.username,
          password: currentUser.passwordHash
        }
      }).then(
        response => dispatch({ type: "saveSuccess" }),
        error => dispatch({ type: "saveFailed", data: error })
      );
    }
  }

  useEffect(() => {
    //give user feedback about result of manual save operation
    if (state.saveCount > 0 && saveState !== "saving" && saveSource === "user") {
      if (saveState === "success") {
        message.success(localizer.resolve("Global.feedback.saveSuccess"));
      } else {
        message.error(localizer.resolve("Global.feedback.saveError"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.saveCount, saveState, saveSource]);

  useEffect(() => {
    if (userPreferences.isAutoSaveOn === true && (userPreferences.autoSaveInterval as number) > 0) {
      let interval = setInterval(() => {
        autoSave();
      }, (userPreferences.autoSaveInterval as number) * 1000); //autoSaveInterval is in seconds
      console.info(`Auto save is turned on, saving your work after every ${userPreferences.autoSaveInterval} seconds`);
      return () => clearInterval(interval);
    } else {
      console.info("Auto save is turned off");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPreferences.isAutoSaveOn, userPreferences.autoSaveInterval]);

  useEffect(() => {
    const autosaveRegistry = new AutosaveRegistry(registryEntries, registryEntriesDispatcher);
    autosaveRegistry.addEntry({
      id: "IdeaEditor",
      name: localizer.resolve("UnsavedChangesModal.plotEditor"),
      isDirty: () => isDirty,
      autosave: autoSave
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (isDisabled === true && currentUser) {
      message.info(localizer.resolve(`Global.message.${currentUser.reasonBecomingInvalid}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state.isLoading) {
    return <Spin size="large" />;
  }

  if (state.notFound) {
    return <NotFound />;
  }

  return (
    <AutosaveContext.Provider value={{ registryEntries, registryEntriesDispatcher }}>
      <Page>
        <span>
          <Link to="/ideas">&laquo; {localizer.resolve("IdeaDetails.backToIdeabook")}</Link>
        </span>
        <main className={["writertools", isDrawerOpen ? "writertools--drawer-open" : "writertools--drawer-closed"].join(" ")}>
          <div className="left-aside" />
          <div className="content-area">
            <Space size="small">
              <SaveButton
                isDisabled={isDisabled}
                isDirty={isDirty}
                isSaving={saveState === "saving"}
                save={() => {
                  setSaveSource("user");
                  form.submit();
                }}
              />
              <ExportButton
                name={`${syncedIdeaRef.current.workTitle?.trim() || localizer.resolve("Global.untitled")} (${localizer.resolve(
                  "Outline.title"
                )})`}
                exportFunction={() =>
                  dispatch({
                    type: "exportPlot"
                  })
                }
              />
              {projectId && <PlotDesignerButton projectId={projectId} />}
              <Button
                disabled={isDisabled}
                type="link"
                onClick={() => {
                  autoSave();
                  if (!hasFormErrorsRef.current) {
                    setShowModal(true);
                  }
                }}
              >
                {localizer.resolve("IdeaDetails.makeProject")}
              </Button>
            </Space>
            <Form
              initialValues={syncedIdeaRef.current}
              onFinish={values => sendForm(values)}
              onFieldsChange={(changedFields, allFields) => {
                setDirty(isDirtyCheck());
                hasFormErrorsRef.current = hasErrors(changedFields, allFields);
              }}
              preserve
              form={form}
              scrollToFirstError={true}
            >
              <Form.Item
                label={localizer.resolve("ProjectMeta.workTitle.label")}
                name="workTitle"
                rules={[
                  {
                    max: 254,
                    message: localizer.resolve("ProjectMeta.workTitle.message.maxLength")
                  },
                  {
                    required: true,
                    message: localizer.resolve("ProjectMeta.workTitle.message.mandatory")
                  }
                ]}
              >
                <Input maxLength={255} disabled={isDisabled} />
              </Form.Item>
              <ImageUpload
                isDisabled={isDisabled}
                name="coverUrl"
                label={localizer.resolve("ProjectMeta.coverUrl.label")}
                extra={localizer.resolve("ProjectMeta.coverUrl.extra")}
                initialUrl={syncedIdeaRef.current.coverUrl}
              />
              <Form.Item label={localizer.resolve("IdeaDetails.chooseDetails")}>
                <Radio.Group
                  defaultValue={state.showLevelDetail}
                  onChange={e =>
                    dispatch({
                      type: "changeLevelDetail",
                      data: e.target.value
                    })
                  }
                >
                  <Radio.Button value="1">{localizer.resolve("IdeaDetails.choices.idea")}</Radio.Button>
                  <Radio.Button value="2">{localizer.resolve("IdeaDetails.choices.plot")}</Radio.Button>
                  <Radio.Button value="3">{localizer.resolve("IdeaDetails.choices.synopsis")}</Radio.Button>
                </Radio.Group>
              </Form.Item>{" "}
              <Form.Item
                label={localizer.resolve("Outline.ideaSummary.label")}
                name="ideaSummary"
                extra={localizer.resolve("Outline.ideaSummary.help")}
                hidden={state.showLevelDetail !== "1"}
                rules={[
                  {
                    /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
                    max: 65000,
                    message: localizer.resolve("Outline.ideaSummary.message.maxLength")
                  }
                ]}
              >
                <Input.TextArea rows={10} maxLength={65001} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                label={localizer.resolve("Outline.plot.label")}
                name="plot"
                extra={localizer.resolve("Outline.plot.help")}
                hidden={state.showLevelDetail !== "2"}
                rules={[
                  {
                    /* The database can handle 65,535 chars, but we limit this to an easy pronouncable number */
                    max: 65000,
                    message: localizer.resolve("Outline.plot.message.maxLength")
                  }
                ]}
              >
                <Input.TextArea rows={10} maxLength={65001} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                label={localizer.resolve("Outline.synopsis.label")}
                name="synopsis"
                extra={localizer.resolve("Outline.synopsis.help")}
                hidden={state.showLevelDetail !== "3"}
                rules={[
                  {
                    /* The database can handle 16,777,215 chars, but we limit this to an easy pronouncable number */
                    max: 10000000,
                    message: localizer.resolve("Outline.synopsis.message.maxLength")
                  }
                ]}
              >
                <Input.TextArea rows={10} maxLength={10000001} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                label={localizer.resolve("Outline.notes.label")}
                name="notes"
                rules={[
                  {
                    /* The database can handle 16,777,215 chars, but we limit this to an easy pronouncable number */
                    max: 10000000,
                    message: localizer.resolve("Outline.notes.message.maxLength")
                  }
                ]}
              >
                <Input.TextArea rows={10} maxLength={10000001} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                label={localizer.resolve("ProjectMeta.genre.label")}
                name="genre"
                rules={[
                  {
                    max: 254,
                    message: localizer.resolve("ProjectMeta.genre.message.maxLength")
                  }
                ]}
              >
                <Input maxLength={255} disabled={isDisabled} />
              </Form.Item>
            </Form>
          </div>
          <ActionBar storylineOn={false} isDisabled={isDisabled} setDrawerOpenCallback={setDrawerOpen} />
        </main>
        {blocker.state === "blocked" && (
          <UnsavedChangesModal
            onOk={() => {
              blocker.proceed();
            }}
            onCancel={() => {
              registryEntriesDispatcher({ type: "clear" });
              blocker.reset();
            }}
            registryEntries={registryEntries}
          />
        )}

        <IdeaToProjectForm showModal={showModal} setShowModal={setShowModal} idea={syncedIdeaRef.current} />
      </Page>
    </AutosaveContext.Provider>
  );
}

export default IdeaDetails;
