import { GlobalAction } from "..";
import { ConsoleLogger } from "di-common";

export type RegistryEntry = {
  id: string;
  name: string;
  isDirty: () => boolean;
  autosave: () => void;
};

const logger = new ConsoleLogger("AutosaveRegistry");

/**
 * The purpose of this class is to register all forms present in a multiform Pane, that have
 * autosave capabilities. It allows us to autosave each form when the Pane is closed or
 * display a list of unsaved changes to the user when autosave is switched off.
 * A form Pane needs to register itself with this class if it wants to participate in this mechanism
 */
export default class AutosaveRegistry {
  private registryEntriesDispatcher: React.Dispatch<GlobalAction>;
  private registryState: RegistryEntry[];

  constructor(registryData: RegistryEntry[], registryEntriesDispatcher: React.Dispatch<GlobalAction>) {
    this.registryState = registryData;
    this.registryEntriesDispatcher = registryEntriesDispatcher;
  }

  public addEntry(newEntry: RegistryEntry): void {
    this.registryEntriesDispatcher({ type: "addEntry", data: newEntry });
  }

  public removeEntry(entryToDelete: RegistryEntry): void {
    this.registryEntriesDispatcher({ type: "removeEntry", data: entryToDelete });
  }

  public findByName(entryName: string): RegistryEntry | null {
    if (this.registryState.length > 0) {
      for (const entry of this.registryState) {
        if (entry.name === entryName) {
          return entry;
        }
      }
    }
    return null;
  }

  public findDirtyEntries(): RegistryEntry[] {
    return this.registryState.filter(entry => entry.isDirty() === true);
  }

  public printSummary() {
    if (this.registryState.length === 0) {
      logger.info("No autosave entries registered");
    } else {
      this.registryState.forEach(entry => logger.info(`Registered autosave component ${entry.name}: ${entry.isDirty()}`));
    }
  }
}

export function autosaveRegistryDispatcher(draft: RegistryEntry[], action: GlobalAction) {
  logger.debug("autosaveRegistryDispatcher received action type: " + action.type);
  switch (action.type) {
    case "addEntry":
      let isAdded = false;
      const newEntry = action.data;
      if (draft.length > 0) {
        for (const [index, entry] of draft.entries()) {
          if (entry.id === newEntry.id) {
            isAdded = true;
            if (entry.isDirty() !== newEntry.isDirty()) {
              logger.debug("Updating dirty state for: " + newEntry.name);
              draft[index] = newEntry;
            }
            break;
          }
        }
      }
      if (!isAdded) {
        logger.debug("Pushed new entry to registryEntries");
        draft.push(newEntry);
      }
      break;
    case "removeEntry":
      const entryId = action.data.id;
      if (draft.length > 0) {
        for (var [index, entry] of draft.entries()) {
          if (entry.id === entryId) {
            draft.splice(index, 1);
            logger.debug("Removed  entry in registryEntries: " + entryId);
            break;
          }
        }
      }
      break;
    case "clear":
      draft = [];
      break;
  }
}
