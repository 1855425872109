import Axios from "axios";
import { User } from "../StateContext";

export type SubscriptionIdentity = {
  subscriberId: string;
  subscriptionId: string;
};

/* TODO: split in diferent request types */
export type Subscription = {
  subscriberId: string;
  subscriptionId: string | undefined;
  type: string; //todo: narrow options
  startDateFlex: string | null | undefined;
  endDateFlex: string | null | undefined;
  flexFee: string | null | undefined;
  terminationDate: string | null | undefined;
};

class SubscriptionDao {
  getSubscription(
    currentUser: User,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get("/api/private/user/subscription/" + currentUser.id, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  takeoutSubscription(
    currentUser: User,
    request: Subscription,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.post("/api/private/user/subscription", request, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  prepayPeriod(
    currentUser: User,
    request: Subscription,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.post("/api/private/subscription/prepay", request, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  cancelSubscription(
    currentUser: User,
    request: SubscriptionIdentity,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.patch("/api/private/subscription/cancel", request, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }

  getPaymentInfo(
    currentUser: User,
    paymentId: string,
    responseCallback: any,
    errorCallback: any
  ) {
    Axios.get(`/api/private/payment/${currentUser.id}/${paymentId}`, {
      auth: {
        username: currentUser.username,
        password: currentUser.passwordHash
      }
    }).then(responseCallback, errorCallback);
  }
}

const subscriptionDao = new SubscriptionDao();
export default subscriptionDao;
